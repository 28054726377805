import React, { Component } from 'react';
import SHEEPIMG from '../lab/blog/SHEEP-IMG.jpg';
import {Helmet} from "react-helmet";

class LanolinTruth extends Component {
    componentDidMount() {
		window.scrollTo(0, 0) 
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish skin-exfoliation SEBUM Lanolin-Truth">
            <Helmet>    
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="index, follow" />   
                <meta name="description" content="Lanolin Truth" />
                <title> Lanolin Truth </title>  
            </Helmet>
            
                <div className="Image_frame">
                   <img src={SHEEPIMG} className="img-responsive"  alt="..."/>
                </div>
        <div className="container">
        <div className="Heading_tu">   
                    <h1>LANOLIN TRUTH</h1>
            </div>
                <div className="Image_frame-middle">
                <div className="Heading_tu">   
                    <p>You always notice same ingredients over and over again on cosmetics products, one of them is lanolin. </p>
                    <p> Formulators use lanolin in beauty products to supplement the skin’s natural functions – in the case of lanolin, those functions are moisturizing and protecting. Lanolin is a popular emollient in personal care products of all varieties, from lipsticks to baby balms, because it softens and soothes the skin while locking in moisture – a particularly helpful property when facing harsh winter air. It’s even been lauded as a <b>“winter skin wonder”</b>.  </p>
                </div>  
                <div className="Heading_tu">
                    <h4>WHAT IS LANOLIN??</h4>
                   <p>Lanolin is a waxy substance derived mainly from the wool of sheep. The sheep’s sebaceous glands produce this wool wax and help shed water and keep the sheep dry. It’s extracted by putting the wool through a centrifuge machine that separates the oil from other chemicals and debris.  </p>
                   <p>That means we get lanolin from wool, which we can get by giving the sheep a haircut. How bad could it be, right? Unfortunately, it’s not that simple.  </p>
               </div>   

                   <div className="Heading_tu">
                    <h4> POPULARITY </h4>
                   <p>
                   Lanolin is used in an array of products like pharmaceuticals, leather, textiles, baby and mother care products. Bio-lubricants and nearly all types of cosmetics marketed to women. While pharmaceuticals grade lanolin is the lion of the market today, the growing demand for natural and organic ingredients in personal care products is driving lanolin market growth which is expected to grow by 2024. 
                   </p>
                   <p>In beauty products, especially, lanolin gives lipsticks heavy gloss and high shine. Formulations like these are about 5-10% lanoline by weight. Lanolin may also appear in cosmetics as modified versions of lanolin oil. Lanfrax, for instance, is the trade name of a lanolin oil compound that has polyethylene glycol attached to it to make it more water – soluble. Accounting for these modified versions of lanolin, lanolin and its derivatives may account for approximately 15-25% of the weight if a given lipstick. </p>
                   </div> 
                   <div className="Heading_tu">
                    <h4> BUT DO SHEEP DIE IN THE PRODUCTION OF LANOLIN, SPECIFICALLY?? </h4>
                   <p>
                   Technically speaking, sheep do not die as a direct result of lanolin production because it is obtained from their wool. However, as sheep age, their wool production gradually slows (just as humans lose density on eyelashes, eyebrows, and hair). When they can no longer meet the farmer’s wool demands, they are sent to slaughter for consumption. Sheep bred for wool are almost always killed for meat. In this way, the demand for mass production lanolin perpetuates cruelty and eventual death of sheep. 
                   </p>
                   <p>By contrast, plant based waxes and emollients do not cause harm to animals, directly or indirectly. For us, the risk of harm is not worth the benefits when perfectly suitable, cruelty free, vegan alternatives exist. </p>
                   </div> 
                     
        </div>
        </div>
        </div>     
        );
    }
}

export default LanolinTruth;