import React, { Component } from "react";
import {Helmet} from "react-helmet";


export default class PrivateLabe extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
  render() {
    return <div>
		<Helmet>
		<meta charset="utf-8" />
	  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />
	<meta name="description" content="Searching for the natural cosmetics private label manufacturers? Choose VertLaboratoire, the ultimate organic beauty product manufacturers you can find in the country. " />
	<title>Label Cosmetic Manufacturers| Third Party Manufacturer|Organic Natural Make up Manufacturers </title>
		</Helmet>
  <section className="banner-frame">
    <img src={"https://ik.imagekit.io/deepak/Private-Label-Banner_sQfqcdCpRq.jpg"} className="img-responsive"/>
  </section> 
  <div className="main">
<section className="packging-section">
<div className="container">
  <div className="row">
	<div className="col-md-4">
		<div className="packging-section-left">
				<img src={"https://ik.imagekit.io/deepak/your-brand_ghqj4oLM7L.jpg"} className="" />
				<div className="packging-section-left-bo-img">
					 <h3>BRANDING</h3>
					 <p>Our R&amp;D, logo design, creative visualizing and marketing teams carefully take in scrutiny all details to innovate a holistic brand pathway that crafts the clients'&nbsp;core brand&nbsp;story.
</p>
				</div>
		</div>
	</div>
	<div className="col-md-8">
			<div className="packging-section-right">    
				<h2>INNOVATIVE BEAUTY CARE PRODUCTS FORMULATED TO THE HIGHEST STANDARDS, PACKAGED WITH YOUR BRAND AND LABEL, AS PER YOUR PREFERENCE. </h2>
				<div className="packging-section-right-left">
						<h4>PACKAGING</h4>
						<p>Bring your green creation to life with our wide range of recycled packaging options.</p>
						<img src={"https://ik.imagekit.io/deepak/your-brand2_3PNhIiMCyB.jpg"} className="" />
				</div>
				<div className="packging-section-right-right">
				      <p>Simply select and start designing with our label design and packaging team who ensure to deliver world className expertise with each eco-conscious curation. Customize from shape of the bottle, color, materials that complements your hotel property’s brand personality perfectly.</p>
                      <h4>LABELING</h4>	
                      <p>Reflect your brand ideas with
our label design team that
efficaciously translates &amp;
executes your desired designs.</p>	
<p>Personalize one of our existing amenity collection with your logo or develop a fully customized formulation with our professional creative team.</p>				  
				</div>
			</div>
	</div>
  </div>
</div>
</section>

<div className="clear"></div>
<section className="labot-section">
<div className="container">
   <h2>PRIVATE<span> LABEL </span></h2>
   <div className="labot-left">
		<img src={"https://ik.imagekit.io/deepak/your-brand3_cTkCTfAuh.jpg"} className="" />
   </div>
   <div className="labot-middle">
       <h3>LABORATORY TESTING </h3>
		<p>With our Laboratory Safety,
Stability and radical testing
experience and expertise, we engage in a 21st century clean+ green factory process to ensure the required testing of your custom product  is chemically trusted, safe and suitable for application. Vert products are
eco-friendly and sustainable as the production process is all
organic &amp; soot-free to ensure compatibility with the environment.</p>
   </div>
   <div className="labot-right">
		<img src={"https://ik.imagekit.io/deepak/your-brand4_5yK2ZSCQFj.jpg"} className="" />
   </div>
</div>
</section>


<div className="clear"></div>
<section className="labotman-section">
<div className="container">
<div className="labotman-section-main">
<div className="labotman-section-left">
<h3>MANUFACTURING</h3>
<p>Vert offers cosmetic contract
manufacturing services
worldwide. Chemists, product
development specialists, and packaging engineers work one-on-one with each client to create products</p>
</div>
<div className="labotman-section-middle">
<img src={"https://ik.imagekit.io/deepak/your-brand5_JsPa4z4w9.jpg"} className=""/>
</div>
<div className="labotman-section-right">
<p>We are extensively experienced and equally recognized for our green reputation as an international eco-conscious manufacturer for bulk natural cosmetic supplies of a vast variety of beauty
products.  </p>
</div>
</div>

</div>
<div className="labotman-section-main-bottom">
<div className="container">
	<p>A commitment to incorporate the best of science and nature through the use of organic ingredients with state-of-the-art technology to create carefully curated botanical formulations, we at Vert endeavor to give you beauty your way! </p>
</div>
</div>
 <div className="lable-bodyimg-frame">
 <img src={"https://ik.imagekit.io/deepak/your-brand6_iIGtW3os2J.jpg"} className="img-responsive" />   
 </div>
</section>
</div>
    </div>;
  }
}
