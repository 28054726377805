import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BodyCare extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
            <div className="make-top-banner">
               <img src={"https://ik.imagekit.io/vert/vertlaboratoire/banner_1_suDvt_14kr.jpg"} className="img-responsive"  alt="..."/>
            </div>
            <div className="makeup-middle-sect Body-care">
            
             <div className="container">
                  <div className="percare_care">
                  <p><span className="bea-t">P</span> <span className="bea-t-right">
                  ersonal care industry produces consumer products which are meant or beautification and hygiene. Growth of body care market with increase in the demand for skin, body and hair care products will increase the growth rate of body care market in the coming years. Rise in the demand for <b>animal and environment friendly products</b> infused with <b>natural</b> and <b>organic</b> ingredients present in the products is driving the growth of the market. </span></p>
                  </div>
             </div>
             <div className="Banner_body">
                <img src={"https://ik.imagekit.io/vert/vertlaboratoire/body_ulleYGUpR.gif"} className="img-responsive"  alt="..."/>
             </div> 
             <div className="container">
                  <div className="Gob_care">
                  <p><span className="bea-t">G</span> <span className="bea-t-right">
                     lobalization has shifted the minds of consumer to late 1990s, it’s not only last decade that the consumption pattern has changed in a big way. Post globalization, now day’s people are more concerned about the trends in personal grooming. New trends and knowledge about cosmetics and self-care with natural products has increased the demand for the market. Organic products are derived from natural resources and are free from chemicals. </span>
                  </p></div>
                  <div className="row longer">
                      <div className="col-md-4">
                          <img src={"https://ik.imagekit.io/vert/vertlaboratoire/body2_A7y_w8pqx.gif"} className="img-responsive"  alt="..."/>
                      </div>
                      <div className="col-md-8">
                      <p><span className="bea-t">B</span> <span className="bea-t-right">
                      ody care is no longer a stigma, earlier people never used to give importance to body, as face and hair was the only problematic areas to be concerned of. As consumer are being more aware of skincare due to education, media and other channels of awareness. They are focusing on the rest of the body problems such as <b>acne, stretch marks, cellulite, breast firming and booty firming products. </b></span>
                  </p>
                      </div>
                  </div>
             </div>
             <div className="percise_main_frame">
             <div className="container">
                 <p>At Vert laboratoire, we have the precise range of products for your body concerns, we offer body scrubs, body butters, body oils, shaving creams, handmade soaps, shower gels, booty scrubs, booty masks, cellulite masks, boobs masks, boobs scrubs and many more products for your concerned area. </p>
              </div>
             </div>  

             <div className="green_na">
                <div className="container">
                     <div className="row">
                         <div className="col-md-6">
                         <p><span className="bea-t">W</span> <span className="bea-t-right">
                         e,as <b>green manufactures</b> produce <b>non-toxic, chemical free and organic products</b> which are suited not only for green consumers but also take care of the production process and use of these products leave minimum carbon footprints on the ecological system. As <b>private label manufactures</b> can help you build your label with organic and natural products. A must for your brand portfolio launch.</span>
                  </p>
                         </div>
                         <div className="col-md-6">
                         <p><span className="bea-t">G</span> <span className="bea-t-right">
                         rowth in the demand for <b>body care products</b> infused with natural ingredients is most visible trend is the ascendance of organic products. Natural and organic products have never been more popular than now. As the market is moving towards high performance formulations instead of <b>chemical </b>infused products. </span>
                  </p>
                         </div>
                     </div>
                </div>
             </div>
             <div className="green_na_g">
                <div className="container">
                     <div className="row">
                        <div className="col-md-6">
                            <img src={"https://ik.imagekit.io/vert/vertlaboratoire/body4_IQk9yuhtX.gif"} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="col-md-6">
                             <img src={"https://ik.imagekit.io/vert/vertlaboratoire/body5_5YMVriWgX.gif"} className="img-responsive"  alt="..."/>
                        </div> 
                     </div>
                </div>
             </div>


            <div className="rech_now_cont">
                <h5>REACH US NOW TO LAUNCH YOUR BRAND !!!</h5>
                <p><Link to="/contact">Contact Us</Link></p>      
            </div>
  
            </div>
        </div>
        );
    }
}

export default BodyCare;