import React, { Component } from 'react';
import {Helmet} from "react-helmet";


class BakuchiolBlog extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="BakuchiolBlog-page BakuchiolBlog-page_cont">
            <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="robots" content="index, follow" />   
            <meta name="description" content="bakuchiol" />
            <title>Bakuchiol </title>  
            </Helmet>  
                  <div className="container">
                  <div className="Image_frame_bakuchiol">
                        <h1>BAKUCHIOL</h1>
                        <img src={"https://ik.imagekit.io/deepak/Laboratoire/bakuchiol-blog_h1qzTyvgy.jpg?updatedAt=1629868878495"} className="img-responsive"  alt="..."/>
                        </div>
                  </div> 
                  <div className="container new"> 
                        <div className="Image_frame_bakuchiol">
                        <p>If you have any idea about skincare, then you know retinol is hugely acclaimed resource for good skin. One can find retinol in just about anything, moisturizer, cream, serum etc. Just about anyone will use it for toughness and benefits. 
Moreover, retinol is a strong ingredient and can be irritating. People with sensitive and dry skin, for example, may not be able to use retinol. So what are they supposed to do instead?
Bakuchiol, this ingredient is a natural, safe alternative to retinol. It includes all the benefits retinol provides without the harmful side effects. Let’s read more about bakuchiol. 
</p>

                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4>What’s Bakuchiol??</h4>
                            <p>Bakuchiol comes from the Psoraleacorylifolia plant (also known as the ‘babchi plant’). It’s native to East Asia and has been traditionally used in Ayurvedic medicine. If used in products, it can be a tender and natural alternative to retinol. Bakuchiol products also have the potential to be vegan. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4>Benefits of Bakuchiol???</h4>
                            <p>Though there have been few researches, bakuchiol is very pleasant forminimizing fine lines, stimulating collagen production and wrinkles and any other skin needs. Bakuchiol also has anti-inflammatory, anti-oxidant and anti-bacterial properties that help the skin more than retinol could. </p>
                        </div>
                       <div className="bottom_cont_BakuchiolBlog">
                            <h4>How to use Bakuchiol???</h4>
                            <p>Just like retinol, one can use bakuchiol as a topical product on your skin. Since it’s not as cruel as retinol, you can use bakuchiol day or night. But if you’re combining a bakuchiol product with other skincare products, make sure you combine them in a careful manner.  </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog"> 
                            <h4>Any catches to Bakuchiol???</h4>
                            <p>Unlike retinol that can cause dryness and irritation, bakuchiol has no reported side effects. Remember, bakuchiol comes from a plant (the leaves, the seeds etc…). It shouldn’t pose or create harmful side effects compared to retinol. However, you should always do a patch test just in case there’s a skin allergy. </p>
                        </div>
                        
                  </div>   
            </div>
        );
    }
}

export default BakuchiolBlog;