import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Instagram from './Instagram';
import VideoSound from '../src/Cate/VideoSound';
import VideoSoundMobile from '../src/Cate/VideoSoundMobile';
import BRAND from '../src/img/BRAND.jpg';
import HONEST from '../src/img/HONEST.jpg';
import BRANDBanner from '../src/img/BRANDBanner.jpg';
import Join from '../src/img/Join.mp4';
import Join2 from '../src/img/Join2.mp4';
import Join3 from '../src/img/Join3.mp4';
import Join4 from '../src/img/Join4.mp4';
import Join5 from '../src/img/Join5.mp4';
import THEBRANDNEW from '../src/img/THEBRANDNEW.mp4';
import Tailcruelty1 from '../src/img/Tailcruelty1.gif';
import Tailcruelty2 from '../src/img/Tailcruelty2.gif';
import Tailcruelty3 from '../src/img/Tailcruelty3.gif';
import MobHome from '../src/img/MobHome.jpg';
import MobHome2 from '../src/img/MobHome2.jpg';
import MobHome3 from '../src/img/MobHome3.jpg';
import MobHome5 from '../src/img/MobHome5.jpg';

class Home2 extends Component {
    render() {
        return (
            <div className="new_home_page"> 
            <div className="container--">         
               <VideoSound/>
               <VideoSoundMobile/>  
            </div>   
               <div className="GREEN-BRAND-FACTORY">        
               <div className="container">
                    <div className="new_home_page_first_section">
                        <div className="row">
                        
                            <div className="col-md-6">
                            <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
                                 <div className="left_home_page_new_first_cont lift">
                                      <h3>AT GREEN BRAND FACTORY</h3>
                                      <div className="right_img_home_pagenewMob">
                                 <div className="right_img_home_pagenew fi">
                                      <img className="img-responsive" src={BRAND}  />   
                                 </div>
                            </div>
                                      <p>We are Young, Careful, Conscious Crafters, who love to create Concepts into Finished Goods and Great Brands eventually.</p>
<p>We are a <span>Green Manufacturing Unit</span> .We carefully understand concepts/ideas, challenges, vision and mission for your brand. With us possibilities are infinite ,we are here to foster <span>The Brands</span> in making. We completely cherish delivering our client's wonderful products across skincare, hair care and makeup.</p>
                                 </div>   
                                 </ScrollAnimation>  
                            </div>
                            <div className="col-md-6 right_img_home_pagenewDesk">
                                 <div className="right_img_home_pagenew fi">
                                      <img className="img-responsive" src={BRAND}  />   
                                 </div>
                            </div>

                        </div>
                        <div className="row">
                        <div className="col-md-6">
                        <div className="left_home_page_new_first_cont NW Mob">
                                    <h3>ETHICAL HONEST AND SUSTAINABLE</h3>
                        </div>
                                 <div className="right_img_home_pagenew nd">
                                    <img className="img-responsive" src={HONEST}  />
                                
                                 </div>     
                            </div>

                            <div className="col-md-6">
                                    <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">

                                    <div className="left_home_page_new_first_cont NW desktop">
                                    <h3>ETHICAL HONEST AND SUSTAINABLE</h3>
                                    <p className="INNOVATING">INNOVATING GREEN CONCEPTS WITH TRULY MARVELLING FEATURES</p>

                                    <p><span>.</span>  We started crafting products <span>Since 2011</span>. For over a decade now we have been making Award Winning Products /Brands through advocacy of <span>Green Chemistry.</span></p>
                                    <p><span>.</span>  Our creations do not pollute Environment, as we are a <span>Zero Waste Manufacturing Unit.</span> We do not harm animals in making beautiful products.</p>
                                    <p><span>.</span>  We take stride and pride in propelling the <span>Green Foot Print.</span></p>
                                    <p><span>.</span>  The upsurge of trends in Beauty Industry like <span>Parabens Free, Handmade, without Harmful Chemicals , no Plastic Beads ,no Animal Fat, against Animal Cruelty</span> is highly contributed in our lovely Green work over <span>10 years and counting.</span>

                                    </p>
                                    </div>

                                    </ScrollAnimation>

                            </div>
                           

                        </div>
                    </div>
                   <div className="bottom_con_all_homepagenew">
                          <p>Making green products does not mean that you make boring products or compromise on efficacy. We have mastered the science and art of creating unique decoction which are efficacious from the very use of it.</p>
<p>We are making products for a wide gamut of Brands complying to <span>UK, Kosher, European Union, Cosmos, US FDA.</span>
</p>
                   </div>

               </div>
               </div>
              
          <div className="complete_ready_sell">
          <a href="/the-brand">
          <div className="container">
              <h4>What Is Product <span>+</span> At Brand Factory</h4>
              <p>We can help you develop not just great products in  Makeup, Skin Care, Hair Care and Personal Care. But can also help you with <span>Packaging, Branding, Logo Design, Label, Box Designing, Website, Marketing</span> and so on & so forth.
</p>
<p>We just don't artfully design but also execute the same seamlessly. So yes we get your <span>Boxes Printed,</span> make your <span>Webpage</span> workable and wide <span>Integrations, Plugins</span> and <span>SEO compatibility.</span>
</p>
<p>In a nut shell we handover a <span>complete Ready to Sell Proposition</span> to you.
</p>
<p>So that you just focus on sales & marketing and you just leave rest on us!</p>

           </div>
           </a>
           </div>
          
          
           <div className="Main_banner_hb_mid Mob">
           <a href="/Branding"> 
                 <img className="img-responsive" src={MobHome3}  /> 
                 </a>
              </div>
             
        <div className="Main_banner_hb  Desk">
        <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInDown">
        <a href="/Branding">  
        <div className="Main_banner_hb_mid">
            <div className="Main_banner_hb_mid_left">
          
              <video autoPlay loop muted>
                        <source src={THEBRANDNEW} type="video/mp4"/>
                        <source src={THEBRANDNEW} type="video/ogg"/>       
              </video>
               
              </div>
              <div className="Main_banner_hb_mid_right">  
                   <img className="img-responsive_brand" src={BRANDBanner}  /> 
              </div>
              </div>
             
              <div className="clear"></div>
              </a>  
             </ScrollAnimation>

        </div>

          <div className="Scrince_come_here_frame">
              <h4>Since <span>2011</span> We Have Enjoyed Working With A Diverse Pool Of Clients</h4>
              <p>They are from different walks of life like Hotels, Spas, Fashion Brands, Retailers, E-Commerce, Startups,<br/> Makeup Artists, Designers, Baby Care companies speciality Mens Brands and we love them all.
</p>
<p>We are a warm <span>Green Brand Factory</span> which is always buzzing with exciting discussion, debates of <br/>upcoming products/brands over a cup of coffee !
</p>
             
              <a href="/Provenance">
              <video autoPlay loop muted>
                        <source src={Join} type="video/mp4"/>
                        <source src={Join} type="video/ogg"/>       
              </video>
                 <img className="img-responsive" src={MobHome2}  />
              </a>
             {/* <a href="/the-brand">
              <video autoPlay loop muted>
                        <source src={Join3} type="video/mp4"/>
                        <source src={Join3} type="video/ogg"/>       
              </video>
              </a>*/} 
              <a href="/how-it-work">
              <img className="img-responsive" src={MobHome}  />
              <video autoPlay loop muted>
                        <source src={Join2} type="video/mp4"/>
                        <source src={Join2} type="video/ogg"/>       
              </video>
              </a>
          </div>


          <div className="reachand_developent_newhomepage">
              <div className="container">
                  <div className="row">
                  <div className="col-md-6">
                            <h4> RESEARCH & <br/> <span>DEVELOPMENT</span></h4>
                            
                      </div>
                      <div className="col-md-6">
                            <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                             
                             <div className="reachand_developent_right">
                                  <p>We have state of art Research & Development Laboratory to<br/> formulate any concept into finished product.<br/> We are very competent in <span>Designing Custom Formulations.</span></p>

                             </div>
                             </ScrollAnimation>
                      </div>
                  </div>
              </div>
          </div>

         

          <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
          
          <div className="join_side_new_home_page">
              <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="join_side_new_home_page-left">
                                 <p>Though, we have laboratory facility we choose to stay away from tests which involve animal cruelty - like we don't conduct or promote <span>Live Animal Dermatology Testing, Hypoallergenic</span> and other tests which involve use of body parts and organs from animals slaughtered for  meat industry.</p>
                            </div>
                           
                            <div className="join_side_new_home_page-right desk">
                                 <p>We don't want to contribute towards the countless suffering of animals in laboratories for testing beauty products.</p>
                                 <p>Hence, at Vert Laboratoire our objective is to achieve balance & harmony with nature, animals & humans.</p>
                                 <p>We strive to make world beautiful without harming anyone in the entire value chain. So that it is <span>Sustainable</span> and keep changing lives positively.</p>
                            </div>
                            <div className="join_side_new_mao desk">
                                    
                                <div className="join_side_new_home_page-middle">
                                <img className="img-responsive" src={"https://ik.imagekit.io/deepak/free_wdPaUB835l.jpg"}  /> 
                                </div>
                                <div className="green_side_bottom">
                                <h5>Join The Green Side</h5>
                            </div>

                            </div>
                            
                        </div>
                       
                        <div className="col-md-4">
                                <div className="live_animalvideo">
                                <div className="container---">
                                <video autoPlay loop muted>
                                <source src={Join5} type="video/mp4"/>
                                <source src={Join5} type="video/ogg"/>       
                                </video>
                                  <img className="img-responsive" src={MobHome5}  /> 
                                </div>

                                </div>
                        </div>
                <div className="col-md-12">
                <div className="join_side_new_home_page-right mob">
                                 <p>We don't want to contribute towards the countless suffering of animals in laboratories for testing beauty products.</p>
                                 <p>Hence, at Vert Laboratoire our objective is to achieve balance & harmony with nature, animals & humans.</p>
                                 <p>We strive to make world beautiful without harming anyone in the entire value chain. So that it is <span>Sustainable</span> and keep changing lives positively.</p>
                            </div>
                    <div className="join_side_new_mao mobile">
                        <div className="green_side_bottom">
                        <h5>Join The Green Side</h5>
                        </div>
                        <div className="join_side_new_home_page-middle">
                        <img className="img-responsive" src={"https://ik.imagekit.io/deepak/free_wdPaUB835l.jpg"}  /> 
                        </div>
                    </div>  
                </div>
                        
                    </div>
                    
              </div>
             
          </div>
          </ScrollAnimation>

          <div className="lunch-now-frame">
					<div className="container">
							<div className="top_lunch_now">
								<h4><a href="/contact">LAUNCH NOW</a></h4>
								<p>Create Your Own Branded Beauty Products - Our Team Will Help As Much Needed To <br/> Conceptualise , Create , Design , Capture , Amplify Your Incredible Products To A Brand.</p>
							</div>
							<div className="lunch_now_buttomframe">
                                  <div className="row">
									  <div className="col-md-4">
										  <div className="speed_frame">
										  <div className="iamg_frame_all new">
                                          <img className="img-responsive" src={Tailcruelty1}  />
                                              </div>
											 <h5>Speed to Market</h5>
											 <p>Launched beauty brands from <br/>  concept stage to Finished goods in <br/> approx 12 weeks straight.</p>
										  </div>
									  </div>
									  <div className="col-md-4">
										  <div className="speed_frame">
										  <div className="iamg_frame_all">
                                          <img className="img-responsive" src={Tailcruelty2}  />
                                        </div>
											 <h5>Sustainable </h5>
											 <p>Harmony is what we pursue. We don't <br/> test on animals and make vegan <br/> products</p>
										  </div>
									  </div>
									  <div className="col-md-4">
										  <div className="speed_frame"> 
											  <div className="iamg_frame_all new">
                                              <img className="img-responsive" src={Tailcruelty3}  />
                                                  </div>
										    
										     <h5>Genesis </h5>
											 <p>We use plant/botanicals sourced <br/> ingredients like butters , oils , flowers , <br/> barks , leaves , spices to create <br/> efficacious products  </p>
										  </div>
									  </div>
								  </div>
							</div>
				    </div>

					</div>

                    <div className="logo-c">
       
	   <img src={"https://ik.imagekit.io/deepak/logo-c_hRMHgPUuah---------.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659267064481"} className="img-responsive" alt="..."/>
	</div>              
<div className="container--">
<Instagram/> 
</div>


            </div>
        );
    }
}

export default Home2;