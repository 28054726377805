import React, { Component } from 'react';
import Facelotion1 from '../lab/Facelotion1.jpg'; 
import Facelotion3 from '../lab/Facelotion3.jpg';
import Facelotion4 from '../lab/Facelotion4.jpg';
import Facelotion5 from '../lab/Facelotion5.jpg';
import Facelotion6 from '../lab/Facelotion6.jpg';
import Facelotion7 from '../lab/Facelotion7.jpg';
import Facelotion8 from '../lab/Facelotion8.jpg';
import Lotionicon1 from '../lab/Lotionicon1.jpg';
import Lotionicon2 from '../lab/Lotionicon2.jpg';
import Lotionicon3 from '../lab/Lotionicon3.jpg';
import Lotionicon4 from '../lab/Lotionicon4.jpg';
import Lotionicon5 from '../lab/Lotionicon5.jpg'; 
import Lotionicon6 from '../lab/Lotionicon6.jpg';
import Lotionicon7 from '../lab/Lotionicon7.jpg';
import Lotionicon8 from '../lab/Lotionicon8.jpg';
import Lotionicon9 from '../lab/Lotionicon9.jpg';  
import Lotionicon10 from '../lab/Lotionicon10.jpg';

class FaceLotion extends Component { 
  componentDidMount() {
    window.scrollTo(0, 0)
  }
    render() {
        return (
            <div className="facecare_main-page face_lotion_mainframe facelotionpage">
            <div className="facelotionbanner">
              <img src={Facelotion1} className="img-responsive"  alt="..."/>
            </div>
            <div className="facelotion_heading">
                     <h2>FACE LOTION</h2>    
             </div>
            <div className="face-lotion-firstsection">
                       <div className="face-lotion-firstsection-inner">
                           <div className="container--">
                               <p>Skin care and skin protection was vial for civilization like the Ancient Egyptians who lived in harsh climates, exposed to extreme heat and wind. History says that one of the earliest lotion bottles belonged to Egyptian Queen Hatshepsut had skin condition known as eczema. The lotion contained traces of palm oil, nutmeg oil and fats. Later, different cultures added herbs and fragrances from flowers and fruits to the lotion and skin care salves to make them smell pleasant. </p>
                               </div>
                       </div>
                  </div>
                  <div className="lotion-market-movingframenn">
                      <img src={Facelotion8} className="img-responsive"  alt="..."/>
                       <div className="lotion-market-movingframe-innernn">
                             <p>In today’s scenario the skin care market is valued at 10.78 billion in 2020 and is expected to rise by CAGR 16.8% by 2025 due to rapid expanding global cosmetic industry. Rising demand for natural and organic skincare products are expected to drive the growth of the skincare market. Benefits of using <span className="high">organic lotions</span> as they are packed with <span className="high">natural ingredients.</span> With the growing awareness of the risk associated with the prolonged use of face lotions made from synthetic materials have raised the demand for the face lotion market. </p>
                       </div>
                  </div>
                  <div className="clear"></div>
                  <div className="lotion-market-movingframe range-productcon">
                       <div className="lotion-market-movingframe-innercon">
                           <div className="container">
                                  <div className="con-sci_lotionframe">
                                    <p>We conscious fabricate our products by not using harmful chemicals like <span className="high">parabens, sodium benzoate, benzyl alcohol, formaldehyde, sodium lauryl sulphate, petrolatum, coal tar, hydroquinone, triclosan, oxybenzone, phthalates, lead</span> other adverse chemicals. </p>
                                     <h4>AT VERT LABORATOIRE WE OFFER CLEAN LEGENDARY EMULSIONS LIKE</h4>
                                  </div>
                            </div>
                       </div>
                  </div>  


                  <div className="ultra-lightbottom-lotion">
                  <div className="container">
                    <div className="row">
                    <div className="col-md-2"></div>
                         <div className="col-md-5">
                             <ul>
                               <li><img src={Lotionicon1} className="img-responsive-"  alt="..."/> <span>ULTRA-LIGHT FACE LOTION</span></li>
                               <li><img src={Lotionicon2} className="img-responsive-"  alt="..."/> <span>OIL FREE FACE LOTION</span></li>
                               <li><img src={Lotionicon3} className="img-responsive-"  alt="..."/> <span>PORE MINIMIZING FACE LOTION</span></li>
                               <li><img src={Lotionicon4} className="img-responsive-"  alt="..."/> <span>WRINKLE FREE FACE LOTION</span></li>
                               <li><img src={Lotionicon5} className="img-responsive-"  alt="..."/> <span>DAY FACE LOTION</span></li>
                             
                             </ul>
                         </div>
                         <div className="col-md-5">
                             <ul>
                               <li><img src={Lotionicon6} className="img-responsive-"  alt="..."/> <span>SUNSCREEN LOTION</span></li>
                               <li><img src={Lotionicon7} className="img-responsive-"  alt="..."/> <span>NIGHT FACE LOTION</span></li>
                               <li><img src={Lotionicon8} className="img-responsive-"  alt="..."/> <span>WATER BASED FACE LOTION</span></li>
                               <li><img src={Lotionicon9} className="img-responsive-"  alt="..."/> <span>OIL BASED FACE LOTION</span></li>
                               <li><img src={Lotionicon10} className="img-responsive-"  alt="..."/> <span>GEL BASED FACE LOTION</span></li>
                             
                             </ul>
                         </div>
                        
                    </div>
                    </div>

                  </div>
                 
                    
                  <div className="lotion-market-movingframerange-product-boo">   
                       <div className="lotion-market-movingframe-innerboo ">
                           <div className="container">
                                   <p>We are <span className="high">Organic products manufacturers</span> and can help you build your label with <span className="high">organic and natural</span> products. Create your own <span className="high">unique label</span> that speaks your essence!!</p>
                            </div>
                       </div>       
                  </div> 
                  

       </div>
        );
    }   
}

export default FaceLotion;