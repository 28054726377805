import React, { Component } from 'react';
import HairVideo from '../lab/Hair.mp4';
import Hairheading from '../lab/hairheading.jpg';
import HairCare1 from '../lab/HairCare1.jpg';
import HairCare2 from '../lab/HairCare2.jpg';
import HairCare3 from '../lab/HairCare3.jpg';
import HairCare4 from '../lab/HairCare4.jpg';
import HairCare5 from '../lab/HairCare5.jpg';
class Hair extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      } 
    render() {  
        return (
            <div className="hair-page hair-carenewpage haircarenewpage">     
                <div className="make-top-banner">
                <video autoPlay loop muted>
                        <source src={HairVideo} type="video/mp4"/>
                        <source src={HairVideo} type="video/ogg"/>       
                    </video>    
                 </div>
                <div className="container">
                    <div className="top_head_hair-heading">
                        <img src={Hairheading} className="img-responsive"  alt="..."/>    
                        <p>The global hair care market is expected to showcase exponential growth by reaching $112.97 billion by 2028 while exhibiting a CAGR of 5.6% between 2021 and 2028. Factors such as the increasing cases <span> of hair loss among men, coupled with the prevalence of several hair related ailments and is anticipated to boost the adoption of the product globally. </span></p>    
                    </div>  
                    <div className="row">
                         <div className="col-md-5">
                            <img src={HairCare1} className="img-responsive"  alt="..."/>
                         </div>
                         <div className="col-md-6">
                         <div className="right_hair_cont_pagenew">
                             <h3><span className="right_hair_cont_pagenewgh" >GROWTH OF</span><br/> <span className="right_hair_cont_pagenewgh2">HAIR CARE</span><br/> <span className="right_hair_cont_pagenewgh">PRODUCT GLOBALLY</span></h3>
                        </div>
                         
                         </div>
                    </div> 
                    </div>
                    <div className="middle_bott_hair">
                    <img src={HairCare2} className="img-responsive"  alt="..."/>  
                    <div className="container">
                        <div className="col-md-12covid">
                         <p>COVID-19 pandemic led to a major disruption in human lives. The rate of infection grew at a colossal rate, along with a high mortality rate. To contain the spread of disease, the government agencies announced complete lockdown and stringent imposition of social distancing norms followed. Owing to this, several stores and industries were closed hampering the operational capabilities. </p>
                        </div>
                        </div>
                        <img src={HairCare2} className="img-responsive"  alt="..."/>  
                     </div> 
                         
                    <div className="bottom_2nd_sect_hair">
                    <div className="container">
                        <div className="row">  
                           <div className="col-md-8">
                                 <div className="bottom_2nd_sect_hair-leftnew">
                                    <p>Increased issue related to hair such as experiencing hair loos, greying and dandruff. And preference shifting towards organic and natural products. Due to the awareness regarding the adverse effects of harmful chemicals on health and the environment are some factors that likely to propel the market growth during the forecast period. </p>
                                 </div>
                           </div>
                           <div className="col-md-4">
                           <div className="bottom_2nd_sect_hair-rightnew">
                              <img src={HairCare3} className="img-responsive"  alt="..."/> 
                           </div>
                           </div>
                           </div>
                    </div>
                  </div>
                  <div className="bottom_2nd_sect_hair">
                    <div className="container">
                        <div className="row">  
                           <div className="col-md-8">
                                 <div className="bottom_2nd_sect_hair-leftnew">
                                    <p>Natural-plant based ingredients have been in the market since ages. The high performance and safe formulations are likely to propel the growth of Hair Care Market. We take immense pride by offering safe Natural products by providing <span class="high">Proteins, vegetal, Vitamin E, Oats, Keratin, paraben free, GMO free, Alcohol free and Cruelty free products.</span> </p>
                                 </div>
                           </div>
                           <div className="col-md-4">
                           <div className="bottom_2nd_sect_hair-rightnew">
                              <img src={HairCare4} className="img-responsive"  alt="..."/> 
                           </div>
                           </div>
                           </div>
                    </div>
                  </div>
                  <div className="bottom_2nd_sect_hair">
                    <div className="container">
                        <div className="row">  
                           <div className="col-md-8">
                                 <div className="bottom_2nd_sect_hair-leftnew">
                                    <p>We happily formulate safe products without any harmful chemicals like <span class="high">SLS, TEA, DEA, Formaldehyde, Alcohol, Synthetic Fragrances, Synthetic Colors, Triclosan and other harmful chemicals.</span></p>
                                 </div>
                           </div>
                           <div className="col-md-4">
                           <div className="bottom_2nd_sect_hair-rightnew">
                              <img src={HairCare5} className="img-responsive"  alt="..."/> 
                           </div>
                           </div>
                           </div>
                    </div>
                  </div>


                  <div className="manustoryhaircarepage">
                  <div className="container">
                      <h4>WE PROUDLY MANUFACTURE RESTORE TAME & STYLE OFFERING FOR YOUR BRAND LIKE</h4>
                      <div className="row">
                          <div className="col-md-6">
                              <div className="manustoryhaircarepage-left">
                                  <ul>
                                      <li>HAIR SERUMS </li>
                                      <li>HAIR SHAMPOOS</li>
                                      <li>PRE – SHAMPOO HAIR MASK</li>
                                      <li>PRE – SHAMPOO SCALP MASK</li>
                                      <li>HAIR CONDITIONERS</li>
                                      <li>LEAVE IN CONDITIONER </li>
                                      <li>CURL CREAM </li>
                                      <li>CURL CONDITIONER</li>
                                      <li>HAIR GEL</li>
                                  </ul>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="manustoryhaircarepage-right">
                                  <ul>
                                      <li>CURL SHAMPOO</li>
                                      <li>DRY SHAMPOO</li>
                                      <li>HAIR OIL</li>
                                      <li>HAIR COLOUR</li>
                                      <li>HAIR MASK</li>
                                      <li>HAIR SCRUBS</li>
                                      <li>HAIR CREAM</li>
                                      <li>HAIR WAX</li>
                                     
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>

                  </div>
                 <div className="haircarelast-line">
                    <div className="container">
                         <p><span class="high">Organic and natural</span> products are loaded with herbs, fruits and natural oils which helps to cleanse your hair, nourish and repair without harming or disturbing your skin. We are <span class="high">private label organic manufacturers</span> and can help you build your label with <span class="high">organic and natural</span> products.</p>
                    </div>
                  </div>
               
            </div>
        );
    }
}

export default Hair;