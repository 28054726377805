import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class BodyAcneHappening extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="BakuchiolBlog-page BakuchiolBlog-page_cont Bodyacne-happ">
            <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="robots" content="index, follow" />   
            <meta name="description" content="why body acne is happening more than ever???" />
            <title>why body acne is happening more than ever???</title>  
            </Helmet> 
            <div className="container new">
               <h1>WHY BODY ACNE IS HAPPENING MORE THAN EVER???</h1>
               <div className="Image_frame_bakuchiol">
                        <p className="text-center">As many people have discovered, that periods of stress can lead to an increase in a stress hormone called cortisol, which in turn causes breakouts and not necessarily on the face. </p>
                        <p>Pandemic stress- induced acne, including the “maskne” caused by our face coverings, have propel the skin-care discussion this year. But a more overlooked part- increase in body acne seems to be occurring side by side to the new bumps on our faces. In this pandemic facial and body acne are increasing like anything. Acne has been one of the most common complaints. Regardless if, you have recently spotted the annoying surprise of a pimple in a fresh place or have long been dealing with body acne, doctors say that there are many ways to get rid of breakouts on the butt, back, chest and legs.</p>
                        </div>  
            </div> 
                  <div className="container">
                  <div className="Image_frame_bakuchiol">
                     <img src={"https://ik.imagekit.io/vert/Blog/blush2_fQ8kzlNUM.jpg?updatedAt=1629967799129"} className="img-responsive"  alt="..."/>
                   </div>
                  </div> 
                  <div className="container new"> 
                      
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4 className="text-center">WHAT IS BODY ACNE??? </h4>
                            <p>Facial and body acne have similar fundamental causes, zits pop up as a result of increase in oil production, inflammation and clogged pores, among other factors, which are then caused by things like stress, individual genetics and hormones.  </p>
                            <p>But just because you get whiteheads around your chin or cystic acne on your chin this not necessarily interpret to the type of breakouts you will see on the body. </p>
                            <p>Body acne do not to show up as frequently as blackheads, but instead can be cysts, pimples or whiteheads. It occurs in areas that can get oily, like the upper back and center of the chest. Higher oil levels make a perfect environment for bacteria to grow, leading to subsequent and inflamed acne. Buttocks are also a common area due to irritation from tight fitting clothes and sweating. Legs can be vulnerable to acne after shaving due to irritation around hair follicles. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4 className="text-center">WHY SUDDEN BREAKOUT???</h4>
                            <p>From the emotional to the physical, pandemic related problems are contributing to the rise in acne. A complete lifestyle change, sitting at one place, creates more friction on your butt, which then leads to butt breakouts. Rubbing of butt on the couch can increase irritation and agitate breakouts. More time spend in sweaty clothes on the couch and can cause clogged pores.  </p>
                            <p>Furthermore, we are not looking after our skin as we used to do. People are more exhausted, mentally and physically. Forget sheet masks or butt masks, people are showering less, which can lead to oil buildup on the body. Whereas, other are showering more, which leads to inflamed skin. Now diet is also contributing to newfound body breakouts. Pandemic related poor diet may be aggravate your acne, studies says that high glycemic diets (high carb and sugar food) and dairy may deteriorate acne.  </p>
                            <p>In the end, there’s the emotional toll that this year has taken. Pandemic related stress may contribute to acne flares. Stress includesthe bracing of hypothalamic – pituitary –adrenal axis and shoot up the cortisol levels in the body. Stress than increase in oil production and inflammation in the skin, causing acne flares. </p>
                            <p>Spending less time on the couch and find ways to de-stress. Yoga and at home exercises can be a great way to relieve your stress while staying in shape. Change in the eating habits and wearing loose fitted clothes with good skincare routine will help your deal with this unwanted breakouts. </p>
                        </div>
                       
                        
                      
                  </div>   
            </div> 
        );
    }
}

export default BodyAcneHappening;