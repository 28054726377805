import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';

class CleanserSanitizer extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }  
    render() {
        return (
            <div className="sani-frame">
                <div className="sani-top-banner"> 
                   <img src={"https://ik.imagekit.io/deepak/Laboratoire/sani-1_JLRjr0K-VVE.jpg?updatedAt=1628931626007"} className="img-responsive"  alt="..."/>
                </div> 
                  <div className="container">
                      <div className="row row_sani_top">
                          <div className="col-md-8">
                          <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                              <div className="sani_left_top">
                                   <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                              </div>
                           </ScrollAnimation> 
                          </div>
                          <div className="col-md-3">
                          <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                               <img src={"https://ik.imagekit.io/deepak/Laboratoire/sani-2_gaQJeNXrf.jpg?updatedAt=1628931626670"} className="img-responsive"  alt="..."/>
                          </ScrollAnimation> 
                          </div>
                      </div>
                      <div className="row row_mi_text">

                          <div className="col-md-4">
                          <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                              <div className="clan_midd_frame fi">  
                                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                              </div> 
                              </ScrollAnimation> 
                          </div>
                          <div className="col-md-4">
                              <div className="clan_midd_frame tw">  
                              <AnimationOnScroll animateIn="animate__bounceIn">
                                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </AnimationOnScroll>    
                              </div> 
                          </div>
                          <div className="col-md-4">
                          <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                              <div className="clan_midd_frame three"> 
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                              </div> 
                              </ScrollAnimation> 
                          </div>
                          
                      </div>

                      <div className="row row_cle_img">
                           <div className="col-md-2"></div>
                           <div className="col-md-4">
                           <AnimationOnScroll animateIn="animate__bounceIn">
                           <img src={"https://ik.imagekit.io/deepak/Laboratoire/sani-3_o4ez-3dK-uW.jpg?updatedAt=1628931627527"} className="img-responsive"  alt="..."/>
                           </AnimationOnScroll>
                           </div>
                           <div className="col-md-4">
                           <AnimationOnScroll animateIn="animate__bounceIn">
                           <img src={"https://ik.imagekit.io/deepak/Laboratoire/sani-4_g_Y1sOH2P.jpg?updatedAt=1628931628498"} className="img-responsive"  alt="..."/>
                           </AnimationOnScroll>
                           </div>
                           <div className="col-md-2"></div>
                      </div>
                        <div className="row row_mi_text">
                        <div className="col-md-4">
                        <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                        <div className="clan_midd_frame four">  
                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div> 
                        </ScrollAnimation>
                        </div>
                        <div className="col-md-4">
                        <div className="clan_midd_frame2 tw2">  
                        <img src={"https://ik.imagekit.io/deepak/Laboratoire/sani-5_K7fcNdTx-.jpg?updatedAt=1628931625479"} className="img-responsive"  alt="..."/>
                </div> 
                        </div>
                        <div className="col-md-4">
                        <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                        <div className="clan_midd_frame four"> 
                       
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        
                        </div> 
                        </ScrollAnimation> 
                        </div>

                        </div>

                  </div>
            </div>
        );
    }
}

export default CleanserSanitizer;