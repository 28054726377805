import React, { Component } from 'react';
import Peppermintimg from '../lab/blog/Peppermintimg.jpg';
import {Helmet} from "react-helmet";

class PeppermintOil extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish">
                   <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="Peppermint Oil for Skin Benefits" />
	<title> Peppermint Oil for Skin Benefits  </title>  
	</Helmet>
                    <div className="Image_frame">
                       <img src={Peppermintimg} className="img-responsive"  alt="..."/>
                    </div>
            <div className="container">
            
                    <div className="Image_frame-middle">
                    <div className="Heading_tu">
                        <h1>PEPPERMINT OIL FOR SKIN BENEFITS</h1>
                        <p>
                        Peppermint oil is an essential oil that is extracted from the leaves of the peppermint plant (menthapiperita). The leaves of peppermint are freshly picked and the hydrosol are then extracted through the process of steam distillation.  </p>
                        <p>Currently, India is the largest producer and exporter of mint oil and its derivatives. Menthol, the main constituent of peppermint oil, is used in a variety of products like cold- relief balms, toothpaste, pain-relief ointments, cough drops etc. </p>
                        <p>From organic to natural beauty industry to the pharmaceuticals industry, from aromatherapy to wellness, from homeopathy to naturopathy, from food to flavoring, peppermint oil is being valued for its abundant therapeutic properties and medicinal benefits throughout the world. </p>
                    </div>    
                    <div className="Heading_tu">
                        <h4>BENEFITS OF USING PEPPERMINT OIL FOR SKIN</h4> 
                        <p> <span>• It reduces pimples – </span>  The anti – microbial activity and anti – septic property of peppermint essential oil help in reducing pimples and lesions like papules, nodules, cysts and active comedones.    </p>
                        <p> <span>• It rejuvenates skin – </span> Peppermint essential oil when added to any skin care formulation makes your skin feel refreshed, rejuvenated and full of life.  </p>
                        <p> <span>•	It stimulates blood flow – </span>  Using oils or serums enriched with organic peppermint essential oil for face and body consistently, improves blood circulation and enhances skin tone. </p>
                        <p> <span>• It cools skin and scalp  </span>  Formulations containing peppermint essential oil are used to provide a cooling sensation on the skin to reduce the effects of sun burn or prolonged sun exposure.  </p>
                        <p> <span>•	It controls odor –  </span>   The anti – bacterial properties in peppermint oil keeps skin and scalp clear from congestion and make them smell fresh and minty by eliminating bacteria and odor.  </p>
                        <p> <span>•	It regulates sebum –  </span>  Peppermint oil in face masks and cleansers helps to regulate sebum production and distribution on oily combination and acne – prone skin.  </p>
                        <p> <span>•	It is astringent in nature – </span>  Peppermint oil is a natural astringent. It contracts skin cells and improves the appearance of pores. It also keeps pores from getting clogged or congested.  </p>
                   
                    </div>     
            </div>
            </div>
            </div> 
        );
    }
}

export default PeppermintOil;