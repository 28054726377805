import React, { Component } from 'react';
import hotel1 from '../lab/hotel1.mp4';
import hotel2 from '../lab/hotel2.mp4';
import hotel4 from '../lab/hotel4.mp4';
import hotel6 from '../lab/hotel6.mp4';

class Hotel extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="hotelpagenew HotelBG"> 
                      <div className="bodycareNewpageVideo">
                            <video autoPlay loop muted>
                            <source src={hotel1} type="video/mp4"/>
                            <source src={hotel1} type="video/ogg"/>          
                            </video>    
                     </div>
                     <div className="hotelamipagestart">
                    <div className="container">
                          <div className="row">
                              <div className="col-md-7">
                                    <div className="helteami-left">
                                        <h3>Hotel Amenities</h3>
                                        <p><span className="fir">V</span>ért provides the widest portfolio of International Amenity brands and serves to majority of International Luxury Hotel & Spa chains. Our premium quality Guest Toiletries are developed with all natural ingredients that are sustainably sourced and organically treated using traditional techniques to give you only the best of botanical beauty. Select from a variety of natural actives, pure essential oils, exquisite fragrances & 100% paraben-free preservatives, ethically crafted by a soot-free process to propel the green footprint.</p>
                                    </div>
                              </div>
                              <div className="col-md-4">
                                    <video autoPlay loop muted>
                                    <source src={hotel2} type="video/mp4"/>
                                    <source src={hotel2} type="video/ogg"/>          
                                    </video> 
                              </div>
                          </div>
                    </div>
                    </div>
                    <div className="cateheading">
                        <h4><h4>CATEGORY</h4></h4>
                    </div>
                    <div className="hotel-categi-section">
                          
                        <div className="row">
                            <div className="col-md-3">
                                <ul>
                                    <li>SHAMPOO</li>
                                    <li>CONDITIONER</li>
                                    <li>BUBBLE BATH</li>
                                    <li>AFTERSUN LOTION</li>
                                    <li>FACE CREAM</li>
                                    <li>SHOWER GEL</li>
                                    <li>SUNSCREEN</li>
                                    <li>FACE WASH</li>
                                    <li> HAIR OIL</li>
                                    <li> HAND CREAM</li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul>
                                    <li>SOLID PERFUME</li>
                                    <li> BODY LOTION</li>
                                    
                                    <li>INSECT REPELLENT</li>
                                    <li>LIP BALM</li>
                                    <li> BODY SCRUB</li>
                                    <li>BATH BOMB</li>
                                    <li> BODY OIL</li>
                                    <li> BATHING SALT </li>
                                    <li> SHAVING CREAM</li>
                                    <li> POWDER FACE CLEANSERS </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="hotelpage-border"></div>
                    <div className="hotelamipagestart spa">
                    <div className="container">
                          <div className="row">
                          <div className="col-md-4">
                                    <video autoPlay loop muted>
                                    <source src={hotel4} type="video/mp4"/>
                                    <source src={hotel4} type="video/ogg"/>          
                                    </video> 
                              </div>
                              <div className="col-md-8">
                                    <div className="helteami-left">
                                        <h3>Spa Amenities</h3>
                                    <p><span className="fir">O</span>ur luxurious & exotic treatments for spas reflect our passion to combine the true principles of botanical healing with premium quality ingredients to elevate your wellness routine and give you a serene spa glow.</p>
                                    <p><span className="fir">P</span>assionately focused on purity and sustainability, our products are derived from authentic & all organic remedies, made in India, completely natural and free of artificial colors and synthetic fragrances for a rejuvenating clean & green spa experience.</p>
                                    </div>
                              </div>
                            
                          </div>
                    </div>
                    </div>
                    <div className="hotelpage-border"></div>
                    <div className="hotelamipagestart tailor">
                        <div className="telar-hotel-heading">
                            <h4>Tailor Made Spa Amenities</h4>
                            <p>STATE-OF-THE-ART FORMULAS FEATURING:</p>
                        </div>
                    <div className="container">
                          <div className="row">
                              <div className="col-md-7">
                                    <div className="helteami-left">
                                    <p><span className="fir">F</span>irst-to-market naturally derived ingredients & custom-made formulas.</p>
                                    <p><span className="fir">C</span>ertified-Organic ingredients & delivery vehicle technologies.
An in house- R&D team that listens to your unique ideas and meets your custom requirements.</p>
<p><span className="fir">H</span>undreds of high performance stock formulas tailored to treat anyone to a luxurious spa experience.</p>
                                    </div>
                              </div>
                              <div className="col-md-4">
                                    <video autoPlay loop muted>
                                    <source src={hotel6} type="video/mp4"/>
                                    <source src={hotel6} type="video/ogg"/>          
                                    </video> 
                              </div>
                          </div>
                    </div>
                    </div>
            </div>
           
        );
    }
}

export default Hotel;