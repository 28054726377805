import React from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import Containers from '../src/lab/Containers.jpg';
import READYTODISPATCH from '../src/lab/READYTODISPATCH.png';
import QA from '../src/lab/QA.jpg';
import DESIGNING from '../src/lab/DESIGNING.jpg';
import Filling from '../src/lab/Filling.jpg';
import PrivateLabel from '../src/lab/PrivateLabel.jpg';
import LabBot1 from '../src/lab/LabBot1.jpg';
import LabBot2 from '../src/lab/LabBot2.jpg';
import LabBot3 from '../src/lab/LabBot3.jpg';
import LabBot4 from '../src/lab/LabBot4.jpg';
import LabBot5 from '../src/lab/LabBot5.jpg';
import LabBot6 from '../src/lab/LabBot6.jpg';
import MobPt1 from '../src/lab/MobPt1.jpg';
import MobPt2 from '../src/lab/MobPt2.jpg';
import MobPt3 from '../src/lab/MobPt3.jpg';
import MobPt4 from '../src/lab/MobPt4.jpg';
import MobPt5 from '../src/lab/MobPt5.jpg';
import MobPt6 from '../src/lab/MobPt6.jpg';




import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default function Example() {
    return (
        <>
      <div className="AccordionItem_main_section">
        <h4>WE ARE MAKING PRODUCTS FOR A WIDE GAMUT OF BRANDS<br/> COMPLYING WITH KOSHER, EUROPEAN UNION, COSMOS AND US FDA.</h4>
        <div className="product_green_heading">
            <h5>we are product <span className="plus">+</span> green brand factory with <br/> <span>end to end services</span>  </h5>
        </div>
        <form>
        <div className="primary_main one"> 
        <input type="radio" name="size" id="small" value="small" defaultChecked />      
 
           <label for="small">
           <img src={LabBot1} className="img-responsive--"  alt="..."/>
           </label>
  
    <article>  
      <p>
          <img src={Containers} className="img-responsive--"  alt="..."/>
      </p>
      </article>
    </div>
    <div className="primary_main two"> 
    <input type="radio" name="size" id="medium" value="medium" /> 
      
    <label for="medium"> <img src={LabBot2} className="img-responsive--"  alt="..."/></label>
 
        <article>
        <p>
          <img src={Filling} className="img-responsive--"  alt="..."/>
      </p>
            </article>    
    </div>
    <div className="primary_main thr"> 
    <input type="radio" name="size" id="large" value="large" />  
   
    <label for="large"> <img src={LabBot3} className="img-responsive--"  alt="..."/></label>
    
        <article>
        <p>
          <img src={PrivateLabel} className="img-responsive--"  alt="..."/>
      </p>
                </article>
    </div>
    <div className="primary_main fo">   
    <input type="radio" name="size" id="xlarge" value="xlarge" />   
     
    <label for="xlarge"> <img src={LabBot4} className="img-responsive--"  alt="..."/></label>
   
        <article>
        <p>
          <img src={QA} className="img-responsive--"  alt="..."/>
      </p>
             </article>
    </div>
    <div className="primary_main five">   
    <input type="radio" name="size" id="Design" value="Design" />    
     
    <label for="Design"> <img src={LabBot5} className="img-responsive--"  alt="..."/> </label>
   
        <article>
        <p>
          <img src={DESIGNING} className="img-responsive--"  alt="..."/>
      </p>

             </article>
    </div>
    <div className="primary_main six">    
    <input type="radio" name="size" id="dispatch" value="dispatch" />  
    
    <label for="dispatch">  <img src={LabBot6} className="img-responsive--"  alt="..."/> </label>
    
        <article>
        <p>
          <img src={READYTODISPATCH} className="img-responsive--"  alt="..."/>
      </p>
                </article>
    </div>
</form>



        </div>


        <div className="AccordionItem_main_section_mobile">
              <div className="row">
                  <div className="col-md-6">
                      <div className="AccordionItem_main_section_mobile_middle">
                          <h5>primary containers</h5>
                          <img src={MobPt1} className="img-responsive--"  alt="..."/> 
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="AccordionItem_main_section_mobile_middle">     
                          <h5> Manufacturing, Filling & labelling</h5>
                          <img src={MobPt2} className="img-responsive--"  alt="..."/> 
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="AccordionItem_main_section_mobile_middle">
                          <h5>printing</h5>
                          <img src={MobPt3} className="img-responsive--"  alt="..."/> 
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="AccordionItem_main_section_mobile_middle">
                          <h5>quality control </h5>
                          <img src={MobPt4} className="img-responsive--"  alt="..."/> 
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="AccordionItem_main_section_mobile_middle">
                          <h5>Design</h5>
                          <img src={MobPt5} className="img-responsive--"  alt="..."/> 
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="AccordionItem_main_section_mobile_middle">
                          <h5>FG ready to dispatch</h5>
                          <img src={MobPt6} className="img-responsive--"  alt="..."/> 
                      </div>
                  </div>
              </div>
        </div>


        </>
    );
}