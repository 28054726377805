import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';


class Custom1 extends Component {
    render() {
        return ( 
            <div className="custom-1mainpage">
               <div className="make-top-banner">
                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-1_iCa0oe2fj2.jpg?updatedAt=1629223838611"} className="img-responsive"  alt="..."/>
                </div>
                
                <div className="container">
                 
                     <div className="top_main_page_ctstom1">
                     <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="flipInX" >
                             
                         <h2>ALL THINGS GREEN & YOU</h2>
                         <h5>SIMPLY FILL OUT OUR PRODUCT REQUEST FROM <span>TO PROVIDE US YOUR CONCEPT IDEA.</span></h5>
                     </ScrollAnimation>
                     </div>
                     <div className="row top_main_page_ctstom2">
                         <div className="col-md-4">
                             <div className="middle_img_frame_custom-1">
                             <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInLeft" >
                                 <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-2_wSRKRodEO.jpg?updatedAt=1629223838487"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="middle_img_frame_custom-1">
                                 <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-3_WnZ44_6QwN.jpg?updatedAt=1629223839127"} className="img-responsive"  alt="..."/>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="middle_img_frame_custom-1">
                             <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight" >
                                 <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-4_I_qwDqdaH.jpg?updatedAt=1629223840101"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                            
                             </div>
                         </div>
                     </div>
                     <div className="CustomFor_cutat1">
                     <AnimationOnScroll animateIn="animate__bounceIn">
                         <h2>CUSTOM FORMULATE</h2>
                      </AnimationOnScroll> 
                         <div className="CustomFor_cutat2">
                         <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="pulse">
                           <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-7_0_GGnCnY5.jpg?updatedAt=1629223838507"} className="img-responsive"  alt="..."/>
                           </ScrollAnimation> 
                           <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                          
                         </div>
                     </div>
                        
                      
                        <div className="row bespoke_main_bottom">
                        <div className="col-md-1"></div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led Ne">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4><span>BESPOKE COSMETIC</span>FORMULATIONS</h4>
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-5_2GdB5kksd.jpg?updatedAt=1629795521362"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </ScrollAnimation>      
                                   </div>
                              </div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led two Ne">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4><span>EXPERT GUIDENCE &</span>ADVANCED INNOVATION</h4> 
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-5-new_dae2QFhw4.jpg?updatedAt=1629795521337"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </ScrollAnimation>
                                   </div>
                              </div>
                              <div className="col-md-1"></div>
                        </div>
                        <div className="bespoke_main_row">
                          
                             <h3>UNDERSTANDING YOUR BRAND</h3> 
                        
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle n">
                                 <div className="col-md-12">
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation>
                                 </div>
                                 
                            </div>          
                        </div>
                       
                </div>

     {/* 2nd page start */}
     
     <div className="custom-3new_page">
               
                <div className="container">
                <div className="custom-2-heading_main_page">
                <AnimationOnScroll animateIn="animate__bounceIn">
                        <h2>COSMETIC DEVELOPMENT COMPANY</h2>
                    </AnimationOnScroll>
                        <div className="CustomFor_cutat2">
                        <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true}  animateIn="zoomIn">
                           <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-22_BiuvfnSgw.jpg?updatedAt=1629277188047"} className="img-responsive"  alt="..."/>
                         </ScrollAnimation> 
                           <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                         </div>

                    </div>

                    <div className="bespoke_main_row">
                    <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>DEVELOP UNIQUE COSMETICS</h3>  
                    </AnimationOnScroll>
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle one">
                             <div className="col-md-3">
                             <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={3} animateIn="bounceInLeft">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-12_VrHPMJGA2.jpg?updatedAt=1629269044800"} className="img-responsive"  alt="..."/>
                            </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true}  animateIn="bounceInRight">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                   </ScrollAnimation> 
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>COSMETICS PRODUCT DEVELOPMENT PROCESS</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle two">
                             <div className="col-md-3">
                                 <ScrollAnimation offset={10}  animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-13_YsQiFQxxT2.jpg?updatedAt=1629269044888"} className="img-responsive"  alt="..."/>
                                    </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation> 
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>DEVELOPMENT OF GREEN BEAUTY PRODUCT</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle three">
                             <div className="col-md-3">
                                   <ScrollAnimation offset={10}  animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-14_KwaV3PHIc.jpg?updatedAt=1629269045888"} className="img-responsive"  alt="..."/>
                                    </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation>
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>HOW LONG DOES IT TAKE?</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle four">
                             <div className="col-md-3">
                             <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                  <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-15_3DZ_4THKq.jpg?updatedAt=1629269046497"} className="img-responsive"  alt="..."/>
                              </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </ScrollAnimation>
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>COSMETIC FORMULATION</h3>  
                        </AnimationOnScroll>
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle five">
                             <div className="col-md-3">
                             <ScrollAnimation offset={10}  animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-16_pY-c68Esr.jpg?updatedAt=1629269044653"} className="img-responsive"  alt="..."/>
                             </ScrollAnimation>
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation>
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="row bespoke_main_bottom">
                        <div className="col-md-1"></div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>BESPOKE FORMULATIONS</h4>
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-18_KDDsQO6M_.jpg?updatedAt=1629272727964"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </ScrollAnimation>      
                                   </div>
                              </div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led two">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>STABILITY & COPMPATIBILITY TESTING</h4> 
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-19_D4sUJzN7t.jpg?updatedAt=1629272728873"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </ScrollAnimation>
                                   </div>
                              </div>
                              <div className="col-md-1"></div>
                        </div>
                        <div className="row bespoke_main_bottom">
                        <div className="col-md-1"></div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led treee">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>RESEARCH & DEVELOPMENT FOR INITIAL PROJECT BREIF</h4>
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-17_TttPkVorp.jpg?updatedAt=1629272091938"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                     </ScrollAnimation> 
                                   </div>
                              </div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led four">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>TRIAL BATCH ASSESMENTS</h4> 
                                       </AnimationOnScroll>
                                       <ScrollAnimation offset={10}  animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
                                         <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-20_kGN2ho8rW.jpg?updatedAt=1629272729794"} className="img-responsive"  alt="..."/>
                                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                       </ScrollAnimation>
                                   </div>
                              </div> 
                              <div className="col-md-1"></div>
                        </div>

                        <div className="bottom_frame_cuts-3">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                            <h4>COSMETIC PRODUCT DEVELOPMENT TIMELINE</h4>
                        </AnimationOnScroll>
                            <div className="bottom_frame_cuts-3_cont">
                            <AnimationOnScroll animateIn="animate__bounceIn">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </AnimationOnScroll>
                            </div>
                        </div>   


                </div>
            </div>

{/* 2nd page end */}


{/* 3rd page start */}

<div className="custom-2ndmainpage">    
                 
                <div className="container">
                    <div className="custom-2-heading_main_page">
                    <AnimationOnScroll animateIn="animate__bounceIn">
                        <h2>COSMETIC PRODUCT MANUFACTURING</h2>
                    </AnimationOnScroll>
                        <div className="CustomFor_cutat2">
                        <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                           <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-21_pbvqACNsm.jpg?updatedAt=1629272727581"} className="img-responsive"  alt="..."/>
                        </ScrollAnimation> 
                           <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                         </div>

                    </div>
                    <div className="bespoke_main_row">
                    <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>WHAT WE FORMULATE</h3> 
                             </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle new">
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInLeft">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-3">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-8_5d8hH6xe_.jpg?updatedAt=1629264737938"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                                 </div>
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>GREEN BEAUTY COSMETIC PRODUCTION</h3> 
                            </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle new2">
                             <div className="col-md-3">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-9_aXQOkeSbFl.jpg?updatedAt=1629264738042"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation>    
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </ScrollAnimation> 
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>COSMETIC MANUFACTURING CAPABILITIES</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle new3">
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-3">
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                   <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-10_C1IePuRjq.jpg?updatedAt=1629264737936"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                                 </div>
                            </div>          
                        </div>
                        
                       <div className="row product_fl_main">
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                
                                    
                                
                                <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                <h4>PRODUCT FILLING</h4> 
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-26_TQrux3__3.jpg?updatedAt=1629278697483"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                         <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                <AnimationOnScroll animateIn="animate__bounceIn">
                                    <h4>PACKAGING</h4>
                                </AnimationOnScroll> 
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-25_p4IVP87EA.jpg?updatedAt=1629278696613"} className="img-responsive"  alt="..."/>
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                    <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                <AnimationOnScroll animateIn="animate__bounceIn">
                                    <h4>QUALITY CONTROL</h4> 
                                    </AnimationOnScroll>
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-24_sVpV9WrEx.jpg?updatedAt=1629278695733"} className="img-responsive"  alt="..."/>
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                    <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                    <h4>RAW MATERIAL</h4> 
                                     <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-23_gAvT0flcB.jpg?updatedAt=1629278695252"} className="img-responsive"  alt="..."/>
                                    </ScrollAnimation> 
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                    <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </AnimationOnScroll>
                                </div>
                            </div>
                       </div>
                       <div className="pro-mu_all_bottom">
                       <h2>COSMETIC PRODUCT MANUFACTURING</h2>
                       <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={1} animateIn="slideInUp">
                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                       </ScrollAnimation>
                       </div>




                </div>
            </div>

{/* 3rd page end */}

            </div>
        );
    }
}

export default Custom1; 