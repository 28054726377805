import React, { Component } from "react";
import {Helmet} from "react-helmet";


export default class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
  render() {
    return <div>    
		<Helmet>
		<meta charset="utf-8" />
	  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="Vert laboratoire is an urban & innovative endeavor to produce, manufacture high quality , organic ingredients that are sustainably sourced and carefully crafted for a competitive price & a truly customized premium product line." />
	<title>Story of VertLaboratoirecosmetics organic natural Manufacturer India   </title>  
		</Helmet>
<section id="banner-frame">
      <img src={"https://ik.imagekit.io/deepak/About_EJIQ41RH0.jpg"} className="img-responsive"  alt="..."/>
</section>
<section className="hotel-amenities-top-middle">
      <div className="container">
	     <div className="headeingamd">
			<h3>About Us</h3>
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
		</div>
		<div className="pa-tag-heading">  
		    <p>Vért Laboratoire is an urban &amp; innovative endeavour to manufacture high quality natural organic products that are made with ingredients, sourced sustainably and carefully crafted for a competitive price &amp; a truly personalized premium product line. Our Clean Beauty contemporary creations are rooted in nature with plant-derived raw material and enriching essential oils that offer
an experience of synaesthesia from head to toe!
</p>
<p>We provide complete end-to-end private labelling service right from Packaging to Finished Good, ready to sell products, designed and manufactured in house, along with marketing &amp; design service as well.</p>
<p>We practice zero waste and recycling protocols to maintain an eco-friendly manufacturing
operation at our factory.</p>

<p>Our in house Research &amp; Development team with 35 years+ expertise is inspired, imaginative,
inventive and is given the freedom of creativity to work with a wide variety of clients that require attention to detail and a comprehensive focus on product brief.</p>
		</div>
	  </div>
</section>
<section className="hotel-amenities-top-middle" id="new">
      <div className="container">
	     <div className="headeingamd">
			<h3>Our Vision</h3> 
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
		</div>
		<h3>CONTEMPORARY COSMOCEUTICALS <br /> THAT ENRICH NOT EXPLOIT</h3> 
		<div className="pa-tag-heading">
		    <p>Vert envisions to enhance the well-being of our customers around the world by delivering the<br /> highest quality, best value Natural and Organic products, bringing green beauty to your<br /> daily personal care regimen.</p>
		    <p>Designed to be totally functional, our creations are formulated with powerful botanicals and<br /> high performance molecules. Formulated with a reinvigorating natural aroma in cooling-soothing<br />
textures that don’t contain any unnecessary ingredients <span>in order to achieve the best possible<br />
formulary combination that fits each client’s philosophy.</span></p>
		</div>
		<div className="headeingamd">
			<h3>Our Promise</h3> 
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
		</div>
		<div className="pa-tag-heading our-po">
		    <p>Designed, Formulated, and Tested for Effectiveness.</p>
		    <p>The beauty you want, minus the ingredients you might not. To get it just right, we got in touch with<br /> naturalists and green minds and asked you exactly what you're looking for in clean beauty. 
</p>
<p>When you spot our Clean seal, you can be sure our products include only the best of nature that<br />
exclude anything artificial. It's a curation of beauty bursting with innovation, big results, and<br />
powerful ingredients.</p>
<p>We promise our products are all free from harmful <span>Parabens, Sulfates SLS and SLES,<br />
Phthalates, Formaldehydes, Formaldehyde-Releasing Agents, Retinyl Palmitate,<br />
Oxybenzone, Hydroquinone, Triclosan, Petrochemicals, Silicon, Mineral oil, Fillers,<br />
Synthetic Dye, Aerosols and triclocarban.</span></p>
		</div>
	  </div>
</section>
<section class="hotel-amenities-top-middle-new">
    <img src={"https://ik.imagekit.io/deepak/about-new_bFEJLxVae.jpg"}  class="img-responsive" alt="..." />
</section>
  <section className="hotel-amenities-top-middle our-misson">
    <div className="container">
      <div className="headeingamd">
      <h3>Our Mission</h3> 
      <img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
      </div>
      <div className="pa-tag-heading">
      <p>Our MISSION is to contribute to the development of a skin + Hair Care culture with<br />
      <span>future-focused</span> products that help secure a sound and sustainable tomorrow for our customers and<br /> the industry, by bringing innovation into the beauty business worldwide.</p>
      </div>    
    </div>
  </section>
    </div>;
  }
}
