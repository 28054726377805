import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HairCare extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
            <div className="make-top-banner">
               <img src={"https://ik.imagekit.io/vert/vertlaboratoire/banner-2_Po0AQuKS-O.jpg"} className="img-responsive"  alt="..."/>
            </div>
            <div className="makeup-middle-sect Body-care mencare">
            
             <div className="container">
                  <div className="percare_care">
                  <p><span className="bea-t">g</span> <span className="bea-t-right">
                  lobal natural hair care market in 2019 was USD 8.74 billion* and is still growing exceedingly. Rise in the awareness of <b>natural products</b> and the benefits of these products is increasing the growth of the market. Change in lifestyle and fashion trends with heightening of new, more effective and safer natural products is boosting the growth of the market.  </span></p>
                  </div>
             </div>

             <div className="mencare_mid_cont">
             <div className="container">
                 <div className="row">
                     <div className="col-md-8"> 
                     <p><span className="bea-t">I</span> <span className="bea-t-right">
                     t’s easy to overlook what one can’t see, which is why many of us ignore an areas which is out of sight (our scalp). Lack of care can lead to conditions that will demand one’s attention. As the scalp is an extension of your face and is too exposed to <b>dryness, dandruff, irritation and itchiness.</b> Using the right products for your scalp is very important. <b>Organic and natural products</b> for treatment of your scalp is the new way of flaunting your hair rather than using <b>harsh chemicals</b> and disturbing the growth and texture of your hair. 
                  </span></p>
                  
                      </div>
                     <div className="col-md-4">  
                       <img src={"https://ik.imagekit.io/vert/vertlaboratoire/men1_jK43ecYlQ.gif"} className="img-responsive"  alt="..."/>
                     </div>
                 </div>
                 <div className="row">
                 <div className="col-md-4">  
                       <img src={"https://ik.imagekit.io/vert/vertlaboratoire/men2_gfjWf6Y3K.gif"} className="img-responsive"  alt="..."/>
                     </div>
                     <div className="col-md-8">  
                     <p><span className="bea-t">W</span> <span className="bea-t-right">
                     ith the use of <b>chemical based shampoos and conditioners</b> the detrimental effects such as rough scalp, bad hair quality and itchiness, roughness has provoked the consumers to use <b>natural/ plant based products.</b> Chemical based products are infused with sulfates, alcohol, parabens and mineral oils. Use of isopropyl alcohol which is found in large hair care products as this changes the natural qualities of <b>hair, inhalation of vapor can cause nausea, dandruff, dizziness, headaches and vomiting.</b> 
                    </span></p>
                  
                      </div>
                     
                 </div>
                 <div className="row">
                     <div className="col-md-8"> 
                     <p><span className="bea-t">R</span> <span className="bea-t-right">
                      ise in the awareness regarding the benefits of natural products for hair care is a key growth factor for the market. Natural hair care products contain <b>antioxidants</b> which contain <b>vitamin E</b> and gives nourishment to scalp and helps in growth. However, using natural ingredients in your hair care products gently infuse your skin cells and hair follicles with oils, natural minerals and herbal extracts. Natural products also helps to avoid elements that are harsh elements which are found in chemical based products. So the likeliness of allergies, irritation and other problems is <b>significantly low.</b> Moreover, these elements are naturally driven and impose less damage on the environment. 
                  </span></p>
                  
                      </div>
                     <div className="col-md-4">  
                       <img src={"https://ik.imagekit.io/vert/vertlaboratoire/men3_eSQ8dkQS6.gif"} className="img-responsive"  alt="..."/>
                     </div>
                 </div>
                 <div className="row-">
                     <div className="col-md-12"> 
                     <p><span className="bea-t">H</span> <span className="bea-t-right">
                     owever, most consumers want to be sure that they are investing in most secure and safest products. This clearly indicates a huge market potential for natural care products. We, as <b>green manufactures</b> produce non-toxic, chemical free and organic products which are suited not only for green consumers but also take care of the production process and use of these products leave minimum carbon footprints on the ecological system. As <b>private label manufactures</b> can help you build your label with organic and natural products. A must for your brand portfolio launch. 
                  </span></p>
                  <p className="te_sou_right">Sourced* - Grand View Research</p>
                      </div>
                     
                 </div>
                 </div>
             </div>
            

            <div className="rech_now_cont">
                <h5>REACH US NOW TO LAUNCH YOUR BRAND !!!</h5>
                <p><Link to="/contact">Contact Us</Link></p>      
            </div>
  
            </div>
        </div>
        );
    }
}

export default HairCare;