import React, { Component } from 'react';
import TEATREEIMG from '../lab/blog/TEATREEIMG.jpg';
import {Helmet} from "react-helmet";

class BenefitsTeaTreeOil extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish Tea-Tree-Oil">
            <Helmet>
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="robots" content="index, follow" />   
<meta name="description" content="Benefits of Tea Tree Oil " />
<title> Benefits of Tea Tree Oil   </title>  
</Helmet>
             <div className="Image_frame">
                <img src={TEATREEIMG} className="img-responsive"  alt="..."/>
             </div>
     <div className="container">
     
             <div className="Image_frame-middle">
             <div className="Heading_tu">
                 <h1>BENEFITS OF TEA TREE OIL </h1>
                 <p>Have a long history with breakouts, you might be familiar with the advantages of using tea tree oil. After all, tea tree oil can help destroy all the tell-tale signs of not-so-clear skin and leave it looking clarified and radiant. This zit-zapping and pore-purifying elixir also offers an affordable path to clearer skin thanks to its wide range of antimicrobial activity. </p>
             </div>    
             <div className="Heading_tu">
                 <h4>TEA TREE OIL BENEFITS</h4> 
                 <p> <span>•</span>  Terpinene-4-ol, an active ingredient in tea tree oil targets and destroys acne- causing bacteria. Thanks to its anti-bacterial and anti-inflammatory properties. It helps reduce swelling, redness and shrink eruption.  </p>
                 <p> <span>•</span>  It helps heal wound and scars faster as it stimulates the white blood cells that make healing possible. Yes, this means it can very well take care of acne scars.  </p>
                 <p> <span>•</span>  Tea tree oil is known to offer end results similar to that of Benzoyl Peroxide although it may take a comparatively longer time. Unlike OTC treatment for acne, this oil dry out or irritate skin while cleaning acne.  </p>
                 <p> <span>•</span>  If you are looking to achieve that dewy glow while battling acne underneath, a layer of tea tree oil can do the trick. The best part – more hydration and reduced oiliness.  </p>
                 <p> <span>•</span>  If your skin is particularly feeling itchy because of dryness or any other reason, opt for tea tree oil based bathing products.  </p>
                 <p> <span>•</span>  Helps to remove toxins. The harmful and toxic ingredients present in the environment are one of the biggest reasons for skin damage. Still, tea tress oil will penetrate the skin and get rid of all toxic material that have managed to make way onto your skin.  </p>
                 <p> <span>•</span>  Not only does it care of the skin, but also has to offer a spectrum of benefits that helps your hair grow and glow simultaneously. Rely on the natural ingredients in this oil to give your hair length you wish to have. </p>
            <p>Moreover, you can see, tea tree oil can be helpful for a number of reasons. It’s an inexpensive natural alternative to chemical based skincare. Plus this oil is not magic cure all. In fact, some people may experience skin irritation and allergic reaction. Overall, tea tree oil serves many purpose and is a good item to have in hand. </p>
             </div>     
     </div>
     </div>
     </div> 
        );
    }
}

export default BenefitsTeaTreeOil;