import React, { Component } from 'react';
import Bodycare7 from '../img/Bodycare7.mp4'; 
import ViBodyCare from '../img/ViBodyCare.mp4'; 
import Bodycare2 from '../img/Bodycare2.jpg'; 
import Bodycare3 from '../img/Bodycare3.jpg'; 
import Bodycare4 from '../img/Bodycare4.jpg'; 
import Bodycare5 from '../img/Bodycare5.jpg'; 
import Bodycare6 from '../img/Bodycare6.jpg'; 
import Bodycarenew from '../lab/Bodycare2-new.png'; 


class BodyCareNew extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return ( 
            <div className="bodycareNewpage facecare_main-page bodycarepageadd bodycarenewBG">
                      <div className="bodycareNewpageVideo">
                        <video autoPlay loop muted>
                        <source src={ViBodyCare} type="video/mp4"/>
                        <source src={ViBodyCare} type="video/ogg"/>       
                        </video>    
                      </div>
                        <div className="bodycareheading">
                        <div className="container">
                           <img src={Bodycare2} className="img-responsive"  alt="..."/>
                        </div>
                        </div>
                    <div className="bodycare-industry-new">
                    <div className="container">
                        <p>The beauty and personal care products market was valued at USD 434.85 billion in 2020, and it is projected to register a CAGR of 4.35% during the forecast period, 2021-2026.</p>
                        <div className="bodycare-industry"></div>
                        </div>
                    </div>
                    <div className="clear"></div>
                    <div className="bodycare-industry-Globalization">
                    <div className="container">
                       
                        <div className="bodycare-industry-Globalization-bottom">
                            <div className="row">
                                <div className="col-md-8">
                                     <div className="bodycare-industry-Globalization-bottomleftcont">
                                         <p>A major issue faced by the manufacturers during the COVID-19 situation was the disrupted supply chains, especially for cosmetics, for during the lockdown, countries closed their borders or raised trade barriers. As a result, manufacturers and exporters were facing high competition to export their goods. Furthermore, the on-premise sales through supermarkets and convenience stores witnessed a decline due to the subsequent lockdowns and the closure of retail stores, as individuals have been practicing social distancing measures and avoiding gatherings and outings. However, the sales through online retail channels majorly supported the market penetration.</p>
                                     </div>
                                </div>
                               
                                <div className="col-md-4">
                                  <img src={Bodycare5} className="img-responsive"  alt="..."/>
                                </div>
                            </div>
                        </div>
                              
                         </div>
                    </div>
                    <div className="stigma-bodycare">
                    <div className="stigma-bodycare-inner">
                    <div className="container">
                    <div className="row">
                    <div className="col-md-4">
                    <div className="bodycare-girl-page">
                        <img src={Bodycare6} className="img-responsive"  alt="..."/>
                    </div>
                   
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-6 stigma-bodycare-inner_cont">
                         <p>The improving quality of life and the positive effects of beauty and personal care on self-esteem and social interaction. And with the gradual consumer shift toward premium and luxury cosmetic brands are a few factors that are likely to propel the market growth during the forecast period.The demand for Natural and Green Personal care product is reaching a never ending stage and will continuously grow by 2026. </p>
                    </div>     
                    </div>
                    </div>
                    </div>
                    </div>
                  <div className="been-market-long-allbodycare">
                         <div className="been-market-long-allbodycare-middle">
                             <p>Natural ingredients has been in the market since long. Natural products </p>
                             <p>have moved forward with high performance safe formulations instead</p>
                             <p>of harmful chemicals infused products. We take immense pride by offering</p>
                             <p>safe Natural products by providing GMO free, Bio-degradable,  Natural products. </p>
                         </div>
                         <img src={Bodycarenew} className="img-responsive-"  alt="..."/>
                  </div>
                   <div className="clear"></div>

                    <div className="immencepride-bodycare">
                    <div className="container">
                           <p>We take immense pride in formulating safe products without any harmful chemicals like <span className="high">mineral oils, BHT, endocrine disruptors, carcinogens, paraben and petroleum by product and other adverse harmful chemicals. </span> </p>
                           <div className="bodycare-industry2"></div>
                    </div>
                    </div>


                    <div className="focused-bodycare">
                    <div className="container">
                            <div className="focused-bodycare-heading">
                                <h3>WE PROUDLY MANUFACTURE FOCUSED SELF-CARE OFFERING FOR YOUR BRAND LIKE</h3>
                                <div className="focused-bodycare-border"></div>
                            </div>
                            <div className="row focused-bodycare-bottom">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                         <div className="col-md-4">
                                             <ul className="first-body-care">
                                                 <li>Body Butters</li>
                                                 <li>Body Scrubs</li>
                                                 <li>Body Oils</li>
                                                 <li>Body Masks </li>
                                                 <li>Body Lotions</li>
                                                 <li>Smoothie Soaps</li>
                                                 <li>Sunscreen Lotions</li>
                                                 <li>Massage Oils</li>
                                                 <li>Shaving Creams</li>
                                                 <li>Handmade Soaps</li>
                                             </ul>
                                         </div>
                                         <div className="col-md-4 second-body-care4">
                                             <ul className="second-body-care">
                                                 <li>Tan Removal Creams </li>
                                                 <li>Tan Removal Body Masks </li>
                                                 <li>Tan Removal Scrubs</li>
                                                 <li>Shower Gels</li>
                                                 <li>Booty Scrubs</li>
                                                 <li>Booty Masks</li>
                                                 <li>Cellulite Masks</li>
                                                 <li>Boobs Masks</li> 
                                                 <li>Boobs Scrubs</li>
                                                
                                             </ul>
                                         </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-4">
                                    <div className="bodycareNewpageVideo-right">
                                    <video autoPlay loop muted>
                                    <source src={Bodycare7} type="video/mp4"/>
                                    <source src={Bodycare7} type="video/ogg"/>       
                                    </video>    
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>




                    <div className="productsinfusedwith-bodycare_frame">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="productsinfusedwith-bodycare_frame-top">
                                <p>
                                <span className="high">We formulate and manufacture Organic and Natural</span> products are loaded with herbs, fruits and natural clay which helps to cleanse your body, nourish and repair without harming or disturbing the PH of your skin. We are <span className="high">private label organic manufacturers</span> and can help you build your label with <span className="high">organic and natural</span> products. </p>
                            </div>
                            </div>
                           
                            </div>
                            <div className="clear"></div>
                    </div>
            </div>
           
        );
    }
}

export default BodyCareNew;