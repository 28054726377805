import React, { Component } from 'react';
import Exfoliation1 from '../img/Exfoliation1.jpg'; 
import Exfoliation3 from '../img/Exfoliation3.jpg'; 
import Exfoliation4 from '../img/Exfoliation4.jpg'; 
import Exfoliation5 from '../img/Exfoliation5.jpg'; 
import Exfoliation6 from '../img/Exfoliation6.jpg'; 
import Exfoliation7 from '../img/Exfoliation7.jpg'; 
import Exfoliation8 from '../img/Exfoliation8.jpg'; 
import Exfoliation9 from '../img/Exfoliation9.jpg'; 
import Exfoliation11 from '../img/Exfoliation11.jpg';  
import Exfoliation12 from '../img/Exfoliation12.jpg'; 
import Exfoliation13 from '../img/Exfoliation13.jpg'; 
import Exfoliation14 from '../img/Exfoliation14.jpg'; 
import Exfoliation15 from '../img/Exfoliation15.jpg'; 
import Exfoliation16 from '../img/Exfoliation16.jpg'; 
import Exfoliation17 from '../img/Exfoliation17.jpg'; 
import Exfoliation10 from '../img/Exfoliation10.mp4';       
class FaceExfoliation extends Component { 
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="facecare_main-page Exfoliation Exfoliationpage">
                <div className="facecarebanner">
                     <img src={Exfoliation1} className="img-responsive"  alt="..."/>
                  </div>
                  <div className="facelotion_heading">
                     <h2> FACE EXFOLIATION </h2>    
                     <p>Exfoliation is the process of removing the dead skin cells from the surface of the skin either bychemical or mechanical process. </p>
                 </div>
                 
                  <div className="Exfoliation-Accordingly">
                       <div className="container">
                           <div className="row">
                            <div className="col-md-5">
                                <div className="Exfoliation-Accordingly-left">
                                   <img src={Exfoliation3} className="img-responsive"  alt="..."/>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="Exfoliation-Accordingly-right">
                                   <p>Mechanical exfoliation, the process of exfoliating the skin by hand by scrubbing with something abrasive has been practiced all over the world and throughout history. American Indians used dried corncobs. The people of the Comanche tribe would use sand from the bottom of a river bed to scrub the skin. Polynesian people would use crushed sea shells.</p>
                                   <p>Mechanical exfoliation is practiced since Ancient Egypt where they employed a variety of methods. Pumice stones were popular abrasive as well as other minerals such as alabaster particles and scrubs made from sand and the aloe vera plant. </p>
                                </div>
                            </div>  
                            </div>
                       </div>
                  </div>
                  <div className="Exfoliation-market-frame">
                       <div className="Exfoliation-market-frame-inner">
                           <div className="container">
                            <p>Exfoliation through chemical means, as practiced today, is a science constantly updated with new technology and ingredients. But the practice itself also has a long history dating back to Ancient Egypt when sour milk, which contains lactic acid was used as a chemical exfoliant. Cleopatra is famously said to have frequently bathed in it.</p>
                            </div>
                       </div>
                  </div> 
                   <div className="Modern-Exfoliation">
                       <h3>TO A MODERN DAY FACE SCRUBS WITH MICRO PLASTIC BEADS </h3>
                   </div>
                  <div className="Exfoliation-Accordingly modern">
                       <div className="container">
                           <div className="row">
                           <div className="col-md-1"></div>
                            <div className="col-md-8">
                                <div className="Exfoliation-Accordingly-right">
                                  <p>Microbeads are tiny, round plastic beads that are found in some exfoliating products and they are causing quite a stir in the environmental world. The plastic waste caused by these microbeads, which are not filtered out during sewage treatment, is damaging water ecosystems and killing marine life. A 2013 research at the University of Wisconsin-Superior showed that there were 1,500 to 1.7 million plastic particles per square mile in the Great Lakes. Not only are the beads damaging to fish, but they are also bad for the face – microbeads can cause eye irritation and promote rosacea and couperose. </p>
                                </div>
                            </div> 
                            <div className="col-md-3">
                                <div className="Exfoliation-Accordingly-left">
                                   <img src={Exfoliation11} className="img-responsive"  alt="..."/>
                                </div>
                            </div> 
                            </div>
                       </div>
                  </div>
                  <div className="crucial-step-bodycare">
                  <div className="container">
                      <div className="crucial-step-bodycare-per">
                          <p>At VertLaboratoire, we take conscious steps by not using harmful chemicals like <span>phthalates, sodium lauryl sulfate, petroleum products, triethanolamine, parabens, BHT, retinol, retinylpalmitate, lead, formaldehyde, mineral oil, polyethylene glycol and butylene glycol and other harmful chemicals.</span> </p>
                         <h4>WE PROUDLY USE EXOTIC GREEN EXFOLIATORS LIKE </h4>
                      </div>
                   </div>
                  <div className="container">
                  <div className="row">
                       <div className="col-md-4">
                             <div className="cof-sumainframe">
                             <div className="cof-sumainframe-middle">
                                 <ul>
                                     <li>COFFEE</li>
                                     <li>SUGAR</li>
                                     <li>SALT</li>
                                     <li>PORRIDGE OATS</li>
                                     <li>MILK</li>
                                     <li>RICE</li>
                                     <li>ALMOND POWDER</li>
                                     <li>OATMEAL</li>
                                     <li>HONEY</li>
                                     <li>BROWN SUGAR</li>
                                     <li>FLAX SEED</li>
                                 </ul>
                             </div>
                             </div>
                             <div className="cof-sumainframe two">
                             <div className="cof-sumainframe-middle">
                                 <ul>
                                     <li>CUCUMBER</li>
                                     <li>OATMEAL</li>
                                     <li>GREEN TEA</li>
                                     <li>CURD</li>
                                     <li>APPLE – MALIC ACID</li>
                                     <li>CITRIC ACID</li>
                                     <li>TURMERIC</li>
                                     <li>COCONUT POWDER</li>
                                     <li>CHAMOMILE TEA</li>
                                     <li>COFFEE MUD</li>
                                    
                                 </ul>
                             </div>
                             </div>
                        </div>
                       <div className="col-md-8">
                            <div className="crucial-step-bodycare-per-mg-right_frame">
                                   <div className="crucial-step-bodycare-per-mg-right_frame-inner one">
                                      <img src={Exfoliation5} className="img-responsive"  alt="..."/>
                                      <img src={Exfoliation13} className="img-responsive"  alt="..."/>
                                      <img src={Exfoliation14} className="img-responsive"  alt="..."/>
                                   </div>
                                   <div className="crucial-step-bodycare-per-mg-right_frame-inner two">
                                   <img src={Exfoliation7} className="img-responsive"  alt="..."/>
                                      <img src={Exfoliation15} className="img-responsive"  alt="..."/>
                                      <img src={Exfoliation16} className="img-responsive"  alt="..."/>
                                      <img src={Exfoliation17} className="img-responsive"  alt="..."/>
                                   </div>
                                   <div className="crucial-step-bodycare-per-mg-right_frame-inner three">
                                   <img src={Exfoliation4} className="img-responsive"  alt="..."/>
                                      <img src={Exfoliation6} className="img-responsive"  alt="..."/>
                                      <img src={Exfoliation8} className="img-responsive"  alt="..."/>
                                   </div>
                            </div>
                       </div>
                  </div>
                  </div>
                  </div>
                 


                  <div className="Exfoliation-market-movingframe-b-n">
                       <div className="Exfoliation-market-movingframe-inner-n">
                           <div className="container">
                              <div className="row">
                              <div className="col-md-4 Exfoliation-market-movingframe-inner_cont-n-left">
                                  <h4><span>JOIN THE GREEN SIDE</span><br/> with <br/><span> Vert Laboratoire</span><br/> and start your label<br/> with <span>organic and<br/> natural product now. </span></h4>
                               </div>
                              
                                 <div className="col-md-8 Exfoliation-market-movingframe-inner_cont-n">
                                       <p>We carefully select the highest performing natural ingredients used for exfoliation and are biodegradable ingredients that will not damage the environment and will polish and refresh the face. </p>
                                       <img src={Exfoliation12} className="img-responsive"  alt="..."/>
                                 </div>
                                
                              </div>
                            </div>
                       </div>
                  </div>
                 
                
            </div>
        );
    }
}

export default FaceExfoliation;