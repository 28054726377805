import React, { Component } from 'react';
import babycare1 from '../lab/babycare1.mp4';
import babycare2 from '../lab/babycare2.jpg';
import babycare5 from '../lab/babycare5.mp4';
import babycare6 from '../lab/babycare6.jpg';
import babycare7 from '../lab/babycare7.jpg';

class BabyCare extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="haircarenewpage babycarepageframe">
                 <div className="bodycareNewpageVideo">
                        <video autoPlay loop muted>
                        <source src={babycare1} type="video/mp4"/>
                        <source src={babycare1} type="video/ogg"/>          
                        </video>    
                      </div>
                      <div className="container">
                    <div className="top_head_hair-heading">
                        <img src={babycare2} className="img-responsive"  alt="..."/>    
                        <p>Global baby and mother care market is projected to exhibit a CAGR of 6.5% during 2026. Rising infant population among developing countries along with the growing focus on e-commerce has led to the growth of Baby and Mother Care market. </p>
                         </div>  
                    </div>

                    <div className="babycaresales">
                          <p>The sales of baby care products witnessed a mixed impact of COVID -19. The bulk purchase of baby care products, primarily diapers and baby food, drove the baby care products market during the pandemic. However, baby care products were not accounted as an essential commodity, led to the hampered distribution of these products in such markets. </p>
                    </div>
                    <div className="clear"></div>
                    <div className="babycaresalesrising">
                    <p>Rising baby’s health concerns, including skin diseases and need for nutritional food. With mother’s body undergoes several changes in the course of nine months of pregnancy. Skin changes vigorously and most common symptoms are stretch marks, pink purplish lines, itchiness, rashness, back pain, pigment change, fluctuating hormone, freckles and other skin related issues.A women’s body needs accurate care and nourishment over the period of nine months. The overall skin is too sensitive and the need for right products and ingredients is a must.</p>
                    </div>
                    <div className="naturn-skin-frame-babycare">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <video autoPlay loop muted>
                                <source src={babycare5} type="video/mp4"/>
                                <source src={babycare5} type="video/ogg"/>          
                                </video>  
                            </div>
                            <div className="col-md-7">
                                 <p>Our Natural skin care products are bursting with natural and toxic-free extracts. Our pregnancy skin care products and hair care products help ease morning sickness, reduce stretch marks, treat postpartum hair fall and swollen feet. It’s always a great idea to give your skin safe, effective and nourishing ingredients that are healthy for both mother and baby. We proudly manufacture safe products by using these ingredients <span class="high">coconut oil, almond oil, aloe vera,butters, plants extracts, flower extract, fruit extract, teas extract, berries extract, willow bark, vitamin C, chamomile, aloe and essential oilsand many more.</span> </p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="container">
                        <div className="babycaresalesrisingnaut">
                          <p> We take immense pride in formulating safe products without any harmful chemicals like <span class="high">phthalates, pesticides, BPA, formaldehyde, formaldehyde releasers, talc, harmful distruptons, 1,4-dioxane and ethylated surfactants, mineral oil, parabens and triclosan other adverse chemicals. </span></p>
                       </div>
                    </div>
                    <div className="produly-heading-main-babycare">
                        <h3>WE PROUDLY MANUFACTURE SUBSTANTIALLY CAREFUL <br/>OFFERINGS FOR MOM AND BABY RANGE INCLUDES</h3>
                    <div className="container">
                         <div className="row">
                             <div className="col-md-4">
                                 <ul className="first">
                                     <li>BABY LOTION</li>
                                     <li>BABY BODY WASH </li>
                                     <li>BABY TEAR FREE SHAMPOO</li>
                                     <li>BABY MASSAGE OIL</li>
                                     <li>BABY DIAPER RASH CREAM</li>
                                     <li>BABY HAIR OIL</li>
                                     <li>BABY SUNSCREEN</li>
                                     <li>NATURAL BODY BUTTER</li>
                                     <li>STRETCH MARKS OIL</li>
                                     <li>BODY LOTION</li>
                                 </ul>
                             </div>
                             <div className="col-md-4">
                             <img src={babycare6} className="img-responsive"  alt="..."/> 
                             </div>
                             <div className="col-md-4">
                                 <ul className="second">
                                     <li>NIPPLE BUTTER</li>
                                     <li>FOOT CREAM </li>
                                     <li>BODY WASH </li>
                                     <li>SHAMPOO </li>
                                     <li>CONDITIONER</li>
                                     <li>UNDER EYE CREAM</li>
                                     <li>FACE WASH </li>
                                     <li>HAIR SERUM</li>
                                     <li>FACE CREAM </li>
                                     <li>BELLY BUTTER</li>
                                    
                                 </ul>
                             </div>
                         </div>
                         </div>
                     </div>

                     <div className="lastlinebabycareframe">
                         <div className="row">
                         
                             <div className="col-md-9">
                                 <p><span class="high">We formulate and manufacture Organic and Natural</span> products are loaded with herbs, fruits and natural clay which helps to cleanse your body, nourish and repair without harming or disturbing the PH of your skin. We are <span class="high">private label organic manufacturers</span> and can help you build your label with <span class="high">organic and natural</span> products.</p>
                             </div>
                             <div className="col-md-3">
                                <img src={babycare7} className="img-responsive"  alt="..."/> 
                             </div>
                         </div>
                     </div>


            </div>
        );
    }
}

export default BabyCare;