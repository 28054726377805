import React, { Component } from 'react';
import Facecare1 from '../lab/Facecare1.jpg'; 
import Facecare3 from '../lab/Facecare3.jpg'; 
import Facecare5 from '../lab/Facecare5.jpg'; 
import Facecare6 from '../lab/Facecare6.jpg'; 

class FaceMask extends Component { 
     componentDidMount() {
          window.scrollTo(0, 0)
        }
    render() {
        return (
            <div className="facecare_main-page mask facemaskpage">
                  <div className="facecarebanner">
                     <img src={Facecare1} className="img-responsive"  alt="..."/>
                  </div>
                  <div className="facelotion_heading">
                     <h2>FACE MASK</h2>    
                 </div>
                 
                  <div className="mask-market-movingframe mask">
                       <div className="mask-market-movingframe-inner">
                           <div className="container">
                              <div className="row">
                              <div className="col-md-5">
                                  <img src={Facecare3} className="img-responsive"  alt="..."/>
                                  </div>
                                 <div className="col-md-6 mask-market-movingframe-inner_cont">
                                      <p>The <span class="high">face mask</span> market is expected to grow at CAGR 8 % by 2025. The global face mask market is booming due to the use of <span class="high">natural ingredients</span> based products in cosmetics. Products which claim <span class="high">natural, herbal and organic</span> are highest selling globally. Face masks are in trends due to the demand for flawless and shiny skin. Past generations have used backyard/home products like <span class="high">sandalwood paste, turmeric, aloe-Vera and lemon</span> for beautifications and <span class="high">personal care</span> which lost their charm in the emergence of <span class="high">chemical formulations.</span> </p>
                                      </div>
                                
                              </div>
                            </div>
                       </div>
                  </div>
                  <div className="nastyche-frame-mask">
                        <div className="nastyche-frame-mask-border1"></div>
                        <div className="nastyche-frame-mask-cont">
                             <p>However, due to nasty chemicals filled products and long term skin concerns like <span class="high">itching, irritation, anxiety, mood swings and insomnia.</span></p>
                        </div>
                        <div className="nastyche-frame-mask-border2"></div>
                  </div>
                  <div className="clear"></div>
                  <div className="mask-market-frame thik">
                  <div className="container">
                       <div className="mask-market-frame-inner"> 
                                 <p>We manufacture organic and natural products which helps to deeply cleanse the pores, rejuvenate, refresh and revitalize the skin. Also,  help to <span className="high">tighten pores, reduce facial skin wrinkles, skin clarity</span> and other skin related concerns. We incorporate the ingredients like <span className="high">moringa seed, flax seeds, sweet marjoram, seaweed, microflora, mushroom, baobab, turmeric, coconut oil and aloe vera. </span> </p></div>
                       </div>
                  </div>

                       <div className="pride-formlation-maskframe">
                       <div className="container">
                            <p>We take pride in formulating our product without these harmful chemicals like <span class="high">sulfates, parabens, phthalates, synthetic colors, harmful fragrances talc, triclosan and others harmful chemicals.</span></p>
                            <h4>AT VERT LABORATOIRE WE OFFER EXOITIC MASKS LIKE</h4>
                            </div>
                       </div>


                  <div className="mask-market-movingframe-n However-n">
                       <div className="mask-market-movingframe-inner-n">
                           <div className="container">
                              <div className="row">
                              <div className="col-md-6">
                                  <img src={Facecare5} className="img-responsive"  alt="..."/>
                               </div>
                                 <div className="col-md-6 mask-market-movingframe-inner_cont-n">
                                    <ul>
                                         <li><span>&#8594;</span> CREAM MASKS</li>
                                         <li> <span>&#8594;</span> CLAY MASKS</li>
                                         <li><span>&#8594;</span> GEL MASKS</li>
                                         <li><span>&#8594;</span> EXFOLIATING MASKS</li>
                                         <li><span>&#8594;</span> HYRDOGEL MASKS</li>
                                         <li><span>&#8594;</span> WATER SLEEPING MASKS</li>
                                         <li><span>&#8594;</span> VITAMIN C MASKS</li>
                                         <li><span>&#8594;</span> ACNE MASKS</li>
                                         <li><span>&#8594;</span> HYPERPIGMENTATION MASKS</li>
                                         <li><span>&#8594;</span> DEEP PORE CLEANSING MASKS</li>
                                         <li><span>&#8594;</span> DETOX MASKS</li>
                                         <li><span>&#8594;</span> INTENSE HYDRATING MASKS</li>
                                    </ul>
                                 </div>     
                              </div>
                            </div>
                       </div>
                  </div>

                  <div className="mask-market-movingframe-bu">  
                       <div className="mask-market-movingframe-inner-bu">
                           <div className="container">
                              <div className="row">
                              
                                 <div className="col-md-12 mask-market-movingframe-inner_cont-bu">
                                      <p>We are <span class="high">Organic products</span> manufacturers and can help you build your label with <span class="high">organic and natural products.</span> Create your own <span class="high">unique label</span> that speaks your essence!!</p>
                                 </div> 
                                    
                              </div>
                            </div>
                       </div>
                  </div>

            </div>
        );
    }
}

export default FaceMask;  