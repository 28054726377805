import React, { Component } from 'react';
import Serums1 from '../img/Serums1.jpg'; 
import Serums4 from '../img/Serums4.jpg'; 
import Serums5 from '../img/Serums5.png'; 
import Serums6 from '../img/Serums6.jpg'; 
import Serums7 from '../img/Serums7.jpg'; 
import Serums8 from '../img/Serums8.jpg'; 
import Serums9 from '../img/Serums9.jpg'; 
import Serums10 from '../img/Serums10.png'; 
import Serums11 from '../img/Serums11.jpg'; 

class FaceSurums extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return ( 
            <div className="facecare_main-page serums serumspage">
                <div className="facecarebanner">
                     <img src={Serums1} className="img-responsive"  alt="..."/>
                  </div>
                  <div className="facelotion_heading">
                     <h2>FACE SERUMS/ ELIXIRS</h2>      
                 </div>
                 <div className="serums-market-frame2">
                       <div className="container">
                           <div className="serums-market-frame-inner2">
                                <p>Serums are a concentration of active ingredients, which targets specific skincare concerns. The ingredients are powerful and made up of smaller molecules. Serums concentration is higher than in a usual face cream. </p>
                                <img src={Serums9} className="img-responsive"  alt="..."/>
                            </div>      
                       </div>
                  </div>

                  <div className="serums-market-framenew market-drivennew">   
                       <div className="serums-market-frame-inner-market-drivennew">
                           <div className="container">
                               <div className="row">
                                   <div className="col-md-4 first-new">
                                      <img src={Serums6} className="img-responsive"  alt="..."/>
                                   </div>
                                   <div className="col-md-7 second-new">
                                   <p> The market is driven by ‘Green Beauty” and Clean Beauty products. These products are luxurious, high in quality and has long terms effects on your skin. Demand for high performance formulations instead of harmful chemical products is changing the skincare market. The movement towards ethical labelling and sustainability is touching people’s heart.  </p> 
                                   </div>
                               </div>
                            </div>
                       </div>
                  </div>
                  <div className="container Face-serums-elixir-frame">
                      <div className="Face-serums-elixir-frame-middle">
                      <div className="Face-serums-elixir-frame-middle2"></div>  
                          <p>We carefully construct our products by <span class="high">not using harmful chemicals</span> like <span class="high">petro-ingredients, silicones, synthetic preservatives, harmful fragrances and harmful colorants ingredients.</span> We take pride in offering the most <span class="high">natural organic</span> decoctions for all such concern as a <span class="high">Green Cosmetics Manufacturer.</span></p>
                          <img src={Serums10} className="img-responsive"  alt="..."/>
                       </div>
                  </div>
                 
                  <div className="serum-market-movingframenew">
                       <div className="serum-market-movingframe-innernew">
                           <div className="container">
                              <div className="row">
                              <div className="col-md-2"></div>
                                 <div className="col-md-5 serum-market-movingframe-inner_contnew">
                                     <div className="serum-market-movingframe-inner_contnew-middle">
                                     <img src={Serums11} className="img-responsive"  alt="..."/>  
                                          <h4>WE OFFER<br/> POWERFUL CONCENTRATIONS<br/> LIKE</h4> 
                                     </div>
                                 </div>
                                  <div className="col-md-5 serum-market-movingframe-inner_contnew-right">   
                                       <ul>
                                           <li>HYDRATING SERUMS</li>
                                           <li>ANTIOXIDANT SERUMS</li>
                                           <li>BRIGHTENING SERUMS</li>
                                           <li>RESURFACING SERUMS</li>
                                           <li>CLARIFYING SERUMS</li>
                                           <li>PORE – MINIMIZING SERUMS</li>
                                           <li>ANTI – AGING SERUMS</li>
                                           <li>CALMING SERUMS</li>
                                       </ul>
                                  </div>
                              </div>
                            </div>
                       </div>
                  </div>

                  <div className="serum-market-movingframe-Organic2 facesurum">
                       <div className="serum-market-movingframe-innern">
                           <div className="container">
                              <div className="row">
                              
                             
                                 <div className="col-md-6 serum-market-movingframe-inner_cont-n">
                                      <p>We are <span class="high">Organic products manufacturers</span> and can help you build your label with <span class="high">organic and natural</span> products. Create your own <span class="high">unique label</span> that speaks your essence!!</p>
                                      </div>
                                      <div className="col-md-6">  
                                  <img src={Serums5} className="img-responsive"  alt="..."/>
                                  </div>
                                  
                              </div>
                            </div>  
                       </div>
                  </div>
            </div>
        );
    }
}

export default FaceSurums;