import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class PlasticsInCosmetics extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div>
                  <Helmet>
			<meta charset="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta name="robots" content="index, follow" />   
			<meta name="description" content="A Non-Living Burden/Terrorist " />
			<title>A Non-Living Burden/Terrorist </title> 
       </Helmet>
            <div className="blog-detail-page">
                 <div className="container">
                      <div className="Heading-blog">
                          <h1>A Non-Living Burden/Terrorist </h1>
                      </div>
                    <div class="blog-con-frame">
                       
                        <p>In our plastic filled world, avoiding plastic can be really challenging. Blue planetdocumentary has given a shocking wakeup call, more people are waking up to the unsustainable way humans are residing. Where ever you go, you will find plastic as your life partner isn’t it??? </p>
                        <p>Plastic in cosmetics is a design error, as the world is changing so is the technology. Facing global warming emergency and depletion in the environment makes the whole scenario more heartbreaking. But there is hope for our planet, if we do our part and make sure that we contribute in to make ourselves Greener.  </p>
                        <div className="main_middle_platic row">
                            <div className="main_middle_platic-left col-md-7">
                                <img src="https://ik.imagekit.io/vert/pa_V_2SO1ZXs4.jpg" className="img-responsive" alt=""/>
                            </div>
                            <div className="main_middle_platic-right col-md-5">
                            <img src="https://ik.imagekit.io/vert/pa2_CcvUL43hH.jpg" className="img-responsive" alt=""/>
                            </div>

                        </div>
                        <p>By saying no to plastics doesn’t mean, not using plastic bags or not spilling garbage and not using plastic bottles. This is just a bit of that you can contribute, but switching towards green cosmetics and using natural and organic products can make a difference. Cosmetics is growing the beauty business is over $500 billion and is estimated to grow to $820 billion by 2023*. Vegan skincare product with sustainable packing can make an impact. Do you know the tiny granules in your unreasonably priced shower gel? They are tiny smithereens of plastic used in personal care products, designed to go down the drain and into our lakes, oceans and rivers. They are Micro-beads, which absorb toxins in water and are consumedby the marine life. A trend, which if continued, will mean we’ll have more plastic in our oceans than the fish by 2050. Over 633 marine species have been effected by the plastic problem, sea animals like seals, dolphins and whales are ingesting astonishing amount of toxic micro plastics which effects their reproduction and mortality. </p>
                             
                        <div className="row right_bott_mi_main">
                            <div className="col-md-9">
                             <p>Avobenzone are found in sunscreen and been proven to diminish coral reefs. Volatile organic compound in hairsprays and fragrances contributes to smog and air pollution. Cotton buds and wet wipes which has polypropylene, a plastic which does decompose and cause sewer blockages. Plastics like water bottles, milk cartons, takeout containers, , bags, shampoo bottles, shadow pallets, lipsticks, tubes, powder compact, lotion, pump bottles and other plastic containers which stay on the sea bed and contribute in the growth of plastic on earth. </p>
                            </div>
                            <div className="col-md-3 right_bott_mi">
                            <img src="https://ik.imagekit.io/vert/pa3_P3eppgqvR9.jpg" className="img-responsive" alt=""/>
                            </div>
                        </div>
                      <div className="place_end">
                       <p>We as manufacturers have started our journey with zero waste manufacturing. We are green manufacturers and we believe in organic and natural products with 100% plant based formulation and 100% vegetarian. Beauty industry were the first to respond or stop the usage of plastic in their products. Now the industry has an even greater opportunity to decrease their plastic footprints by introducing reusable or refills alternatives. We as green manufactures have stopped using bottles for shampoo and shower gels instead we use soap bars, as this leaves zero trace behind on the planet. We focus on plant based products with natural ingredients like essential oils, butters, natural extracts, fresh raw material, cocoa butter and coconut oil for our products. In spite of using box for packing we prefer using paper bags, glass jars, glass bottles, wooden box, aluminum containers, we use paper to wrap our products and more over make our packing from used materials which helps us to be zero waste manufacturers. </p>
                       <img src="https://ik.imagekit.io/vert/oil_REshPMr-R.jpg" className="img-responsive_all" alt=""/>
                        <br/>
                        <br/>
                        <p><span>Sourced- * livemint</span></p>
                        </div>
                    </div>
                    <div className="clr"></div>   

                 </div>
            </div>
            </div>
        );
    }
}

export default PlasticsInCosmetics;