import React, { Component,nextButtonClick,prevButtonClick,onPage, } from 'react';
import HTMLFlipBook from 'react-pageflip';
import Shade1 from '../../src/lab/shade/Shade1.jpg';
import Shade2 from '../../src/lab/shade/Shade2.jpg';
import Shade3 from '../../src/lab/shade/Shade3.jpg';
import Shade4 from '../../src/lab/shade/Shade4.jpg';
import Shade5 from '../../src/lab/shade/Shade5.jpg';
import Shade6 from '../../src/lab/shade/Shade6.jpg';
import Shade7 from '../../src/lab/shade/Shade7.jpg';
import Shade8 from '../../src/lab/shade/Shade8.jpg';
import Shade9 from '../../src/lab/shade/Shade9.jpg';
import Shade10 from '../../src/lab/shade/Shade10.jpg';
import Shade11 from '../../src/lab/shade/Shade11.jpg';
import Shade12 from '../../src/lab/shade/Shade12.jpg';
import Shade13 from '../../src/lab/shade/Shade13.jpg';
import Shade14 from '../../src/lab/shade/Shade14.jpg';
import Shade15 from '../../src/lab/shade/Shade15.jpg';
import Shade16 from '../../src/lab/shade/Shade16.jpg';
import Shade17 from '../../src/lab/shade/Shade17.jpg';
import Shade18 from '../../src/lab/shade/Shade18.jpg';
import Shade19 from '../../src/lab/shade/Shade19.jpg';
import Shade20 from '../../src/lab/shade/Shade20.jpg';   

   class MakeupSlider extends React.Component {
      constructor(props) {
         super(props);
         this.flipBook = React.createRef();
      
      }
     

       nextButtonClick = ()=>  {  
         this.flipBook.current.pageFlip().flipNext()
         // console.log(this.flipBook.current)  
       };
     
       prevButtonClick = ()=>  {
         this.flipBook.current.pageFlip().flipPrev();
       };    
      
   render() {
     
      
      return (
         <div>
            <div className="makeup_bottom_slider_section_middle">
         <HTMLFlipBook 
         width={1000}
         height={900}
         size="stretch"
         maxShadowOpacity={0.5}
         showCover={false}
         className="demo-book"
         ref={this.flipBook}
        >
            <div className="demoPage"><img src={Shade1} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade2} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade3} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade4} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade5} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade6} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade7} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade8} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade9} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade10} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade11} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade12} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade13} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade14} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade15} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade15} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade16} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade17} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade18} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade19} className="img-responsive"  alt="..."/></div>
            <div className="demoPage"><img src={Shade20} className="img-responsive"  alt="..."/></div>
        </HTMLFlipBook>
        </div>
        <div className="container makeup-bottonarrow">
            <div>
              <button className="left" type="button" onClick={this.prevButtonClick}>
                <span>&#x21d2;</span> 
              </button>
              <button className="right" type="button" onClick={this.nextButtonClick}>
                 <span>&#x21d0;</span>
              </button>
            </div>
           
          </div>
        </div>
      );
   }
}

export default MakeupSlider;