import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class EucalyptusOil extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="BakuchiolBlog-page BakuchiolBlog-page_cont dark-underarms-treatment Ecul">
            <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="robots" content="index, follow" />   
            <meta name="description" content="Eucalyptus Oil Benefits For Hair " />
            <title>Eucalyptus Oil Benefits For Hair </title>  
            </Helmet> 
           <div className="container">
                  <div className="Image_frame_bakuchiol">
               <h1>EUCALYPTUS OIL BENEFITS FOR HAIR </h1>
               </div>  
                  <div className="Image_frame_bakuchiol">
                     <img src={"https://ik.imagekit.io/vert/Blog/blush3_9WUyo6Gim.jpg?updatedAt=1629967457524"} className="img-responsive"  alt="..."/>
                     <p className="text-center">If you’re searching for an essential oil to change your dull hair, you can give eucalyptus oil a shot. This oil is extracted from the leaves of the eucalyptus tree, which is native to Australia, eucalyptus essential oil is obtained by drying, crushing and distilling the leaves. Easily identifiable by its strong, medicinal scent and is known for its curing and therapeutic properties. Helps in relieving joint pain, curing coughs and healing wounds – these are some of well-known medicinal eucalyptus oil uses.  </p>
                   </div>
                  </div> 
                  <div className="container new"> 
                      
                        <div className="bottom_cont_BakuchiolBlogn cen">
                            <h4 className="text-center">BENEFITS FOR HAIR</h4>
                            </div>
                            <div className="bottom_cont_BakuchiolBlog">
                            <ul>
                                <li>Hair elasticity is key factor in hair growth. Hair that isn’t elastic enough tends to spit or break easily and get damaged. Well, eucalyptus oil to the rescue once again, as it’s known to increase hair elasticity. </li>
                               <li>Eucalyptus oil is both a prevention and a cure for dandruff. It has unique antifungal and antiseptic properties that prevent any yeast like fungus from developing and settling on the scalp. Use it with a suitable carrier oil and you won’t have to worry about your dandruff problems anymore. </li>
                               <li>Eucalyptus oil is a rich source of antioxidants. It also has natural anti-inflammatory properties which help protect the hair against intense damage. </li>
                               <li>Exposure to the sun, pollutants and dust can often render our hair dull and in need of hydration and shine. This is where eucalyptus oil comes in! Adding lustre and bounce to the hair is another eucalyptus oil benefits that can totally transform your locks for the better. </li>
                               <li>If your scalp often feels itchy and uncomfortable, then eucalyptus oil is the antidote you need. Its unique ability to calm and soothe the scalp has made it a popular product in the hair care industry. </li>
                               <li>Issues like premature greying can be out to rest simply by including a bottle of eucalyptus oil in your weekly hair care routine. </li>
                               <li>We are well-aware of the kind of menace head lice can be! Which is why it’s beneficial to always have a small bottle of eucalyptus oil handy for emergency lice situations. Eucalyptus oil is a well-known remedy for lice infestations as well. </li>
                               <li>A popular eucalyptus oil benefit is that it reduces stress, and we all know the role stress plays in causing unexpected hair loss. Regular use of eucalyptus oil can not only reduce hair loss but also promote hair growth by nourishing the scalp and strands with nutrients important for healthy hair. </li>
                            </ul>
                            </div>
                           
                          
                        
                      
                  </div>   
            </div> 
        );
    }
}

export default EucalyptusOil;