import React, { Component } from 'react';
import Fungusimg from '../lab/blog/Fungusimg.jpg';
import {Helmet} from "react-helmet";

class FungalAcneReal extends Component {
    componentDidMount() {
		window.scrollTo(0, 0) 
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish skin-exfoliation SEBUM">
            <Helmet>    
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="index, follow" />   
                <meta name="description" content="Is fungal Acne Real??" />
                <title> Is fungal Acne Real??</title>  
            </Helmet>
            
                <div className="Image_frame">
                   <img src={Fungusimg} className="img-responsive"  alt="..."/>
                </div>
        <div className="container">
        <div className="Heading_tu">   
                    <h1>IS FUNGAL ACNE REAL??</h1>
            </div>
                <div className="Image_frame-middle">
                <div className="Heading_tu">   
                    <p>If you’ve taken a second to scroll through beauty sides or any other website, chances are high you’ve encountered someone’s fungal acne routine. They’ll claim those pimples on your face that just won’t go away will clear up with their skincare remedies. You may start believing that you have fungal acne yourself.</p>
                    <p>Studies have shown that fungal acne isn’t a classification of acne at all. It’s not even a real diagnosis. In fact. It’s a made up name for something else entirely different from acne, and it typically affects your body and not your face. So let’s read more and stop focusing on social media trends and remedies. </p>
                </div>  
                <div className="Heading_tu">
                    <h4>WHAT EXACTLY IS FUNGAL ACNE??</h4>
                   <p>It’s an infection of hair follicle scientifically called pityrosporum folliculitis or malassezia folliculitis. No matter what you call it, inflammation and itchy, acne-like eruptions within hair follicles are caused by excess yeast known as malassezia, which is in the same biological classification as fungi. </p>
                   <p>Malassezia actually lives on everyone’s skin. However, yeast levels tend to increase during hot, humid weather or when you’re sweaty. High yeast levels promote inflammation, which in turn manifest on the skin as pus bumps. Unlike true acne, fungal acne can be contagious encounters. Because yeast is involved, the infection has a tendency to spread. Some other signs are intense itching and placements. Inflammatory acne tends to affect the face and is usually either due to increase oil production, follicular plugging, excess bacterial growth of propionibacterium acnes, or hormonal changes. Fungal acne on the other hand, appears frequently as uniform papules and pustules on the chest and back or in the areas of occlusive clothing. </p>
               </div>

                   <div className="Heading_tu">
                    <h4> WAY TO SPOT THEM?? </h4>
                   <p>
                   Fungal acne can be hard to diagnose because they look like a small white head that are about the size of a pinpoint or specifically, one millimeter in circumference. Usually they’ll be on the chest, shoulders or back and will appear in clusters. “Often, it’s after wearing restrictive clothing, like a scuba suit or sweating under clothes and not showering right away. 
                   </p>
                   </div>
                   <div className="Heading_tu">
                    <h4> LET’S FIX THEM!!! </h4>
                   <p>
                      How are they treated? Depending on the severity of the infection, your dermatologist might suggest something as simple as a body wash or they prescribe medication. They’ll most likely advice incorporating a topical sulfur wash into your routine, which is anti-fungal and antibacterial. If the infection doesn’t start clearing up for a month, your dermatologist is likely to prescribe an oral anti-fungal medication. Given the fact that fungal acne is due to yeast overgrowth within the hair follicles, systemic oral medication is usually necessary to reach deep into the follicle. 
                   </p>
                   </div>
                     
        </div>
        </div>
        </div>  
        );
    }
}

export default FungalAcneReal;