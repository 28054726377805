import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Makeup extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="make-top-banner">
                   <img src={"https://ik.imagekit.io/vert/vertlaboratoire/makeup-top-banner_P8qdwLqoa.jpg"} className="img-responsive"  alt="..."/>
                </div>
                <div className="makeup-middle-sect">
                <div className="container">
                    <div className="gobal-beauty">
                        <p><span className="bea-t">G</span> <span className="bea-t-right">lobal beauty industry is valued in billions and is reaching at a never ending stage. Beauty is not defined with products or ingredients, it’s the way you perceive and know the right way of using cosmetics in <b>Green Way</b> .
                        </span></p>
                        <div className="clear"></div>
<p><span className="bea-t remove">G</span> <span className="bea-t-right">Innovative beauty trends and beauty beyond boundaries is growing like never before in India. Quarantine has given us a new way to define makeup with trends such as<b> no makeup-makeup look, five products used for makeup or concealer only.</b> While many of us are comfortable with the idea of no makeup look, and rest are waiting for totally glamorous look. Beauty trends are growing and the 80s inspired look are coming back with <b>coloured mascara, graphic liners and metallic bold eyes</b>. As the eyes are doing the talking sales of mascaras and rise in consumption of eyeliners as consumers are lean into the habit of wearing a mask. 
</span></p>
                    </div>
                    <div className="clear"></div>
                    <div className="moreover_mini">
                        <div className="row">
                        <div className="col-md-6">
                             <div className="moreover_mini_left">
                                 <img src="https://ik.imagekit.io/vert/vertlaboratoire/mak_1_XFAlmLd-y.gif" class="img-responsive" alt="..."/>
                                <p className="Using_natural"><span className="bea-t">U</span> <span className="bea-t-right">sing <b>natural makeup products,</b> as they are high in pigment and the application is seamless, one stroke does the perfect job. Natural ingredients helps in skin repairing and less chances of breakout. Plant derived ingredients are good in anti-aging and boost collagen production. Ingredients one should avoid using in cosmetics are Lanolin, shellac, caseinate, squalene, guanine, oleic- acid, tallow, animal hair, stearic acid, carmine, collagen, elastin, keratin and other harmful chemicals. </span></p>
                             </div>                
                        </div>
                        <div className="col-md-6">
                        <div className="moreover_mini_right">
                        <p><span className="bea-t">U</span> <span className="bea-t-right over">oreover, <b>minimal and dewy makeup</b> is one of the biggest trends, people are shifting towards minimal makeup and <b>highlight the best features,</b> and natural glam is the new word for beauty this year. <b>At-home blush glow, fierce brows, graphic eyes, expensive eyes, expressive eyes, nostalgic makeup, natural foundation, uplifting colour, supporting small brands and all over luminosity are some of the famous trends of 2021.</b> Beauty is beyond skin tone, nowadays we see more brand using darker skin tone models for showcasing there cosmetics. As dark skin tone was a taboo for makeup and buy this brands are launching more variation in their pallets. Gender neutral (beauty is beyond gender) why girls have all the fun.. A booming market for men’s beauty products is increasing the growth of the market with the increased use of cosmetics by LGBTQ group and gender fluidity has created a large audience for cosmetics brand to serve to.</span> </p>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="gi_f_banner_water">
                   <div className="container">
                        <p>At Vert laboratoire, we encourage the idea of each customer by customizing the products for them. Consumers are becoming aware of their choices and have begun to understand what work for them may not work for someone else. This has caused brands to create a new form of luxury in the industry. </p>
                   </div>
                </div>

                
                      <div className="container">
                      <div className="your_skin">
                          <div className="row">
                          <div className="col-md-8">
                              <p><span className="bea-t">Y</span> <span className="bea-t-right deserves">our skin deserves natural ingredients and we as <b>organic makeup products manufacturer</b> believe highly in sourcing ingredients from all over the world. Our top priority is providing clean makeup ingredients, as green manufactures we produce non-toxic, chemical free and organic products which are suited not only for green consumers but also take care of the production process and use of these products leave minimum carbon footprints on the ecological system. As private label manufactures can help you build your label with organic and natural products. A must for your brand portfolio launch.</span> </p>
                              <p><span className="bea-t remove">Y</span> <span className="bea-t-right deserves">We manufacture lipsticks, liquid lipsticks, matte lipstick, lip gloss, lip balm, tinted lip balm,  mascara, eye brow pencil, eyeliner, eye shadow, foundation, tinted moisturizer, lip liner, concealer, color corrector, blush, loose powder, compressed powder, bronzer, highlighter and more interesting products for each one of you.</span> </p>
                          </div>
                          <div className="col-md-4">
                              <div className="img_right">
                                     <img src="https://ik.imagekit.io/vert/vertlaboratoire/skin_uX2K86n5p.gif" class="img-responsive" alt="..."/>
                             </div>
                          </div>
                          </div>
                      </div>
                     
                      <div className="lip_acc">
                      <div className="row">
                      <div className="col-md-4">
                              <div className="img_left">
                                     <img src="https://ik.imagekit.io/vert/vertlaboratoire/lip_ltEY83Ba7.gif" class="img-responsive" alt="..."/>
                             </div>
                          </div>
                          <div className="col-md-8">
                              <p className="lip"><span className="bea-t">L</span> <span className="bea-t-right"><b>ips-</b> Attractive pair of lips are tempting and help in making the whole face look glamour’s. Lips helps to complete the makeup look and gives color to the face. <b>We manufacture a variety of products for lips such as lip balms, lip liners, lipsticks, tinted lip balm, lip gloss, matte lipsticks and gloss lipsticks. </b> </span></p>
                         </div>
                         </div>
                      </div>
                      <div className="lip_acc eye">
                      <div className="row">
                          <div className="col-md-8">
                              <p><span className="bea-t">E</span> <span className="bea-t-right">yes- Eyes enhance the beauty and reflects the personality of a person, good eye makeup makes one look stunning and ravishing.<b> You can create different looks with the help of our products such as mascara, liner, eye shadow and brow pencil. </b> </span></p>
                         </div>
                         <div className="col-md-4">
                              <div className="img_right">
                                     <img src="https://ik.imagekit.io/vert/vertlaboratoire/eye_g86zMeDkH.gif" class="img-responsive" alt="..."/>
                             </div>
                          </div>
                          </div>
                      </div>
                      <div className="lip_acc">
                      <div className="row">
                      <div className="col-md-4">
                              <div className="img_left">
                                     <img src="https://ik.imagekit.io/vert/vertlaboratoire/face_FrxeVsJ7C.gif" class="img-responsive" alt="..."/>
                             </div>
                          </div>
                          <div className="col-md-8">
                              <p><span className="bea-t">F</span><span className="bea-t-right"> <b>ace Makeup- Facial makeup</b> are the products used to highlight and color face features. We manufacture a variety of products for face makeup such as foundation (matte and hydrating), concealer, color corrector, pressed powder, loose powder, bronzer, highlighter and blush.</span> </p>
                         </div>
                      </div>
                      </div>

                </div>

                <div className="rech_now_cont">
                    <h5>REACH US NOW TO LAUNCH YOUR BRAND !!!</h5>
                    <p><Link to="/contact">Contact Us</Link></p>      
                </div>
      
                </div>
            </div>
        );
    }
}

export default Makeup;