import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class FaceOils extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="faceoilBlog-page BakuchiolBlog-page_cont">
                <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="face oils all you need to know" />
	<title> face oils all you need to know </title>  
	</Helmet>
                  <div className="container">
                       
                        <div className="Image_frame_bakuchiol">
                        <h1>FACE OILS: ALL YOU NEED TO KNOW</h1>
                        <img src={"https://ik.imagekit.io/deepak/Laboratoire/facial-oil-blog-a_vm2iUCJFK.jpg?updatedAt=1629868898711"} className="img-responsive"  alt="..."/>
                        
                        </div>
                        </div>
                        <div className="container new">
                        <div className="Image_frame_bakuchiol">
                         <p>Oil on the face, this thoughts throws you off, we get it. Maybe it’s because the idea of putting oil on, say, your acne-prone face seems certifiably insane (it isn’t), or maybe your skin is so sensitive you think you can’t use oil (you can), or maybe you just think the whole concept of face oils is too confusing for your low-maintenance needs (it definitely is not).  </p>
                         <p>Whatever the reason maybe, but face oil is the one product that can actually treat your skin issues, while giving you the bright, glow, clear face of your dreams. And yes, every skin type can use it. Before you have many questions in your head regarding face oils let’s get started. </p>

                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h5>DIFFERENT TYPES OF SKIN TYPES AND FACE OILS SUITED FOR THEM </h5>
                            <h4>OILY SKIN: </h4>
                            <p>It sounds counterintuitive, but the right oil can only act as an astringent to rid your face of excess grease but will also help stabilize and regulate your skin’s sebum production to make you less oily overall. And let’s get the whole texture issue out of the way and because of their different molecular weights, many oils feel more like a serum or essence so you’re not stuck feeling sebum-ridden. </p>
                            <p>Sealing in moisture and using the oil as the oil as the last step, after moisturizing. When you use a moisturizer for oily skin, it’s generally lightweight and oil-free so you want these ingredients to absorb into the skin first to avoid any potential blocking or clogging of the pores. Then, you can add a protective layer of oil over your moisturizer to prevent water loss. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4 className="center_al">JOJOBA OIL:</h4>
                            <p>Not only is jojoba an incredibly lightweight and breathable oil (making it great for combination skin that still needs some moisture), but, because jojoba actually breaks down and dissolves sebum, it’ll also help control your shine. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4>DRY SKIN:</h4>
                            <p>You would think that any face oil would automatically be a cupid-level match for dry skin, like all things in beauty, it’s not that simple. Some oils are naturally more astringent and lightweight than others, so drier types want to use a rich oil that is high in oleic acid- a fatty acid that helps condition skin, lock in moisture and reduce irritation from dryness. </p>
                            <p>Mix 3-5 drops directly in your night moisturizer, and apply to both face and neck. You can also smooth over your moisturizer to provide a protective barrier to prevent dry air from pulling moisture out from your skin. </p>
                        </div> 
                      <div className="bottom_cont_BakuchiolBlog"> 
                            <h4 className="center_al">ALMOND OIL:</h4>
                            <p>It’s intensely hydrating and simple and gentle enough for anyone with eczema, dermatitis or just really dry skin. It’s also incredibly high in vitamin A, giving it some line-smoothing and zit fighting power when used topically over time.  </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog"> 
                            <h4>ACNE PRONE SKIN:</h4>
                            <p>Do you know the key to reducing your breakouts is to slather your face with oil? Too late? – That’s exactly what you have to do. Most acne treatments in the market are filled with irritating ingredients that strips your skin of natural oils. When your skin is dry and inflamed, it overproduces sebum, leading to pimple and blackheads. Acne safe face oils, however, work to nourish your skin, so your body can heal the zits you have without causing more.  </p>
                        </div>
                       <div className="bottom_cont_BakuchiolBlog"> 
                            <h4 className="center_al">POMEGRANATE OIL:</h4>
                            <p>Not only is it naturally antibacterial and anti-inflammatory (so it’ll help fight zit-causing bacteria in your pores while also calming the rest of your inflamed, broken-out skin), but it’s also incredibly lightweight, so it won’t feel like a greasy mask. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog"> 
                            <h4>NORMAL SKIN:</h4>
                            <p>If you don’t really break out, you’re not super dry, definitely not too oil, probably not sensitive, then you are blessed with perfectly normal skin. This means that you can use basic OG face oil without having to worry about clogged pores, irritation or dryness. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog"> 
                            <h4 className="center_al">ARGON OIL: </h4>
                            <p>Argon oil is rich and soothing, it sinks in quickly, and it’s incredibly high in vitamin E an antioxidant that helps prevent skin damage, dark spots and fine lines by blocking environmental damage. Massage it on in the morning or night as the final step of your skincare routine.  </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog"> 
                            <p><b>Face oils are great for your skin as they help in opening up the clogged pores to maintain good blood flow and they have antioxidants that prevents your skin from aging and keeps the skin hydrated and moisturized. </b>  </p>
                        </div>
                   </div>
            </div>
        );
    }
}

export default FaceOils;