import React, { Component } from 'react';

class Howitwork extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="how-it-work_main"> 
                 <div className="top-banner-howit"> <img className="img-responsive" src={"https://ik.imagekit.io/deepak/work-1_C-OZIo6IN8.jpg"}  /> </div>  
                 <div className="top-banner-howit2"> <img className="img-responsive" src={"https://ik.imagekit.io/deepak/work-2_5mfseHpw2.jpg"}  /> </div>
                 <div className="top-banner-howit3"> <img className="img-responsive" src={"https://ik.imagekit.io/deepak/work-3_CiULkkbRDD.jpg"}  /> </div>  
                 <div className="top-banner-howit4"> <img className="img-responsive" src={"https://ik.imagekit.io/deepak/work-4_Y5tGbEDa9.jpg"}  /> </div>       
            </div>
        );
    }
}

export default Howitwork;