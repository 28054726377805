import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Slider from './slider';
import Instagram from './Instagram';
import Categories from './Categories';
import {Helmet} from "react-helmet";

export default class Home extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div>
			<Helmet>
			<meta name="keywords" content=" Organic Cosmetic Products, Beauty Products, Fresh and handmade beauty cosmetic products" />
	<meta name="robots" content="index, follow" />
	<meta name="description" content="Searching for the natural cosmetics private label manufacturers? Choose VertLaboratoire, the ultimate organic beauty product manufacturers you can find in the country. " />
	<title> Vertlaboratoire | Cosmetic Manufacturers India| Third Party Manufacturer|Organic Natural Make up Manufacturers 	 </title> 
	<meta name="twitter:title" content="Buy Organic Natural Cosmetic Products | Handmade Beauty Products Online - verthpc.com"/>

                
            </Helmet>
                <Slider />
				<div className="brandfactory">
				    <div className="container">
					   <div className="brandfactory-middle">
						      <h2>AT BRAND FACTORY</h2>
							  <div className="bordern"></div>
							  <p>We are Young, Careful, Conscious Crafters, who love to create Concepts into finished good and great brands eventually.</p>
							  <p>We are Green Boutique Manufacturing Unit .We carefully understand concepts/ideas, challenges, vision and mission for your brand. With us possibilities are infinite ,we are here to foster The Brands in making.
we completely cherish delivering our client's wonderful products across skin care, hair care and makeup.</p>
                        <div className="img-all-frame">
						    <img src={"https://ik.imagekit.io/deepak/brand-1_FV6PR-Tqu.jpg"} className="img-responsive" alt="..."/>
						    <img src={"https://ik.imagekit.io/deepak/brand-2_DSoBT5JRW.jpg"} className="img-responsive" alt="..."/>   
						</div>
						</div> 
					</div>
	            </div>

				<div className="ethcilmain_frame">
				   <div className="container">
				     <div className="ethcilmain_frame_middle">
                          <h2>Ethical honest and Green </h2>
						  <div className="bordern"></div>
						  <p>Innovating  Green concepts with truly marvelling features</p>
					  </div>
					  <div className="ethcilmain_frame_middle_cont">
						  <div className="row">
							  <div className="col-md-6">
								     <div className="ethi-left_frame">
										      <ul>
												  <li>We started crafting products Since 2011. For over a decade now we have been making award winning products /Brands through advocacy of green chemistry.</li>
												  <li>Our creations do not pollute Environment, as we are a zero waste manufacturing unit. We do not harm animals in  making Beautiful products.       </li>
												  <li>We take stride and pride in propelling the green foot print.</li>
												  <li>The upsurge of trends in Indian Beauty Industry like Parabens free, Handmade, without harmful chemicals  , No Plastic beads ,No animal Fat, Against animal cruelty... is highly contributed our our lovely Green work over 10 years. </li>
											  </ul>
											  <img src={"https://ik.imagekit.io/deepak/eth-2_V2wTM-E54q.jpg"} className="img-responsive" alt="..."/>
									 </div>
							  </div>
							  <div className="col-md-6">
							  <div className="ethi-right_frame">
							     <img src={"https://ik.imagekit.io/deepak/eth-1_giaaTsPbjm.jpg"} className="img-responsive" alt="..."/>
							     <img src={"https://ik.imagekit.io/deepak/eth-3_h3c6SGA6GC.jpg"} className="img-responsive" alt="..."/> 
							  </div>
                                 
							  </div>
						  </div>
					  </div>
					</div>
				</div>
				<div className="making_frame">
				   <div className="container">
					      <p>Making green products does not mean that you make boring products or compromise on efficacy , we have <br/>mastered the science and art of creating unique decoction which are efficacious from the very use of it.</p>
						  <p>We are making products for a wide gamut of Brands complying to<br/>
<span>Kosher, European Union, Cosmos, US FDA.</span></p>
				   </div>                   
                </div>
				<div className="makingimg_frame">
				   <div className="container">
					   <div className="row">
							<div className="col-md-6">
							   <img src={"https://ik.imagekit.io/deepak/eth-4_z9i2HSvDKQ.jpg"} className="img-responsive" alt="..."/>
							</div>
							<div className="col-md-6">
							   <img src={"https://ik.imagekit.io/deepak/eth-5_YCPlrxeP-B.jpg"} className="img-responsive" alt="..."/>
							</div>
							<div className="col-md-12">
                                 <p>We have a experienced team from FMCG industry who can help you cater all yours Brand making needs from<br/> the ideation stage </p>
							</div>
							</div>
				   </div>
                </div>
				<div className="canhelp_frame">
				   <div className="container--">
					   <div className="canhelp_frame_all">
					    <p>We can help you develop not just great products in Skin care, hair care, Makeup,But can also help you with<br/> packaging needs,  Branding, logo design,label and Box designing, website, marketing so on & so forth...</p> 
						<p>We just don't artfully design but also execute the same seamlessly.</p>
						<p>So yes we get your labels + Boxes printed, make your webpage workable and wide integrations, plugins and<br/> SEO compatibility.</p>
						<p>In nut shell we handover a <span>complete ready to sell proposition</span> to you.</p>
						<p>So that you just focus on sales & marketing and you just leave rest on us!</p>
						</div>
						<img src={"https://ik.imagekit.io/deepak/eth-6_XI4-GwGXzU.jpg"} className="img-responsive" alt="..."/>
				</div>
				   </div>

				   <div className="since_frame">
				   <div className="container">
					    <div className="top-cont_frame">
							<p>Since 2011 we have enjoyed working with a diverse pool of clients.</p>
							<p>They are from different walks of life like Hotels, spas,  Fashion Brands, Retailers, e-commerce, startups, Makeup artists, Designers, Baby Care companies speciality mens Brands.....and we love them all.</p>
							<p>
We are a warm Boutique Brand factory which is always buzzing with exciting discussion, debates of upcoming products/Brands over a cup of coffee. ?</p>
<p>Come Join the green side !</p>
<ul>
	<li>
		<div className="pop-hover">
			<h3><Link to="/Provenance">Provenance</Link></h3>
		</div>
	<img src={"https://ik.imagekit.io/deepak/brand-3_Ul1rQfx2x.jpg"} className="img-responsive" alt="..."/>
	</li>
	<li>
	<div className="pop-hover">
			<h3><Link to="/the-brand">THE BRAND</Link></h3>
		</div>
	<img src={"https://ik.imagekit.io/deepak/brand-2_QWfgC-OAe.jpg"} className="img-responsive" alt="..."/>
	</li>
	<li>
	<div className="pop-hover">
			<h3><Link to="/how-it-work">HOW IT WORKS</Link></h3>
		</div>
	<img src={"https://ik.imagekit.io/deepak/brand-1_eoEN_0ZyANP.jpg"} className="img-responsive" alt="..."/>
	</li>
</ul>
						</div>
				   </div>
				   </div>
				   <div className="clear"></div>
				   <Categories/>
				   <div className="reach-frame_frame">
				   <div className="reach_top_cont">
					   <h2>RESEARCH & DEVELOPMENT</h2> 
					   <div className="bordern green"></div> 
						 <p>We have state of art Research & Development laboratory to formulate any concept into finished product.<br/>

We are very competent in Designing Custom formulations.</p>
<img src={"https://ik.imagekit.io/deepak/middle_big_M4hja9k9c.png"} className="img-responsive" alt="..."/>
					   </div>
				   <div className="container">
					  
					   <div className="throuth_frame">
						   <div className="row">
							   <div className="col-md-7">
								   <p>Through we have laboratory facility we choose to stay away from<br/> tests which involve animal cruelty - like we don't conduct or promote <br/>Live animal dermatology testing ,hypoallergenic and other tests<br/> which involve use of body parts and organs from animals slaughtered<br/> for meat industry.</p>
							   </div>
							   <div className="col-md-4 col-n">
							   <img src={"https://ik.imagekit.io/deepak/free_wdPaUB835l.jpg"} className="img-responsive" alt="..."/>
							   </div>
						   </div>
						   <div className="row">
							   
							   <div className="col-md-5">
							   <img src={"https://ik.imagekit.io/deepak/free-2_1VfnDQy_rF.png"} className="img-responsive" alt="..."/>
							   </div>
							   <div className="col-md-7 new">
							
								   <p>We don't want to contributes towards the countless suffering of animals in laboratories for testing beauty products.</p>
								   <p>Hence at Vert Laboratoire our Objective is to achieve Balance & Harmony.... With nature , animals & Humans</p>
								   <p>We strive to make world beautiful without harming anyone in the entire  value chain.</p>
								   <p>So that it is Sustainable and we keep changing lives positively </p>
								   <h3>Join The Green Side </h3>
							   </div>
						   </div>
					   </div>
					    
					</div>
					</div>	

					<div className="lunch-now-frame">
					<div className="container">
							<div className="top_lunch_now">
								<h4>LAUNCH NOW</h4>
								<p>Create your own Branded Beauty Products -Our team will help as much needed to conceptualise , Create , Design , <br/>Capture , Amplify your Incredible products to A Brand.</p>
							</div>
							<div className="lunch_now_buttomframe">
                                  <div className="row">
									  <div className="col-md-4">
										  <div className="speed_frame">
										  <div className="iamg_frame_all new">
										     <img src={"https://ik.imagekit.io/deepak/lunch-1_cJoZQ2mo2g.png"} className="img-responsive" alt="..."/>
										     </div>
											 <h5>Speed to Market</h5>
											 <p>Launched beauty brands from<br/> concept stage to Finished goods in<br/> apprx 12 weeks straight.</p>
										  </div>
									  </div>
									  <div className="col-md-4">
										  <div className="speed_frame">
										  <div className="iamg_frame_all">
										     <img src={"https://ik.imagekit.io/deepak/lunch-2_kFRArxkGFT.png"} className="img-responsive" alt="..."/>
										    </div>
											 <h5>Sustainable </h5>
											 <p>Harmony is what we pursue. We don't<br/> test on animals and make vegan<br/> products</p>
										  </div>
									  </div>
									  <div className="col-md-4">
										  <div className="speed_frame"> 
											  <div className="iamg_frame_all new">
											      <img src={"https://ik.imagekit.io/deepak/lunch-3_BmNox5tQHe.png"} className="img-responsive" alt="..."/>
											  </div>
										    
										     <h5>Genesis </h5>
											 <p>We use plant/Botanicals sourced<br/> ingredients like butters , oils , flowers ,<br/> barks , leaves , spices ...... to create<br/> efficacious products  </p>
										  </div>
									  </div>
								  </div>
							</div>
				    </div>

					</div>



					<div className="logo-c">
       
	   <img src={"https://ik.imagekit.io/deepak/logo-c_hRMHgPUuah.jpg"} className="img-responsive" alt="..."/>
	</div>              
<div className="container--">
<Instagram/> 
</div>
            </div>
        )
    }
}
