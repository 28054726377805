import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee"; 
import ManuTop from '../lab/ManuTop.mp4';
import ManuBottom from '../lab/ManuBottom.mp4';
import ManufacturingN1 from '../lab/ManufacturingN1.mp4';
import ManufacturingN2 from '../lab/ManufacturingN2.mp4';
import Videoss from '../lab/Videoss.jpg';
import manufacturing1 from '../lab/manufacturing1.jpg';
import manufacturing2 from '../lab/manufacturing2.jpg';
import manufacturing3 from '../lab/manufacturing3.jpg';
import manufacturing4 from '../lab/manufacturing4.jpg';
import manufacturing5 from '../lab/manufacturing5.jpg';
import manufacturing6 from '../lab/manufacturing6.jpg';
import manufacturing7 from '../lab/manufacturing7.jpg';
import manufacturing8 from '../lab/manufacturing8.jpg';
import RawMaterial from '../lab/RawMaterial.png';
import MANUFACTURINGBanner from '../lab/MANUFACTURI-GBanner.jpg'; 
import Fruits from '../lab/Fruits.jpg';
import ManuB from '../img/ManuB.jpg';

   
class Manufacturing extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="privel-label-newpage manufacturing_main_page">
                  <div className="top-video_privel ma_veo_Desktop">
                  <video autoPlay loop muted poster={MANUFACTURINGBanner}>
                        <source src={ManuTop} type="video/mp4"/>
                        <source src={ManuTop} type="video/ogg"/>       
                    </video>
                </div>
                <div className="top-video_privel ma_veo_mob">
                  <video autoPlay loop muted poster={MANUFACTURINGBanner}>
                        <source src={ManufacturingN1} type="video/mp4"/>
                        <source src={ManufacturingN1} type="video/ogg"/>       
                    </video>
                </div>
                <div className="privel-label-newpage manufacturing_main_page-middle">
                <div className="tube_light_middle_section">
                <h1><span>M</span>ANU<span>F</span>ACTURING</h1>  
                <img src={Fruits} className="img-responsiveright-tue"  alt="..."/>
                <div className="container">
                <div className="tube_light_middle_section_top">
                    <p><span className="First_let_n">V</span>ert Laboratoire is a private label manufacturing company over a decade now. We have been dedicated <span className="highlight">GMP – Good Manufacturing Practice</span> with Innovative and <span className="highlight">High Quality</span> concoction. We only practiced Green and Sustainable way of manufacturing. With our Eco – Friendly Factory, as we do not pollute the environment, we have Zero Waste Material. </p>
                   <p>We believe in propelling the <span className="highlight">Green Foot Print</span> by using Renewable Energy (solar and wind) for our manufacturing. We favor the use of artisan methods of production which enables us to maintain the quality of our <span className="highlight">Raw Material.</span> </p>
                </div>
                <div className="clear"></div>
                <div className="tube_light_middle_section_bottom">
                     <p><span className="First_let_n">W</span>e source our ingredients ethically and locally. We take pride in getting the best and most potent Plant Based Raw Material from our local products. Our manufacturing process do not include Animal Testing, Harmful Chemicals and <span className="highlight">Reef Safe Products.</span> As we our <span className="highlight">Green Factory</span> with <span className="highlight">Zero Waste Manufacturing Unit</span> and thriving <span className="highlight">Zero Carbon Foot Print</span> over the years. We are certified manufacturing company specializing in plant based and organic formulations. We do not compromise in quality of the product over quantity. </p>    
                     <img src={RawMaterial} className="img-responsive--"  alt="..."/>
                </div>
                </div>
                </div>   

                <div className="container our_range_section_middle">
                    <div className="our_range_section">
                    <Marquee pauseOnHover={"false"} gradientColor={255, 255, 255} speed={90}> 
                      <div>
                          <h3>OUR RANGE INCLUDES</h3>
                      </div>
                      <div>
                          <h3>OUR RANGE INCLUDES</h3>
                      </div>
                      <div>
                          <h3>OUR RANGE INCLUDES</h3>
                      </div>
                      
                    </Marquee>
                    </div>
                    </div>
                    <div className="our_range_sectio_img_frame">
                    <div className="container">
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                     <div className="our_range_sectio_img_frame-top">
                     <a href="/facecare">
                             <img src={manufacturing1} className="img-responsive--"  alt="..."/>
                    </a>
                    <a href="/Bodycare">
                        <img src={manufacturing2} className="img-responsive--"  alt="..."/>
                    </a>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                    
                        <div className="our_range_sectio_img_frame-bottom">
                               <div className="our_range_sectio_img_frame-bottom-left">
                              <a href="/Mencare">
                                   <img src={manufacturing3} className="img-responsive--"  alt="..."/>
                               </a>   
                               <a href="/hotel-and-spa-amenities">   
                                  <img src={manufacturing6} className="img-responsive--"  alt="..."/>
                               </a>
                               </div>
                               <div className="our_range_sectio_img_frame-bottom-middle">
                               <a href="/Haircare"> 
                                  <img src={manufacturing4} className="img-responsive--"  alt="..."/>
                               </a>
                               <a to="#"> 
                               <img src={manufacturing7} className="img-responsive--"  alt="..."/>
                               </a>
                               </div>
                               <div className="our_range_sectio_img_frame-bottom-right">
                               <a href="/makeup"> 
                                  <img src={manufacturing5} className="img-responsive--"  alt="..."/>
                               </a>
                               <a  href="/babycare">          
                                   <img src={manufacturing8} className="img-responsive--"  alt="..."/>
                               </a>
                               </div>
                        </div>
                        </ScrollAnimation>
                        <div className="clear"></div>
                    </div>
                    </div>
                    <div className="container">
                     <div className="our-work-over-now">
                           <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-10">
                                    <div className="our-work-over-now">
                                         <p>Our work over the years have only been around good manufacturing practice and we will continue doing the same. Our aim is to make the world worth living by ensuring least amount of waste and harm done to the environment. Our Green Factory is fully sustainable beauty manufacturing unit for you to <span className="highlight">START YOUR OWN BRAND</span></p>
                                    </div>            
                                </div>
                           </div>
                     </div>
                </div>
                <div className="bottom-video_privel deskto_manu_bot">
                    <a href="/contact">
                  <video autoPlay loop muted>
                        <source src={ManuBottom} type="video/mp4"/>
                        <source src={ManuBottom} type="video/ogg"/>       
                    </video>
                    </a>
                </div>
                <div className="bottom-video_privel mobile_manu_bot">
                    <a href="/contact">
                  {/** <video autoPlay loop muted>
                        <source src={ManufacturingN2} type="video/mp4"/>
                        <source src={ManufacturingN2} type="video/ogg"/>        
                    </video>*/}
                    <img src={ManuB} className="img-responsive"  alt="..."/>
                    </a>            
                </div>
               
            </div>
            </div>
        );
    }
}

export default Manufacturing;