import React, { useEffect, useRef,useState,playing,setPlaying } from "react";

export default function Demo() {
const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleVideoPress = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };
  

  return (
    <div id="App">
      <br />
      <br />
      <br />
      <br />
      <video
        onClick={handleVideoPress}
        className="video__player"
        loop
        ref={videoRef}
        src={"https://www.w3schools.com/tags/movie.mp4"}
      ></video>
     
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
    </div>
  );
}