import React, { Component } from "react";
import {Helmet} from "react-helmet";


export default class CustomFormulation extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
  render() {
    return <div>
		<Helmet>
			<meta charset="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta name="robots" content="index, follow" />   
			<meta name="description" content="Searching for the best Custom natural cosmetics manufacturers? Our world-class cosmetic chemists are experts at developing custom formulations." />
			<title>Custom Formulation | Cosmetics Contract Manufacturer India |Private label Skin Care manufacturer</title> 
       </Helmet>
       <section id="banner-frame">
    <img src={"https://ik.imagekit.io/deepak/Custom-Formulation-Banner_qcNEYBm83o.jpg"} className="img-responsive"/>
  </section>
  <section className="all-things-green--you">
	<div className="container">
		<div className="all-things-green--you-top">
		      <h3>All things green &amp; you</h3>
			  <p>Simply fill out our Product Request form<br/> to provide us your concept idea.</p>
		</div>
		<div className="all-things-green--you-bottom">
			 <h3>Custom formulate <br/> your unique cosmetic combination </h3>
			 <p>with our in-house R&amp;D and technical team who craft<br/>
eco-enabled/conscious contemporary creations consisting of all<br/>
 natural and organic ingredients to pamper you with clean beauty <br/>
 cosmetics derived from green chemistry, ensuring  you  lab<br/>
 tested products, innovated with the very best in technology.</p>
		</div>
		
	</div>
	<div className="vott-po">
	<img src={"https://ik.imagekit.io/deepak/thing_9tBI06jO03.jpg"} className="responsive"/>
	</div>
</section>
<section className="not-all-product-all">
   <div className="container">
		<div className="not-all-product-all-top">
			<h3>Not ALL PRODUCT WITH FIT ALL REQUIREMENTS.<br/> WE ARE HERE TO HELP YOU CREATE</h3>
			<p>Take your beauty care project from inception to success. Let our <br/>
			highly innovative and efficient product development team work <br/>
			with you to develop an exclusive line of products that reflect<br/>
			your highest standards of quality.</p>
		</div>
		<div className="not-all-product-all-bottom">
			<div className="row">
			   <div className="col-md-6">
					<div className="not-all-product-all-left">
						<h3>LABORATORY TESTING </h3>
						<p>With our Laboratory Safety,<br/>
Stability and radical testing<br/>
experience and expertise, we <br/>
engage in a 21st century clean+<br/>
 green factory process to ensure<br/>
 the required testing of your<br/>
 custom product  is chemically<br/>
 trusted, safe and suitable for<br/>
application. Vert products are<br/>
eco-friendly and sustainable as<br/>
 the production process is all<br/>
green &amp; soot-free to ensure<br/>
 compatibility with the<br/>
 environment..

</p>
					</div>
			   </div>
			   <div className="col-md-6">
			      <div className="not-all-product-all-right">
						<h3>Manufacturing</h3>           
<p>
Vert offers cosmetic contract<br/>
manufacturing services<br/>
worldwide. Chemists, product<br/>
development specialists, and<br/>
packaging engineers work<br/>
one-on-one with each client to<br/>
 create products
 </p>   
<p>We are extensively experienced <br/>
and equally recognized for our<br/>
 green reputation as an internatio-<br/>nal eco-conscious manufacturer<br/> for bulk natural cosmetic supplies<br/> of a vast variety of beauty<br/>
products.  </p> 
					</div>
			   
			   </div>
			</div>
		</div>
   </div>
   <div className="left-img-po">
		<img src={"https://ik.imagekit.io/deepak/bott-left_vkVZ3r0IG0.png"} className="left-img-po"/>		
   </div>
   <div className="right-img-po">	
		<img src={"https://ik.imagekit.io/deepak/bott-left2_xmP_3bCLo.png"} className="right-img-po-2"/>
   </div>
</section>
    </div>;
  }
}
