import React, { Component } from 'react';
import TweaksBanner from '../lab/blog/TweaksBanner.jpg';
import {Helmet} from "react-helmet";



class Tweaks extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {      
        return (
            <div className="TheDeadliestIngredientSqualane Tweaks">
                   <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="These Small TWEAKS Can Help Save the Environment" />
	<title> These Small TWEAKS Can Help Save the Environment</title>  
	</Helmet>
                  
            <div className="container">
            <div className="Heading_tu">
                        <h1>THESE SMALL <span>TWEAKS</span> CAN HELP SAVE THE ENVIRONMENT  </h1>
                </div>
            <div className="Image_frame">
                       <img src={TweaksBanner} className="img-responsive"  alt="..."/>
               </div>
                    <div className="Image_frame-middle">
                    <div className="Heading_tu">
                       
                        <p>The beauty industry is a major contributor to worldwide pollution. Luckily, you don’t have to choose between being a fan of beauty products and a friend of the planet. You don’t even have to radically overhaul your routine to make a difference! Making tiny, environmentally-friendly changes can really add up over time.  </p>
                    </div>
                    <div className="Heading_tu">
                        <ul>
                            <li>Cut Out Aerosols </li>
                        </ul>
                        <p><b>The Issue:</b> Canned aerosol products like dry shampoo and hairspray need compressed gases and hydrocarbons to work. Unfortunately, aerosols contain volatile organic compounds (VOCs), which contribute to ground-level smog. Not only is smog gross, but research indicates that it may also increase the risk of asthma. </p>
                        <p>Additionally, some research suggests that aerosols may affect weather patterns, since they contribute to more polluted clouds. </p>
                        <p><b>The tweak:</b> Do the environment (and your lungs) a favor by switching to a green alternative of your favorite aerosol beauty products. Try a powder dry shampoo, or products in a pump-fueled container. </p>
                    </div>
                    <div className="Heading_tu">
                        <ul>
                            <li>Avoid Exfoliating Micro beads </li>
                        </ul>
                        <p><b>The issue:</b> Many exfoliating scrubs and washes utilize polyethylene micro beads as a scrubbing agent. A facial exfoliant can contain 350,000 micro beads in just one package. While they work to clear away dead skin, these tiny plastics pieces are also polluting our lakes and oceans. Micro beads end up in the water supply, where fish eat them. Since other animals eat fish, the micro beads can work their way up the food chain, causing digestive h arm along the way.   </p>
                        <p><b>The tweak:</b> Choose an exfoliant that uses a natural exfoliating agent like sugar or salt. Avoid products with ingredients like micro beads, micro abrasives, polypropylene or polyethylene. </p>
                        </div>
                        <div className="Heading_tu">
                        <ul>
                            <li>Shun Synthetic Fragrances  </li>
                        </ul>
                       <p><b>The issue:</b> You probably already know that synthetic fragrances are sensitizing and can cause skin irritation, but did you know that they’re also harmful to the marine environment? </p>
                       <p>Since wastewater treatment plants don’t remove them, synthetic fragrances end up in our rivers, oceans and lakes. According to National Geographic, synthetic fragrances can decrease an organism’s natural ability to protect itself from toxins. Thus, synthetic fragrances make the effects of water pollution even worse for marine life. </p>
                       <p><b>The tweak:</b> Shop for products that say “no synthetic fragrance” on their labels, and avoid products with “fragrance” listed as an ingredient. </p>
                       </div>
                       <div className="Heading_tu">
                        <ul>
                            <li> Switch to Oxybenzone-Free Sunscreen  </li>
                        </ul>
                      <p><b>The issue:</b> Oxybenzone is an active ingredient in many sunscreen. But according to some study, oxybenzone may actually be killing coral reefs around the world. The results are particularly devastating in touristy areas like Hawaii and the Caribbean. Oxybenzone also damages the DNA of the plants and animals that live in the coral reefs, stunting their growth. </p>
                      <p><b>The tweak:</b> Of course you should never skip wearing sunscreen, but you should switch to a sunscreen that uses minerals like zinc oxide and titanium oxide as active ingredients. </p>
                      </div>
                      <div className="Heading_tu">
                        <ul>
                            <li>Cut Down on Plastic Use  </li>
                        </ul>
                     <p><b>The issue:</b> The beauty industry created 120.8 billion units of packaging in 2008, 40% of which was rigid hard plastic. Unfortunately, hard plastic never fully degrades. Once it’s in a landfill, it’s there forever. </p>
                     <p><b>The tweak:</b> One fix is to buy larger containers of your favorite products. For example, if you always buy the same lotion, buy it in the biggest size the manufacturer makes rather than multiple smaller sizes. One large container contains less plastic than several smaller containers. </p>
                     <p>Many environmentally-friendly brands also offer refills of their products. Simply bring your original container back to the store to restock. </p>
                      </div>
            </div>
            </div>
            </div>
        );
    }
}

export default Tweaks;