import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';


class Custom2 extends Component {
    render() {
        return (
            <div className="custom-2ndmainpage">    
                 <div className="make-top-banner">
                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-1_iCa0oe2fj2.jpg?updatedAt=1629223838611"} className="img-responsive"  alt="..."/>
                </div>
                <div className="container">
                    <div className="custom-2-heading_main_page">
                    <AnimationOnScroll animateIn="animate__bounceIn">
                        <h2>COSMETIC PRODUCT MANUFACTURING</h2>
                    </AnimationOnScroll>
                        <div className="CustomFor_cutat2">
                        <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInDown">
                           <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-21_pbvqACNsm.jpg?updatedAt=1629272727581"} className="img-responsive"  alt="..."/>
                        </ScrollAnimation> 
                           <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                         </div>

                    </div>
                    <div className="bespoke_main_row">
                    <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>WHAT WE FORMULATE</h3> 
                             </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle new">
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInLeft">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-3">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-8_5d8hH6xe_.jpg?updatedAt=1629264737938"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                                 </div>
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>GREEN BEAUTY COSMETIC PRODUCTION</h3> 
                            </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle new2">
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInLeft">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-3">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-9_aXQOkeSbFl.jpg?updatedAt=1629264738042"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation>    
                                 </div>
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>COSMETIC MANUFACTURING CAPABILITIES</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle new3">
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInLeft">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-3">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                   <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-10_C1IePuRjq.jpg?updatedAt=1629264737936"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                                 </div>
                            </div>          
                        </div>
                        
                       <div className="row product_fl_main">
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                
                                    
                                
                                <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInLeft">
                                <h4>PRODUCT FILLING</h4> 
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-26_TQrux3__3.jpg?updatedAt=1629278697483"} className="img-responsive"  alt="..."/>
                                 </ScrollAnimation> 
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                         <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                <AnimationOnScroll animateIn="animate__bounceIn">
                                    <h4>PACKAGING</h4>
                                </AnimationOnScroll> 
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-25_p4IVP87EA.jpg?updatedAt=1629278696613"} className="img-responsive"  alt="..."/>
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                    <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                <AnimationOnScroll animateIn="animate__bounceIn">
                                    <h4>QUALITY CONTROL</h4> 
                                    </AnimationOnScroll>
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-24_sVpV9WrEx.jpg?updatedAt=1629278695733"} className="img-responsive"  alt="..."/>
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                    <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product_fl_main_middle">
                                <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                    <h4>RAW MATERIAL</h4> 
                                     <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-23_gAvT0flcB.jpg?updatedAt=1629278695252"} className="img-responsive"  alt="..."/>
                                    </ScrollAnimation> 
                                    <AnimationOnScroll animateIn="animate__bounceIn">
                                    <p> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </AnimationOnScroll>
                                </div>
                            </div>
                       </div>
                       <div className="pro-mu_all_bottom">
                       <AnimationOnScroll animateIn="animate__bounceIn">
                          <h2>COSMETIC PRODUCT MANUFACTURING</h2>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                       </AnimationOnScroll>
                       </div>




                </div>
            </div>
        );
    }
}

export default Custom2;