import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';

class PrivateLabel extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="Private_label">
                <div className="Pri-top-banner"> 
                   <img src={"https://ik.imagekit.io/deepak/pri-1_MhR9-sf2aTr.jpg?updatedAt=1628920558882"} className="img-responsive"  alt="..."/>
                </div>
                <div className="container">
                     <div className="pri-to-section">
                     <AnimationOnScroll animateIn="animate__bounceIn">
                         <p>INNOVATIVE BEAUTY CARE PRODUCTS FORMULATED TO THE HIGHEST STANDARDS,<br/> PACKAGED WITH YOUR BRAND AND LABEL, AS PER YOUR PREFERENCE. </p>
                        <h2> <span className="pi">P</span>RIVATE <span className="label">LABEL</span> </h2>
                     </AnimationOnScroll>  
                     </div>

                     <div className="Middle_frame_pri">
                       <div className="row">
                         <div className="col-md-1"></div>
                            <div className="col-md-5">
                            <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                 <div className="right_frame_c_pri">
                                 
                                    <p>
                                        Lorem Ipsum is simply dummy
                                        text of the printing and typesetting
                                        industry. Lorem Ipsum has been the
                                        industry's standard dummy text eveR
                                        since the 1500s,
                                    </p>
                                   
                                 </div>
                                 </ScrollAnimation>
                             </div>
                             <div className="col-md-5">
                             <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                                  <div className="left_img_pri">
                                      <img src={"https://ik.imagekit.io/deepak/pri-2_ENW6LUg90.jpg?updatedAt=1628920559034"} className="img-responsive"  alt="..."/>
                                  </div>
                                </ScrollAnimation>
                             </div> 
                             <div className="col-md-1"></div>
                         </div>
                         <div className="row">
                         <div className="col-md-1"></div>
                           
                             <div className="col-md-5">
                             <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                  <div className="left_img_pri">
                                      <img src={"https://ik.imagekit.io/deepak/pri-3_YDsJNZDvu.jpg?updatedAt=1628920558250"} className="img-responsive"  alt="..."/>
                                  </div>
                             </ScrollAnimation>  
                             </div> 
                             <div className="col-md-5">
                             <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                                 <div className="right_frame_c_pri">
                                 <p>
                                        Lorem Ipsum is simply dummy
                                        text of the printing and typesetting
                                        industry. Lorem Ipsum has been the
                                        industry's standard dummy text eveR
                                        since the 1500s,
                                    </p>
                                 </div>
                             </ScrollAnimation> 
                             </div>
                             <div className="col-md-1"></div>
                         </div>
                     </div>

                </div>

                <div className="bottom_framepri_frame">
                <AnimationOnScroll animateIn="animate__bounceIn">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </AnimationOnScroll> 
                    </div>
                 
                 
            </div>
        );
    }
}

export default PrivateLabel;