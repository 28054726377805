import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class ChmicalBlog extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="chmicalBlog-page BakuchiolBlog-page_cont">
                <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="chemical ingredients to avoid in daily use products" />
	<title>chemical ingredients to avoid in daily use products </title>  
	</Helmet>
                  <div className="container">
                       
                        <div className="Image_frame_bakuchiol">
                        <h1>CHEMICAL INGREDIENTS <span>TO AVOID IN DAILY USE PRODUCTS</span></h1>
                        <img src={"https://ik.imagekit.io/deepak/Laboratoire/chmical-blog_rM5Ib9u1Kx.jpg?updatedAt=1629868878628"} className="img-responsive"  alt="..."/>
                        
                        </div>
                      
                        </div>
                        <div className="container new">
                        <div className="Image_frame_bakuchiol">
                        <p>Every one love to take care of their skin and days remain incomplete without them. Even for those who do not particularly use cosmetics do use soap, shampoo and moisturizer. That’s a basic personal care and hygiene. Do you know many of these products are harmful, because of certain chemicals they contain? So, before you blindly pick your daily skincare products and cosmetics, make sure you choose the right one. </p>

                        </div>
                       <div className="bottom_cont_BakuchiolBlog">
                            <h5>Here’s a look at products with certain ingredients to avoid:</h5>
                            <h4>•INTIMATE WASH WITH ARTIFICIAL FRAGRANCES</h4>
                            <p>Who doesn’t want her intimate parts to smell like flowers or fruits?? Well, no one should. Firstly, these fragrances can disrupt pH level of the vagina and turn more alkaline, which leads to infection. Since, manufacturers are not required to reveal the exact fragrant contents of the product, there is no surety if these are allergens, endocrine disruptors, irritants or even carcinogens. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4>•SOAPS AND SHAMPOOS WITH PARABENS</h4>
                            <p>Most of the shampoos and soaps have parabens in them. These are preservatives that ward off the growth of bacteria in water – based products. If, your soap or shampoo has the word paraben in the list of ingredients, it’s time you stop using it. They disrupt hormonal balance in the body and lead to breast cancer. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4>•NAIL PAINT WITH FORMALDEHYDE, DIBUTYL PHTHALATE AND TOLUENE</h4>
                            <p> The three chemicals, dibutyl phthalate, formaldehyde (FRP), and toluene are together known as the toxic trio. They are mainly found in nail enamels, and can be extremely harmful. These three have been liked with a number of diseases and conditions, likeheadache, birth defects, hormonal problems, breast cancer and more. </p>
                        </div>
                       <div className="bottom_cont_BakuchiolBlog"> 
                            <h4>•LOTIONS AND CREAMS WITH POLYETHYLENE GLYCOL</h4>
                            <p>PEG is the thickening agent in most body lotions and creams. It is also a skin softeners in the form of micro beads. However, while trying to keep your skin soft, you maybe unknowingly inviting cancer to your body. Polyethylene glycol or PEG maybe contaminated with both ethylene oxide and 1-4 dioxane. These are possible carcinogens and can also cause respiratory problems. The beads also pollute the environment. </p>
                        </div>
                        <div className="bottom_cont_BakuchiolBlog"> 
                            <h4>•SUNSCREEN WITH OXYBENZONE</h4>
                            <p>There are two types of sunscreen – with chemical filters and minerals. Chemical filters oxybenzone are present in most of the sunscreen commonly available in the market. It is extremely harmful component, known to alter the thyroid functions. It is also a skin irritant and endocrine disruptors.</p>
                        </div>
                       <div className="bottom_cont_BakuchiolBlog"> 
                           
                            <p><b>Granted, the chemical free products might be expensive, and the process of finding the right products is little time consuming. But, it is always a good idea to invest some time and money in your health. </b></p>
                        </div>
                       
                        </div>
            </div>
        );
    }
}

export default ChmicalBlog;