import React, { Component } from 'react';
import facewash1 from '../lab/facewash1.jpg';
import facewash4 from '../lab/facewash4.jpg';
import facewash5 from '../lab/facewash5.jpg';
import facewash6 from '../lab/facewash6.jpg';
import facewash7 from '../lab/facewash7.jpg';
import facewash8 from '../lab/facewash8.jpg';

class FaceWash extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="facecare_main-page facewashmainpage">
                 <div className="facecarebanner">
                     <img src={facewash1} className="img-responsive"  alt="..."/>
                  </div>
                 
                  <div className="mask-market-frame-new face-wash-new">
                       <div className="mask-market-frame-inner-new">
                           <div className="container">
                               <div className="row"> 
                                   <div className="col-md-5">
                                      <img src={facewash7} className="img-responsive"  alt="..."/>
                                   </div>
                                   <div className="col-md-7">
                                          <div className="mask-market-frame-inner-new-right">
                                               <h4>FACE WASH</h4>
                                               <p>In the earlier times water was used for cleansing. But since not all environmental impurities are water soluble, this method proved to be less efficient. Later, different types of soaps came into existence like <span className="high">coarse soap</span> – made with wood ash, water and train oil, <span className="high">sweet soap</span> - that include olive oil, water and wood as hand <span className="high">speckled soap</span> – combination of wood ash, tallow and water. With the changing time and demand <span className="high">face wash</span> came into existence that included fatty acids, polypeptide and antioxidants and so on. As these elements left the face soft and hydrated skin. </p>
                                          </div>
                                   </div>
                                </div>
                           </div>
                       </div>
                  </div>
                  <div className="mask-market-frame ascend">
                       
                           <div className="container">
                               <div className="row">
                                   <div className="col-md-8"></div>
                                   <div className="col-md-4">
                                   <div className="mask-market-frame-inner">
                                   <p>Natural ingredients has been in the market since long and have shaken off their inexpensive tag and are repacked with branding, ingredients and expensive bottles. Organic products have moved forward with high performance safe formulations instead of harmful chemicals infused products. The <span className="high">global organic beauty market</span> would be worth just under $22 billion by 2024.</p>
                                   </div>
                                   </div>
                               </div>
                                 
                       </div>
                  </div>
                  <div className="manu-pro-middle-arr-facewash">
                    <div className="container">
                         <div className="manu-pro-middle-arr-facewash-top">
                             <p>We take pride in formulating safe products without any harmful chemicals like <span className="high">parabens, sodium benzoate, benzyl alcohol, formaldehyde, sodium lauryl sulphate, petrolatum, coal tar, hydroquinone, triclosan, oxybenzone, phthalates, lead</span> other adverse chemicals. </p>
                             <h4>WE PROUDLY MANUFACTURE FACE WASH FORMATS LIKE</h4>  
                         </div>
                         <div className="manu-pro-middle-arr-facewash-bottom">   
                              <div className="row">
                                  <div className="col-md-5">
                                      <div className="cont-gelcle one">
                                          <h5>GEL CLEANSERS</h5>
                                          <h5>CREAM CLEANSERS</h5>
                                          <h5>POWDER FACE CLEANSERS</h5> 
                                          <h5>SOAP- FREE CLEANSERS</h5>
                                      </div>
                                  </div>
                                  <div className="col-md-2 middle_frame_dott">
                                      <img src={facewash8} className="img-responsive"  alt="..."/>
                                  </div>
                                  <div className="col-md-5">
                                  <div className="cont-gelcle two">
                                          <h5>FOAM CLEANSERS</h5>
                                          <h5>OIL - BASED CLEANSERS</h5>
                                          <h5>BALM BASED CLEANSERS</h5>
                                          <h5>OIL TO MILK CLEANSERS</h5>
                                  </div>
                                  </div>  
                              </div>
                         </div>
                    </div>
                  </div>



                  <div className="lotion-market-movingframe range-product popular">
                       <div className="lotion-market-movingframe-inner popular n">
                           <div className="container">
                              <div className="row">
                              <div className="col-md-4">
                                  <img src={facewash4} className="img-responsive"  alt="..."/>
                               </div> 
                               <div className="col-md-1"></div>
                                 <div className="col-md-6 lotion-market-movingframe-inner_cont popular n facewash"> 
                                      <p>We formulate and manufacture <span className="high">organic and natural</span> products which are loaded with herbs, fruits and natural clay, helps to cleanse your face, nourish and repair without harming or disturbing the PH of your skin. We are <span className="high">private label organic manufacturers</span> and can help you build your label with <span className="high">organic and natural products.</span></p>
                                  </div>
                                  <div className="col-md-1"></div> 
                              </div>
                            </div>
                       </div>
                  </div> 
                   
                

              

            </div>
        );
    }
}

export default FaceWash;