import React, { Component } from 'react';
import Sebumimg from '../lab/blog/Sebumimg.jpg';
import {Helmet} from "react-helmet";

class Sebum extends Component {
    componentDidMount() {
		window.scrollTo(0, 0) 
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish skin-exfoliation SEBUM">
            <Helmet>    
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="index, follow" />   
                <meta name="description" content="SEBUM" />
                <title> SEBUM</title>  
            </Helmet>
            <div className="Heading_tu">   
                    <h1>SEBUM</h1>
            </div>
                <div className="Image_frame">
                   <img src={Sebumimg} className="img-responsive"  alt="..."/>
                </div>
        <div className="container">
        
                <div className="Image_frame-middle">
                <div className="Heading_tu">   
                     
                    <p>It’s oily and waxy, secreted from your own skin from head to toe. Though it may not be the most pleasant sounding skin-care term, SEBUM is a crucial component of the skin barrier- and when it’s thrown off balance (which can happen for a number of reasons), a variety of issues can occur. What’s more, thought the terms sebum and oil sometimes used interchangeably in reference to skin, they are not one and the same.  </p>
                    <p>When people says its oil on the skin is generally thought to be one component of it. But it’s also often includes environmental buildups and sweat that can occur on the skin. So what is sebum exactly? </p>
                </div> 
                <div className="Heading_tu">
                    <h4>SEBUM?</h4>
                   <p>It is a complex mixture of fatty acids, sugars, waxes and other natural chemicals that forms a protective barrier against water evaporation. To be more specific the composition if sebum is made up of wax esters, fatty acids, triglycerides which together help to keeps the skin moisturized. So “what we call oil” on our skin is made up of more than just sebum, but also has mixture of dead skin cells, sweat and even tiny particles of whatever else is in the dust floating around you. </p>
                   </div>

                   <div className="Heading_tu">
                    <h4> WHERE DOES SEBUM COME FROM???? </h4>
                   <p>
                   Sebum is produced by the sebaceous glands, which naturally cover our bodies from tip to toe. There are two type of sebaceous glands: Those that are connected to hair follicle, and those that are not. Sebaceous follicles are most commonly found on the face, behind the ears and on the upper portions of the chest and back. On face itself, you’ll find the highest concentration of sebaceous glands around T-zone, which is why this area is generally more prone to developing acne. 
                   </p>
                   </div>
                   <div className="Heading_tu">
                    <h4> WHY IS IT IMPORTANT?? </h4>
                   <p>
                   As shitty as excess oiliness and shine cab be, sebum is actually to overall skin health – that is, when it’s not over-or-under produced by the body. Sebum serves, as a protective and moisturizing barrier on the surface of the skin, helping to prevent water loss. Moreover, it can also provide anti-inflammatory, antioxidant and antibacterial benefits through its different components. 
                   </p>
                   </div>
                   <div className="Heading_tu">
                    <h4> TOO MUCH VS. TOO LESS!!! </h4>
                   <p>
                   In case of sebum, too much of a good thing is not a good thing- as it causes plugged up glands and breakouts. If you have acne, you likely suffer from too much oil production. Another condition, seborrheic dermatitis, can develop when an overproduction of sebum mixes with yeast, creating symptoms like flaking, red and itchy skin on the forehead, cheeks and scalp. That said too less of sebum, makes your skin dry and show up as eczema, which is an impaired skin barrier that needs to be restored. 
                   </p>
                   </div>    
        </div>
        </div>
        </div>  
        );
    }
}

export default Sebum;