import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import VideoSound from '../Cate/VideoSound';
import Testing1 from '../lab/Testing1.mp4';
import Testing2 from '../lab/Testing2.mp4';
import TestingN1 from '../lab/blog/TestingN1.mp4';
import TestingN2 from '../lab/blog/TestingN2.mp4';
import Cruelty1 from '../lab/Cruelty1.gif';
import Cruelty2 from '../lab/Cruelty2.gif';
import Cruelty3 from '../lab/Cruelty3.gif';
import Cruelty4 from '../lab/Cruelty4.gif';
import Cruelty5 from '../lab/Cruelty5.gif';
import Cruelty6 from '../lab/Cruelty6.gif';
import Cruelty7 from '../lab/Cruelty7.gif';
import Cruelty8 from '../lab/Cruelty8.gif';
import Cruelty9 from '../lab/Cruelty9.gif';
import Cruelty10 from '../lab/Cruelty10.gif';
import Test1 from '../lab/blog/Test1.jpg';
import Test2 from '../lab/blog/Test2.jpg';
import Test3 from '../lab/blog/Test3.jpg';  
import HeadingTesting from '../lab/blog/HeadingTesting.jpg';

class Testing extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <>
           {/*<VideoSound/>  */} 
            <div className="testing_main_frame"> 
           <div className="testing_main_frame_middle_main">
                 <div className="top-video_privel testing_desktop">
                  <video autoPlay loop muted>
                        <source src={Testing1} type="video/mp4"/>
                        <source src={Testing1} type="video/ogg"/>       
                    </video>
                </div>
                <div className="top-video_privel testing_mobile">
                  <video autoPlay loop muted>
                        <source src={TestingN2} type="video/mp4"/>
                        <source src={TestingN2} type="video/ogg"/>       
                    </video>
                </div>
              
                <div className="container">
                <div className="testing_top_head"> 
                    <img src={HeadingTesting} className="img-responsive"  alt="..."/>
                    <p><span className="first-spa">A</span>t Vert Laboratoire, we aim to manufacture products of highest quality. Which means creating products which are time tested and super safe with our <span className="zerocal">Green Chemistry</span> based innovation and sustainability. All our formulas undergoes stringent testing to ensure the end-consumer receives an exceptional product that have real results. </p>
                    <p>Our goal is to create products which are not only safe for humans, but also safe for the environment. We are propelling <span className="zerocal">Zero Carbon Foot Print</span> since years and we are <span className="zerocal">Zero Waste Manufacturing Unit.</span> Our products are not only tested during <span className="zerocal">production</span> and <span className="zerocal">development,</span> but also during <span className="zerocal">filling</span> process to ensure stable results. </p>
                </div> 
                </div>
                </div>
                <div className="container raw_metel_testing first_main desktop">
               

                <div className="row raw_metel_testing first">
                    <div className="col-md-4">
                         <div className="raw_metel">
                                 <h4>Raw Materials</h4> 
                                 <p>Every ingredients is quarantined, tested and recorded before it is allowed into the production unit. </p>
                         </div>
                    </div>
                    <div className="col-md-4">
                         <div className="raw_metel_img one">
                             <img src={Test1} className="img-responsive"  alt="..."/>           
                         </div>
                    </div> 
                    <div className="col-md-4">
                         <div className="raw_metel">
                                 <h4>Filling</h4> 
                                 <p>No matter how much care and attention is paid to the development and manufacture of a product, if it’s not perfectly filled the end result can be disastrous. We therefore inspect every jar, tube and bottle individually as it comes off the line.  </p>
                         </div>
                    </div>
                </div>
                <div className="row raw_metel_testing botto">
                <div className="col-md-4">
                         <div className="raw_metel_img two">
                            <img src={Test2} className="img-responsive"  alt="..."/>
                      
                         </div>
                    </div>
                    <div className="col-md-4">
                         <div className="raw_metel">
                                 <h4>Packaging</h4> 
                                 <p>All packaging material is quarantined in its own stations and released only after it satisfies our stringent testing. Packaging that fails is returned to suppliers. </p>
                         </div>
                    </div>
                    
                    <div className="col-md-4">
                         <div className="raw_metel_img three">
                           <img src={Test3} className="img-responsive"  alt="..."/>
                         </div>
                    </div> 
                </div>
                
              
                
                </div>
                <div className="container raw_metel_testing first_main mobile">
               

               <div className="row raw_metel_testing first">
                   <div className="col-md-4">
                        <div className="raw_metel">
                                <h4>Raw Materials</h4> 
                                <img src={Test2} className="img-responsive"  alt="..."/>
                                 <p>Every ingredients is quarantined, tested and recorded before it is allowed into the production unit. </p>
                        </div>
                   </div>
                   <div className="col-md-4">
                        <div className="raw_metel">
                                <h4>Packaging</h4> 
                                <img src={Test1} className="img-responsive"  alt="..."/>           
                                <p>All packaging material is quarantined in its own stations and released only after it satisfies our stringent testing. Packaging that fails is returned to suppliers. </p>
                        </div>
                   </div>
                   <div className="col-md-4">
                        <div className="raw_metel">
                                <h4>Filling</h4> 
                                <img src={Test3} className="img-responsive"  alt="..."/>
                                <p>No matter how much care and attention is paid to the development and manufacture of a product, if it’s not perfectly filled the end result can be disastrous. We therefore inspect every jar, tube and bottle individually as it comes off the line.  </p>
                        </div>
                   </div>
               </div>
             
             
               
               </div>
        <div className="product_list_pri-lable new">
            <h4>WE CONDUCT MANY STANDARD TESTS ON OUR PRODUCTS - INCLUDING </h4>
            <div className="top-video_privel testing_desktop">
            <video autoPlay loop muted>
            <source src={Testing2} type="video/mp4"/>
            <source src={Testing2} type="video/ogg"/>       
            </video>
            </div>
            <div className="top-video_privel testing_mobile">
            <video autoPlay loop muted>
            <source src={TestingN1} type="video/mp4"/>
            <source src={TestingN1} type="video/ogg"/>               
            </video>
            </div>
        </div>  
                <div className="container">

<div className="product_list_pri-lable">
    <h4 className="proudly_we">WE PROUDLY ARE</h4>
    <ul>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
    <img src={Cruelty1} className="img-responsive"  alt="..."/>
    <h5>CRUELTY FREE <br/>FACILITY</h5>
    </ScrollAnimation>
    </li>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
      
    <img src={Cruelty2} className="img-responsive"  alt="..."/>
    <h5>ECO-FRIENDLY <br/>PRODUCTING</h5>
    </ScrollAnimation>
    </li>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
      
    <img src={Cruelty3} className="img-responsive"  alt="..."/>
    <h5>PARABEN & <br/>SLS FREE</h5>
    </ScrollAnimation>
    </li>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
      
    <img src={Cruelty4} className="img-responsive"  alt="..."/>
    <h5>VEGAN</h5>
    </ScrollAnimation>
    </li>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
      
    <img src={Cruelty9} className="img-responsive"  alt="..."/>
    <h5>ZERO CARBON<br/> FOOT PRINT</h5>      
</ScrollAnimation>
    </li>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
      
    <img src={Cruelty6} className="img-responsive"  alt="..."/>
    <h5>ZERO WASTE<br/>
MANUFACTURING<br/>
UNIT</h5>
</ScrollAnimation>
    </li>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
      
    <img src={Cruelty10} className="img-responsive"  alt="..."/>
    <h5>REEF SAFE, NO PLASTIC BEEDS</h5>
</ScrollAnimation>
    </li>
    <li>
    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
      
    <img src={Cruelty8} className="img-responsive"  alt="..."/>
    <h5>WE USE
RENEWABLE
ENERGY SOURCE
(SOLAR AND WIND)
FOR 
MANUFACTURING 
</h5>
</ScrollAnimation>
    </li>
    </ul>
</div>
<div className="clear"></div>

</div>
            </div>  
            </>
        );
    }
}

export default Testing;