import React, { Component } from 'react';
import Mencare1 from '../lab/mencare1.mp4';
import Mencare2 from '../lab/mencare2.jpg';
import Mencare3 from '../lab/mencare3.jpg';
import Mencare4 from '../lab/mencare4.jpg';
import Mencare5 from '../lab/mencare5.jpg';
import Mencare6 from '../lab/mencare6.jpg';
import Mencare7 from '../lab/mencare7.jpg';
import Mencare8 from '../lab/mencare8.jpg'; 

class MenCareNew extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="mencarenewpage mencareBG">
                 <div className="make-top-banner">
                <video autoPlay loop muted>
                        <source src={Mencare1} type="video/mp4"/>
                        <source src={Mencare1} type="video/ogg"/>       
                    </video>    
                 </div>
                 <div className="container">
                    <div className="top_head_men-heading">
                        <img src={Mencare2} className="img-responsive"  alt="..."/>    
                        <p>The global market for men’s grooming products has grown by 6.4% in 2021. This growth is expected to accelerate at CAGR 8% over the period of 2021-2031. Thus, revenue from the sales of men’s grooming products across the world will nearly double by 2031 form the current value of US $55.5 Billion. </p>
                     </div>  
                  </div>
                  <div className="container">
                      <div className="mencarerecentyear">
                      <div className="row">
                          <div className="col-md-6">
                          <img src={Mencare3} className="img-responsive"  alt="..."/>    
                          </div>
                          <div className="col-md-6">
                              <p>In recent years, men’s grooming has shifted from conventional focus on shaving to a broader perspective for personal care. Men’s skin market has been dramatically affected by the pandemic for different reasons, mainly due to the closure of shops and saloons. One of the category that has been affected the most was the shaving products. </p>
                          </div>
                      </div>
                      </div>
                  </div>

                  <div className="mencareskinis">
                  <div className="container">
                      <div className="row">
                            <div className="col-md-8">
                                <p>Men’s skin is diametrically different from women’s. While this is true in a structural sense. Skincare requirements for both genders are the same. Men’s skin is 20 percent thicker, 70 percent more oily and 40 percent more sweaty. Environmental exposure, stress levels and testosterone hormones impacting their skin can also cause variance from women’s skin. </p>
                            </div>
                            <div className="col-md-4">
                                <div className="right-imagemencarenew">
                                 <img src={Mencare4} className="img-responsive"  alt="..."/>  
                            </div>  
                            </div>
                      </div>
                      </div>
                  </div>
                  <div className="container">
                      <div className="mencarerecentyear2">
                      <div className="row">
                          <div className="col-md-4">
                          <img src={Mencare5} className="img-responsive"  alt="..."/>    
                          </div>
                          <div className="col-md-8">
                            <p>Natural products are powerful and have good antioxidants that helps in slowing aging process and also help in glowing and healthier skin. We proudly craft beautiful concoctions by offering <span class="high">GMO free, Bio-degradable, Natural, Clean and Organic products.</span> </p>
                           </div>
                      </div>
                      </div>
                      <div className="immenseframemencare">
                          <p>We take immense pride in formulating safe products without any harmful chemicals like <span class="high">phthalates, sodium lauryl sulfate, petroleum products, triethanolamine, parabens, BHT, retinol, retinylpalmitate, lead, formaldehyde, mineral oil, polyethylene glycol and butylene glycol and other harmful chemicals.</span> </p>
                      </div>
                  </div>

                  <div className="mencareproductlist-bottom heading-img">
                  <div className="row">
                  <div className="col-md-5">
                  </div>
                  <div className="col-md-7">
                     <img src={Mencare8} className="img-responsive"  alt="..."/> 
                  </div>
                  </div> 
                  </div>
                  <div className="mencareproductlist-bottom">
                      <div className="row">
                          <div className="col-md-5">
                              <div className="mencarebottom-fixedsection">
                                  <img src={Mencare6} className="img-responsive"  alt="..."/> 
                             </div>
                          </div>
                          <div className="col-md-7">
                              <div className="row">
                                  <div className="col-md-5">
                                      <ul>
                                          <li>FACE WASHS</li>
                                          <li>FACE MOISTURIZERS</li>
                                          <li>FACE MASKS</li>
                                          <li>FACE MISTS</li>
                                          <li>FACE SERUMS</li>
                                          <li>FACE SCRUBS</li>
                                          <li>UNDER EYE CREAMS </li>
                                          <li>BEARD OILS</li>
                                          <li>BEARD CREAMS</li>
                                          <li>HAIR SPRAYS</li>
                                          <li>HAIR WAX</li>
                                          <li>HAIR GELS</li>
                                          <li>HAIR OILS</li>
                                      </ul>  
                                  </div>
                                  <div className="col-md-1">
                                      <div className="mencaremiddle-border"></div>
                                  </div>
                                  <div className="col-md-5">
                                      <ul>
                                          <li>HAIR CREAMS</li>
                                          <li>HAIR POMADES</li>
                                          <li>HAIR SHAMPOOS</li>
                                          <li>HAIR CONDITIONERS</li>
                                          <li>BEARD WASH</li>
                                          <li>SHAVING SOAPS</li>
                                          <li>SHAVE LOTION </li>
                                          <li>PRE SHAVE OIL</li>
                                          <li>POST SHAVE LOTION</li>
                                          <li>MASSAGE OILS</li>
                                          <li>SHOWER GEL </li>
                                          <li>BODY LOTION</li>  
                                          <li>BODY BUTTERS</li>
                                      </ul>  
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="mencareproductlist-bottom lastp">
                      <div className="row">
                          <div className="col-md-7">
                             <img src={Mencare7} className="img-responsive"  alt="..."/> 
                          </div>
                          <div className="col-md-4">
                              <p><span class="high">Organic and natural</span> products are loaded with herbs, fruits and natural oils which helps to cleanse your face, nourish and repair without harming or disturbing your skin. We are <span class="high">private label organic manufacturers</span> and can help you build your label with <span class="high">organic and natural</span> products.</p>
                          </div>
                      </div>
                  </div>
            </div>
        );
    }
}

export default MenCareNew;