import React, { Component } from "react";
import { Link } from "react-router-dom";
import FooterFrom from "./FooterFrom";


export default class Footer extends Component { 
  render() {
    return (
      <div className="footer_main_section">
    

        <footer>
        <div className="container">
        <div className="row">
        <div className="col-md-3 footer-left-frame">
           <h4>About Us</h4> 
           <div className="border_footer"></div>
       
					<div className="footer-left">
					   <p>
             We are in business of making people and planet beautiful. We seek Harmony and drive the circular economy through green innovations. We fuel growth with values and responsibility.


					  </p>
            <p className="Address-main-dd">
              <b>Address:</b>
              
              75 Shelton Street, Covent Garden , London, United Kingdom
            </p>
					</div>
        
				
				</div>
        <div className="col-md-3">
        <div className="footer-left2">
        <h4>Services</h4> 
        <div className="border_footer"></div> 
					   <ul>
             <li> <a href="/Branding">Branding</a> </li>
               <li> <a href="/custom-formulation">Custom Formulation</a> </li>
               <li><a href="/private-label">Private Label</a></li>
               <li> <a href="/packaging">Packaging</a></li>
               <li> <a href="/Manufacturing">Manufacturing</a> </li>  
               <li> <a href="/testing">Testing</a> </li> 
               
               
               <li><a href="/blog">Blog</a></li>
             
					   </ul>
					</div>
        </div>
      
        <div className="col-md-3">
        <div className="footer-left2">
        <h4>Products</h4> 
        <div className="border_footer"></div> 
					   <ul>
               
               <li> <a href="/facecare">Face Care</a> </li>
               <li> <a href="/makeup">Makeup</a> </li>  
               <li> <a href="/Haircare">Hair Care</a> </li> 
               <li><a href="/babycare">Baby Care</a></li>
               <li> <a href="/Bodycare">Body Care</a></li>
               <li><a href="/Mencare">Men Care</a></li>
               <li><a href="/hotel-and-spa-amenities">Hotel Amenities</a></li>
             
					   </ul>
					</div>
        </div>
        <div className="col-md-3">
				    <div className="footer-left2">
					     <div className="footer-top-heading">
						
              <h4>Reach Out</h4> 
              <div className="border_footer"></div>
							
						 </div>       
						
             <div class="footer-left"><p><span> </span></p></div>  
					   <div className="clear"></div>
             <ul className="footer-right-list">  
               
               <li className="cont-footer"> <a href="/contact">Contact Us</a> </li>
             
					   </ul>
             <a href="/">
        <img src={"https://ik.imagekit.io/deepak/logo-new_RQuBGP2uu.jpg?updatedAt=1692729297770"}  />
        </a>  
          </div>
				</div>
          </div>
        </div>  
        </footer> 

      </div>
    );
  }
}
