import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


class Categories extends Component {
    constructor() {
        super();
        this.state = { tabIndex: 0 };
      }
      render() {
        return (
            <div className="container Cate">
                <h3> Product Categories  </h3>
          <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
            <TabList>
              <Tab>Cleaners & Sanitizers</Tab>
              <Tab>Face Care </Tab>
              
              
             
              <Tab>Baby Care</Tab>
              <Tab>Hair Care</Tab>
              <Tab>Men's Care</Tab>
              <Tab>Make Up</Tab>
            </TabList>
            <TabPanel>
              <div className="home-catelist row">
                <ul className="col-md-3">
                    <li>Handmade soap</li>
                    <li>Transparent soap</li>
                    <li>Neem soap</li>
                    <li>Hand cleansers</li>
                    <li>Hand Soap</li>
                    
                </ul>
                <ul className="col-md-3">
                <li>Bacterial Soap </li>
                    <li>Mint soap</li>
                    <li>Oats soap</li>
                    <li>Luxury soap </li>
                    <li>Hotel soaps</li>
                   
                </ul>
                <ul className="col-md-3">
                    <li>Hand rub</li>
                    <li>Olive soap</li>
                    <li>Facial soap</li>
                    <li>Rose soap </li>
                    <li>Vitamin e soap</li>
                   
                </ul>
                <ul className="col-md-3">
                    <li>Bathing soaps</li>
                    <li>Baby soap</li>
                    <li>Gift soaps</li>
                    <li>Sanitizer</li>
                   
                </ul>
              </div>                  
            </TabPanel>
            <TabPanel>
            <div className="home-catelist row">
                <ul className="col-md-3">
                    <li>Face Cleansers</li>
                    <li>Face Scrubs</li>
                    <li>Face Wash</li>
                </ul>
                <ul className="col-md-3">
                    
                    <li>Face Creams</li>
                    <li>Face Serums</li>
                    <li>Face lotion</li>
                   
                </ul>
                <ul className="col-md-3">
                
                    <li>Face Masks</li>
                    <li>Face Mists</li>    
                   
                </ul>
                <ul className="col-md-3">
               
                <li>face elixir</li>
                <li>Face oils</li>
            </ul>
                </div>
            </TabPanel>
           
           
           
            <TabPanel>
                 <div className="home-catelist row">
                <ul className="col-md-3">
                    <li>Creams</li>
                    <li>Oils </li>
                  
                </ul>
                <ul className="col-md-3">  
                    <li>Body </li>
                    <li>Soaps</li>
                   
                </ul>
                <ul className="col-md-3">  
                   
                    <li>Rash Cream</li>
                </ul>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="home-catelist row">
                <ul className="col-md-3">
                    <li>Shampoos</li>
                   <li>Conditioners</li>
                   <li>Hair Colors</li>
                   
                </ul>
                <ul className="col-md-3">
                   
                  
                   <li>Hair Oil</li>
                   <li>Hair cream</li>
                   <li>Hair styling pomade</li>
                  
                </ul>
                <ul className="col-md-3">
                <li>Hair styling Gel</li>
                   <li>Hair styling wax</li>
                  
                </ul>
                <ul className="col-md-3">
               
                   <li>Hair color streaks</li>
                   <li>Hair Masks</li>
                </ul>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="home-catelist row">
                <ul className="col-md-3">
                    <li>Face Wash</li>
                    <li>Shaving Cream</li>
                    <li>Beard Oil</li>
                </ul>
                <ul className="col-md-3">
                   <li>Shaving Soap</li>
                    <li>Shave Lotion</li>
                   <li>Body wash</li> 
                </ul>
                <ul className="col-md-3">
                    <li>Handmade Soaps</li>
                    <li>Shower Gels</li>  
                </ul>
                <ul className="col-md-3">
                    <li>Shampoo</li>
                    <li>Conditioner</li>
                </ul>
                </div>
            </TabPanel>
            <TabPanel>
                 <div className="home-catelist row">
                <ul className="col-md-3">
                    <li>Lipsticks</li>
                    <li>Liquid lip color</li>
                    <li>Lip Gloss</li>
                    <li>Lip Tint</li>
                   
                </ul>
                <ul className="col-md-3">
                    <li>Lip liner</li>
                    <li>Mascara</li>
                    <li>Eye Liner</li>
                   
                </ul>
                <ul className="col-md-3">
                    <li>Eye Brow Pencil</li>
                    <li>Eye Shadow</li>
                    <li>Foundation </li>
                   
                </ul>
                <ul className="col-md-3">
                    <li>Tinted Moisturiser </li>
                    <li>Concealer </li>
                    <li>Lip to cheek multi Sticks</li>
                   
                </ul>
                </div>
                </TabPanel>
          </Tabs>
          </div>
        );
      }
}

export default Categories;