import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export default class slider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,

          };
        return (
            <div>
           
           <Slider className='sliderTest' {...settings}>
              <div>
              
                <img src={"https://ik.imagekit.io/deepak/Banners1_Lqs03DqOJ.jpg"} class="img-responsive"  alt="..."/>
           
              </div>
              <div>
              
                <img src={"https://ik.imagekit.io/deepak/Banners-2_zXWc4tj8kM.jpg"} class="img-responsive"  alt="..."/>
           
              </div>
              <div>
              
                <img src={"https://ik.imagekit.io/deepak/Banners-3_9gtJiOaYJ8.jpg"} class="img-responsive"  alt="..."/>
           
              </div>
              <div>
              
                <img src={"https://ik.imagekit.io/deepak/Banners-4_7a2qBJotiL.jpg"} class="img-responsive"  alt="..."/>
           
              </div>
              <div>
              
                <img src={"https://ik.imagekit.io/deepak/Banners-5_-LBsMzJntp.jpg"} class="img-responsive"  alt="..."/>
           
              </div>
             
             
              
            </Slider>
          </div>
        )
    }
    }