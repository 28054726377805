import React, { Component } from 'react';
import Unevenimg from '../lab/blog/Unevenimg.jpg';
import {Helmet} from "react-helmet";

class Treatunevenskintone extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish uneven-skin-tone">
            <Helmet>
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="robots" content="index, follow" />   
<meta name="description" content="How to treat uneven skin-tone" />
<title> How to treat uneven skin-tone  </title>  
</Helmet>
             <div className="Image_frame">
                <img src={Unevenimg} className="img-responsive"  alt="..."/>
             </div>
     <div className="container">
     
             <div className="Image_frame-middle">
             <div className="Heading_tu">
                 <h1>HOW TO TREAT UNEVEN SKIN-TONE </h1>
                 <p>Uneven skin tone is a gradual process and doesn’t just happen overnight. So, if you have uneven skin tone, it means you unfortunately haven’t done enough to protect your skin from spots, blemishes and pigmentation. Does this means you still can’t?  </p>
             </div>    
             <div className="Heading_tu">
                 <h4>WHAT CAUSES UNEVEN SKIN TONE?</h4> 
                 <p> <span>SUN EXPOSURE-</span> Who doesn’t love sun kissed skin? But when the sun gets too intense, your skin produces excessive melanin. These pigments absorb UV rays, which ultimately damage your skin cells, making the exposed area darker than the rest. This also causes hyperpigmentation.  </p>
                 <p> <span>POST-</span> Inflammatory hyperpigmentation- if any part of your skin has sustained wounds, the scar may get darker. This is called scarring. Acne on your face can also leave ugly scars behind. </p>
                 <p> <span>HORMONAL CHANGES- </span> If you are pregnant or take contraceptives, the imbalance in hormones may cause high melanin production. This condition is called melisma, and it can give you an uneven skin tone. </p>
                 <p> <span>AGING- </span> As your age is increasing, you get age related spots on your face and other areas of your skin starts showing signs like wrinkles, spots, blemishes and many other sign of uneven skin tone.  </p>
                 <p> Moreover, as long as you get your hands on a good treatment for uneven skin tone, there’s still hope to undo the damage. Given the overwhelming list of options available in the market, its best you treat your skin with natural and organic ingredients. This will help you achieve the desired skin you always wanted to have.  </p>
            </div>  
            <div className="Heading_tu">
                 <h4>OATMEAL</h4> 
                 <p>Helps to soak up excess oil on your skin and help treat acne. Its antioxidant and anti-inflammatory properties help treat dry skin and remove dead skin cells. Oats also contain compounds called saponins, which are natural cleansers. They remove the dirt and oil that clog the pores and exfoliate the skin. </p>
            </div> 
            <div className="Heading_tu">
                 <h4>SANDALWOOD</h4> 
                 <p>It can be in any form an oil or powder, sandalwood is able to smooth inflammatory skin conditions like eczema, dermatitis and psoriasis, as well as calm irritated skin and flatten breakouts. It is an astringent, which means it can induce minor contractions in the soft tissues, therefore soothing and tightening skin and reducing the appearance of pores. </p>
            </div>  
            <div className="Heading_tu">
                 <h4>GRAM FLOUR</h4> 
                 <p>Using gram flour for face helps to treat acne. Zinc in gram flour can fight infection that causes acne and the fiber stabilizes blood sugar levels. Helps to remove tan and exfoliates dead skin. Also helps to treat blemishes and prevents the skin to get oily.  </p>
            </div> 
            <div className="Heading_tu">
                 <h4>MILK</h4> 
                 <p>Milk contains lactic acid, which is really effective for lightening skin and removing dead skin cells that get accumulated on your face. It also helps to soothe sun burns as it contains protein that wards off the burning sensation and diminishes unwanted pigmentation. </p>
            </div>     
     </div>
     </div>
     </div> 
        );
    }
}

export default Treatunevenskintone;