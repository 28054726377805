import React, { Component } from 'react';
import {Helmet} from "react-helmet";


class TreatBackAcneOrganically extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  } 
    render() {
        return ( 
            <div className="faceoilBlog-page BakuchiolBlog-page_cont tretback">
                <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="how to treat back acne organically" />
	<title> how to treat back acne organically </title>  
	</Helmet>
            <div className="container">
                 
                  <div className="Image_frame_bakuchiol">
                  <h1>HOW TO TREAT BACK ACNE ORGANICALLY </h1>
                  <img src={"https://ik.imagekit.io/deepak/Laboratoire/back-acne_bT5E5koZk.jpg?updatedAt=1629884619439"} className="img-responsive"  alt="..."/>
                  
                  </div>
                  </div>
                  <div className="container new">
                  <div className="Image_frame_bakuchiol">
                 <p className="text-center">If only we stressed about our back as much as we do about our face, back acne might not have been so common. For those who are not familiar with the term ‘bacne’, here’s a quick insight: Back acne is a skin condition of breakouts on the back. Since the skin on our back is thick, it’s dense with sweat and oil producing glands which is the perfect recipe for acne. </p>

                  </div>
                  <div className="bottom_cont_BakuchiolBlog">
                      <h3>Let’s look into some organic ways to treat back acne. </h3>
                     
                      <p><b>•Tea Tree Oil </b>– It is known to have antimicrobial and anti-inflammatory properties. As such, it can help reduce the inflammation associated with acne and prevent acne lesions. While its effectiveness in treating acne is widely accepted. </p>
                  </div>
                 <div className="bottom_cont_BakuchiolBlog">
                      <p><b>•Aloe Vera</b> – Aloe Vera has antibacterial properties that can help control and reduce acne- causing bacteria. It has certain characteristics that make it effective in fighting acne. As it is antioxidant and anti-inflammatory, person may use pure aloe Vera cream instead of basic cleanser to prevent acne breakouts. Due to the antibacterial agents in aloe Vera remove bacteria from skin and reducing the risk of spots developing.  </p>
                  </div>
                  <div className="bottom_cont_BakuchiolBlog">
                      <p><b>•Baking Soda</b> –help reduce acne breakouts and manage pain and inflammation of the skin due to its anti-inflammatory and antiseptic properties. Moreover, using baking soda used as exfoliate or added to current acne treatment to boost effects. Also brushing your teeth with baking soda or baking soda based toothpaste can help reduce the amount of harmful bacteria in your mouth and whiten your teeth. </p>
                  </div>
                 <div className="bottom_cont_BakuchiolBlog">
                      <p><b>•Apple Cider Vinegar</b> - This contains organic acids that may help kill the bacteria that cause acne. Also help reduce the appearance of scars. It contains proteins, enzymes and beneficial bacteria that are responsible for most of apple cider vinegar health benefits. </p>
                  </div>
                 <div className="bottom_cont_BakuchiolBlog">
                      <p><b>•Green Tea</b> – Green tea contains substances called catechins. These plant based compounds, or polyphenols have antioxidant, anti-inflammatory and antibacterial properties. Its helps in improving acne and oily skin issues. With its anti-inflammatory and antioxidant properties help to reduce sebum excretions in the skin. </p>
                  </div>
                  
                 
                  
           </div>
      </div>
        );
    }
}

export default TreatBackAcneOrganically;