import React, { Component } from 'react';
import WrinklesNaturally from '../lab/blog/WrinklesNaturally.jpg';
import {Helmet} from "react-helmet";

class BanishWrinklesNaturally extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish">
                   <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="Banish Wrinkles Naturally" />
	<title> Banish Wrinkles Naturally </title>  
	</Helmet>
                    <div className="Image_frame">
                       <img src={WrinklesNaturally} className="img-responsive"  alt="..."/>
                    </div>
            <div className="container">
            
                    <div className="Image_frame-middle">
                    <div className="Heading_tu">
                        <h1>BANISH WRINKLES NATURALLY</h1>
                        <p>Crease are not good to look, be it your clothes or on your face! The onset of wrinkles, probably the one thing no one ever wishes to see. Wrinkles are the result of a lack of moisture and elasticity of skin. This happens mainly due to aging but some environmental factors also have a role to play. Sun exposure, pollution, smoking or nutritional deficiencies are some more factors responsible for the creases on your face. </p>
<p>Wrinkles, also known as rhytides are folds in your skin. As you age, your skin produces less of proteins collagen and elastin. This makes your skin thinner and less resistant to damage. Environmental exposure, dehydration and toxins can all make your face more likely to develop pronounced wrinkles. Wrinkles are a natural part of getting older, and there’s no reason to dread getting them. But if you would like to slow the signs of aging on your face, chances are that you would want to buy cosmetics and anti-wrinkles, anti-aging creams to get rid of them.  </p>
                    </div>
                    <div className="Heading_tu">
                        <h4>SOME NATURAL WAYS TO TAKE CARE OF YOUR WRINKLES:</h4>
                        <p> <span> 1- Jojoba Oil – </span>  Jojoba oil is another natural and effective home remedy for wrinkles. All you need to do is massage a few drops of this oil on your fingertips, massage your face for a few minutes. Relax for 20 minutes and remove the oil with lukewarm water.    </p>
                        <p> <span> 2- Lemon Juice-</span> This vitamin C rich fruit can be a great way to treat wrinkles naturally. Just slice some lemon and squeeze its juice into fine lines and massage it all over. Acidic properties of lemon will help your skin look brighter and will mitigate the wrinkles as well. </p>
                        <p> <span> 3- Aloe Vera –</span>  Aloe Vera is rich in malic acid which improves skin elasticity. All you need to do is take some gel from the leave and apply it to your skin as it is. Wait for 15 minutes or till it dries and then wash it off. </p>
                        <p> <span> 4- Bananas – </span>   When we refer to a healthier diet, it is much more than just eating these nutritious foods. You can use these foods for topical applications as well. Bananas can also be used for this purpose. Its nutritional properties fight the causes of wrinkles. Mash the bananas and form a paste. Apply it on your face and let it rest for half an hour and then wash it off. You could also mix it with some avocado and honey for some effect. </p>
                        <p> <span> 5- Carrots – </span>   Carrots are rich in vitamin A which promotes collagen production, thereby keeping the skin smooth and wrinkle-free. For this, you can apply a carrot paste on your face every day. Just boil some carrots and blend it into a paste with some honey. Apply it on your face, rest for half an hour and then wash off. </p>
                        <p> <span>6- Pineapple – </span>  Pineapplehas enzymes that offer multiple benefits for your skin. These enzymes improve skin elasticity, moisture and it eliminates dead skin as well. It is rich in fibers, antioxidants and it boosts skin health as well. All you need to do is rub some pineapple flesh on your skin directly and leave it for 20 min. Once it dries out, wash it with cold water. </p>
                    </div>
            </div>
            </div>
            </div> 
        );
    }
}

export default BanishWrinklesNaturally;