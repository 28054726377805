import React, { Component } from 'react';
import Coconutimg from '../lab/blog/Coconutimg.jpg';
import {Helmet} from "react-helmet";

class CoconutOil extends Component { 
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish coconut-oil-benefits-for-hair">
            <Helmet>
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="robots" content="index, follow" />   
<meta name="description" content="Coconut Oil Benefits for Hair" />
<title> Coconut Oil Benefits for Hair  </title>  
</Helmet>
             <div className="Image_frame">
                <img src={Coconutimg} className="img-responsive"  alt="..."/>
             </div>
     <div className="container">
     
             <div className="Image_frame-middle">
             <div className="Heading_tu">
                 <h1>COCONUT OIL BENEFITS FOR HAIR</h1>
                 <p>The easiest thing to do can sometimes be the most underestimated one, like applying coconut oil on hair. No wonder your grandma swore by the magic elixir. Believe it or not a good oil massage rates high over any salon treatment any day. </p>
             </div>    
             <div className="Heading_tu">
                 <h4>BENEFITS OF COCONUT OIL</h4> 
                 <p> <span>•</span> The most trusted and natural treatment for hair growth has always been the humble coconut oil. After all, it penetrates deeper than any other oil and deeply conditions the tresses. When everything else fails, you can always count on this magical coconut oil to make your hair grow.  </p>
                 <p> <span>•</span> After a sweaty workout or during humid season, it’s natural for bacteria to accumulate on the scalp. This can cause your scalp to emanate an unpleasant odor. Coconut oil with its antifungal and antibacterial properties can help eliminate the odor-causing bacterial and even treat scalp infection in extreme cases. </p>
                 <p> <span>•</span> Did you know coconut oil has natural SPF? This means coating your hair with coconut oil can ensure sun protection for those delicate strands.  </p>
                 <p> <span>•</span> Damaged and brittle hair is a cry for help? Your hair needs nourishment to restore its shine and health with the help of coconut oil.  </p>
                 <p> <span>•</span> Coloring hair is fun until you start to notice its aftereffects. Your hair tends to get dry and breaks easily. However, you can call upon coconut oil for damage repair and protect your hair from further damage. </p>
            </div>  
            <div className="Heading_tu">
                 <h4>APPLY COCONUT OIL THE RIGHT WAY</h4> 
                 <p> If you’ve been diligent with applying coconut oil but are yet to see the results maybe you’ve got the application wrong. Here are few pointers worth considering to make the hair oil treatment more effective.  </p>
                 <p> <span>•</span> Always apply coconut oil on hair at night before sleeping for better absorption and results. Avoid applying it during the day as it could take time to soak up while also making hair look flat.  </p>
                 <p> <span>•</span> Warming coconut oil on low temperature before application helps liquefy it and makes it easier to spread on your hair strands. The warm oil will help open up the follicles for better penetration of oil into the roots. Although, be careful to not overheat the oil.   </p>
                 <p> <span>•</span> If your scalp is oily or your hair is thin you can avoid applying the oil on your scalp. This will ensure the oil doesn’t weigh down your hair.  </p>
                 <p> <span>•</span> When applying oil, focus more on the dry and damaged ends and less where your hair is the healthiest. Applying on the mid-shafts where your hair is not dry will unnecessarily make it oily.  </p>
                 <p> <span>•</span> If you can’t commit to an overnight treatment, leave the oil on your hair for at least 30 minutes before you rinse it off. Be careful with the amount of oil you use on your scalp as going overboard may require a couple of hair wash cycles to completely get rid of the residual oil.  </p>
            
            </div>     
     </div>
     </div>
     </div> 
        );
    }
}

export default CoconutOil;