import React, { Component } from 'react';

class Packaging extends Component {
     componentDidMount() {
		window.scrollTo(0, 0)
	  }  
    render() {
        return (
            <div className="packaging_main_frame">
               <div className="top_banner">
                    <img className="img-responsive" src={"https://ik.imagekit.io/deepak/packaging_jRH4Lomom.jpg"}  />
               </div>
               <section className="pack_middle_main_frame">
                     <div className="container">
                         <div className="row">
                             <div className="col-md-6">
                                  <div className="sustain_left">
                                        <h3>SUSTAINABLE PACKAGING</h3>
                                        <p>A book is always judged by its cover and your brand is no different. Compilation of the nitty gritties of your brand to align them with the prevailing trends, Drawing from an extensive research to develop a cover that mesmerizes prospective customers, is our prime forte. We intelligently interpret the most complex briefs and metamorphosize them into stunning, meaningful and creative package.</p>
                                        <p>Also given the current situation on Land fills , Sea water contamination and Polluted Air - we take extra care to not suffocate the environment any further.This is a conscious choice that we exercise via different routes</p>
                                  </div>
                             </div>
                             <div className="col-md-6">
                                  <div className="sustain_right_img right">
                                      <img className="img-responsive" src={"https://ik.imagekit.io/deepak/packaging2_-A7CoJHAz.jpg"}  />
                                  </div>
                             </div>
                         </div>
                         <div className="row">
                             
                             <div className="col-md-6">
                                  <div className="sustain_right_img">
                                      <img className="img-responsive" src={"https://ik.imagekit.io/deepak/packaging3_yuMHTmMEZ.jpg"}  />
                                  </div>
                             </div>
                             <div className="col-md-6">
                                  <div className="sustain_left">
                                        <ul>
                                            <li><span>Do not Over Pack -</span> Reduce the extra cartons,Boxes or leaflets /Brochures/Pamphlets</li>
                                            <li><span>Use HDPE Or PET</span> recyclable plastics -Ideal for the Rinse offs</li>
                                            <li><span>Clean Glass-</span> Heavier substrate ,breakable but very compatible with products and looks premium and stunning</li>
                                            <li><span>Stainless Steel -</span> Medium weight ,reusable and unbreakable substrate</li>
                                            <li><span>Allumminum -</span> Light weight ,Matt Finish , Reusable ,Looks neat</li>
                                       </ul>  
                                    </div> 
                             </div>
                         </div>
                         <div className="row main_design_frame">

                              <div className="col-md-6">
                                   <div className="main_design_frame_left">
                                        <h4>DESIGNING</h4>   
                                        <p>The authenticity, relevance and romance of 
your brand is your story 
and we take the onus of telling it compellingly.
Our vision commences where the customer's ends,presenting superior brand experiences and 
building productive conversations 
with the customer. 
We activate where the customer exists, 
introducing the web, social, print and 
environmental
 expressions of your brand.</p>
 <img className="img-responsive" src={"https://ik.imagekit.io/deepak/packaging5_lBVTu-mpJ.jpg"}  />
                                   </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="main_design_frame_right">
                                  <img className="img-responsive" src={"https://ik.imagekit.io/deepak/packaging4_p9iDuq0lxo.jpg"}  />
                                  <img className="img-responsive_al" src={"https://ik.imagekit.io/deepak/girl_P80aQ03PY.jpg"}  />
                                  </div>
                              </div>
                         </div>

                         <div className="clear"></div>
                         <div className="row visual_all_frame">
                              <div className="col-md-6">
                              <div className="top_cont CREATIVE_SERVICES">
                                        <h4>CREATIVE SERVICES</h4>
                                        <p>Innovation is what we believe in.<br/> From trade shows to showrooms,<br/> 
                                        campaign designs to <br/>execution-and everything in between, <br/>
we ignite the spark of brand growth.<br/>
 Our innate innovative and specialized approach, <br/>
stands your creative apart, <br/>driving visibility and business action.
</p>
<img src={"https://ik.imagekit.io/deepak/girl2_Z5vmUkbGOM.jpg"}  />
                                   </div>  
                              </div>
                              <div className="col-md-6">

                              <div className="top_cont VISUAL_ID">
                                        <h4>VISUAL ID</h4>
                                        <p>We don't just build and refresh brands.<br/>  We adopt your brand to build, <br/>
refresh and nourish it with a dash of creativity,<br/> 
persistence, heart and soul such that<br/>  Your brand presence<br/>
 is blazoned and sown in the consumer's mind.</p>
 <img src={"https://ik.imagekit.io/deepak/boy_s0kw9ehRW1.jpg"}  />
                                   </div>

                              </div>
                              <div className="col-md-12">

                              <div className="top_cont BRAND_INTERACTION">
                                        <h4>BRAND INTERACTION</h4>
                                        <p>Your brand stands as an identity and hence, it speaks. Interactive<br/>
 channels propel your brand towards evolving, multi dimensional relationship building. <br/>
We bridge the gap between consumers and the brand, built with fine content. <br/>
Our creative intelligence extends to websites, mobile, social and unique campaigns, <br/>
so your brand experience unfolds with zest across all digital mediums.
</p>
                                   </div>
                              </div>
                              <div className="col-md-5">       
                                   <div className="top_cont AUDIO_IDENTITY">
                                      <img  src={"https://ik.imagekit.io/deepak/girl3_8ToEzLukMt.jpg"}  />
                                   </div>
                              </div>
                              <div className="col-md-7">       
                                   <div className="top_cont AUDIO_IDENTITY">
                                        <h4>AUDIO IDENTITY</h4>
                                        <p>How does your brand sound? Does it resonate with the market?<br/> 
That's where our internal brand identity and external advertising<br/>
 expertise intervenes. <br/>
Audio Identity exhales the holistic expression of your brand and our insights<br/>
 and strategies add a layer of brand equity to the way your brand sounds.
</p>
                                   </div>
                              </div>
                         </div>
                          
                         <div className="clear"></div>

                         <div className="middle_banner">
                              <div className="middle_banner_img">
                                  <img className="img-responsive" src={"https://ik.imagekit.io/deepak/middle_pack_qwLXZTuAj.jpg"}  />
                              </div>
                         </div>

                         <div className="clear"></div>

                         <div className="row brand_express_all">
                           
                            <div className="col-md-12">
                                 <div className="Brand_Experience">
                                   <h4>Brand Experience</h4>
                                   <p>Brand storytelling is how you convey authenticity, 
relevance and romance of your brand. 
Our design and digital services are all about
 activating superior brand experiences and
 building meaningful conversations with your customers. 
We activate where the customer exists: everywhere, 
from web, social, print,Retail 
Merchandise,store experience to environmental expressions of your brand.</p>
</div>
                            </div>

                        </div> 

                        <div className="only_use_frame row">
                        <div className="col-md-4">
                               <img className="img-responsive" src={"https://ik.imagekit.io/deepak/packaging6_Kd0UOc2SS.jpg"}  />
                            </div>
                             <div className="col-md-8">
                                 <img className="img-responsive" src={"https://ik.imagekit.io/deepak/brandgap__Syuc5ah_.jpg"}  />
                             </div>
                               
                             
                        </div>  

                        <div className="clear"></div>
                         
                         <div className="innvoo">
                              <h5>INNOVATIVE BEAUTY CARE PRODUCTS FORMULATED TO THE HIGHEST STANDARDS,<br/>
                              PACKAGED WITH YOUR BRAND AND LABEL, AS PER YOUR PREFERENCE.</h5>
                              <p>Bring Your Green Creation to Life with Our Wide Range of Recycled Packaging Options</p>
                              <img src={"https://ik.imagekit.io/deepak/lip-3_z9sgHFaSsr.jpg"}  />
                         </div>

                         <div className="start_design_page">
                              <p>Simply select and start designing with our label design and packaging <br/> team who ensure to deliver world className expertise with each<br/> eco-conscious curation. Customize from shape of the bottle, color, <br/>materials that complements your hotel property's brand personality<br/>
                              perfectly.</p>
                         </div>

                         <div className="row brand-idea">
                                <div className="col-md-5">
                                <div className="only_use_frame_img">
                              <img className="img-responsive" src={"https://ik.imagekit.io/deepak/lip-1_HzvHe_xpWC.jpg"}  />
                              <img className="img-responsive" src={"https://ik.imagekit.io/deepak/lip-2_zDCofSpIH.jpg"}  />
                              </div>
                                </div>
                                <div className="col-md-7">
                                      <p>Reflect your brand ideas with our label design team that efficaciously  translates & executes your desired designs.</p>
                                      <p>Personalize one of our existing amenity collection with your logo or develop a fully  customized formulation with our professional creative team.</p>
                                </div>
                         </div>

                        <div className="brand_gap_frame">
                              <div className="col-md-12"><p>
Our R&D, Logo Design, Creative Visualizing and Marketing Teams Carefully<br />
take in Scrutiny all Details to Innovate a Holistic Brand Pathway that Crafts the <br />
Clients' core Brand Story.

</p></div>
                        </div>

                     </div>
               </section>
            </div>
        );
    }
}

export default Packaging;