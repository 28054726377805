import React, { Component } from 'react';
import FaceCare1 from '../img/FaceCare1.jpg';   
import FaceCare2 from '../img/Facecare2.jpg'; 
import Facecare3 from '../img/Facecare3.png'; 
import Facecare4 from '../img/Facecare4.png'; 
import FaceCare5 from '../img/Facecare5.png'; 
import FaceCare6 from '../img/Facecare6.jpg'; 
import FaceCare7 from '../img/Facecare7.png'; 
import FaceCare8 from '../img/Facecare8.png'; 
import FaceCare9 from '../img/Facecare9.png'; 
import FaceCare10 from '../img/Facecare10.jpg'; 
import FaceCare11 from '../img/Facecare11.jpg'; 


class FaceCare extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="facecare_main_page">
                <div className="facelotion_heading facecare">
                     <h2>FACE CARE</h2>    
                 </div>
                 <div className="container">
                      <div className="facecare_topsection">
                             <div className="facecare_topsectionL left">
                                 <img src={FaceCare1} className="img-responsive"  alt="..."/>
                             </div> 
                             <div className="facecare_topsectionL mi">
                                 <div className="facecare_topsectionL_mi">
                                <img src={FaceCare2} className="img-responsive"  alt="..."/>
                                </div>
                             </div>
                             <div className="facecare_topsectionL right">
                             <div className="facecare_topsectionL_right1">
                                <a href="/facecare/facewash">
                                 <img src={FaceCare7} className="img-responsive"  alt="..."/>
                                 </a>
                              </div>
                              <div className="facecare_topsectionL_right2">
                                 <a href="/facecare/exfoliation">
                                 <img src={Facecare3} className="img-responsive"  alt="..."/>
                                 </a> 
                              </div>
                             </div>
                      </div>

                      <div className="facecare_topsectionsec">
                          <div className="facecare_topsectionsecLeft">
                          <a href="/facecare/serums">
                             <img src={Facecare4} className="img-responsive"  alt="..."/>
                           </a>
                          </div>
                          <div className="facecare_topsectionsecmiddle">
                            <img src={FaceCare6} className="img-responsive"  alt="..."/>
                          </div>
                          <div className="facecare_topsectionsecright">
                          <a href="/facecare/cream">
                             <img src={FaceCare9} className="img-responsive"  alt="..."/>
                           </a>
                          </div>
                       </div>


                       <div className="facecare_topsectionBottom">
                           <div className="facecare_topsectionBottom-left">
                               <div className="facecare_topsectionBottom-leftOne">
                               <a href="/facecare/lotion">
                                 <img src={FaceCare5} className="img-responsive"  alt="..."/>
                              </a>
                              </div>
                              <div className="facecare_topsectionBottom-lefttwo">
                              <a href="/facecare/mask">
                                 <img src={FaceCare8} className="img-responsive"  alt="..."/>
                              </a>
                              </div>
                             
                           </div>  
                           <div className="facecare_topsectionBottom-middle">
                           <img src={FaceCare10} className="img-responsive"  alt="..."/>
                           </div>
                           <div className="facecare_topsectionBottom-right">
                           <img src={FaceCare11} className="img-responsive"  alt="..."/>
                           </div>
                        </div>

                 </div>
            </div>
        );
    }
}

export default FaceCare;