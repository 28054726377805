import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import MakeuoV from '../img/MakeuoV.mp4';
import MakeuoV2 from '../img/MakeuoV2.mp4';
import MakeuoV3 from '../img/MakeuoV3.mp4';
import MakeuoV4 from '../img/MakeuoV4.mp4';
import MakeuoV5 from '../img/MakeuoV5.jpg';
import Marquee from "react-fast-marquee";
import M1 from '../img/M1.jpg';
import M2 from '../img/M2.jpg';  
import M3 from '../img/M3.jpg';
import M4 from '../img/M4.jpg';
import M5 from '../img/M5.jpg';
import M6 from '../img/M6.jpg';
import M7 from '../img/M7.jpg';
import M8 from '../img/M8.jpg';
import M9 from '../img/M9.jpg';
import MakeuoV7 from '../img/MakeuoV7.jpg';
import MakeuoV8 from '../img/MakeuoV8.jpg';
import ColorMatte1 from '../img/ColorMatte1.gif';
import ColorMatte2 from '../img/ColorMatte2.gif';
import ColorMatte3 from '../img/ColorMatte3.gif';
import ColorMatte4 from '../img/ColorMatte4.gif';
import ColorMatte5 from '../img/ColorMatte5.gif';
import ColorMatte6 from '../img/ColorMatte6.gif';
import MakeupSlider from '../Cate/MakeupSlider.js';
import makeup1 from '../lab/makeup1.gif';
import makeup2 from '../lab/makeup2.gif';
import makeup3 from '../lab/makeup3.gif';


class MakeupNew extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  } 
    render() {
        return (
            <div className="makeup-new-pageAd makeupnewpage makeupBG">
                <div className="make-top-banner">
                    <video autoPlay loop muted>
                        <source src={MakeuoV} type="video/mp4"/>
                        <source src={MakeuoV} type="video/ogg"/>       
                    </video>              
                </div>   
                 <div className="container">
                     <div className="make-top_pagecont">
                        <h1>Makeup</h1>
                        <p><span className="Fid">S</span>ince the origin of makeup about 6000 years ago in the Ancient Egyptian Era till date has been a significant expression to mankind. Egyptian used kohl to paint their eyes and football players apply paint under their eyes as colors played a very significant role in that era till date. </p>
                     </div> 
                     
                 </div>
                 <div className="b_heading_bo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="b_heading_bo_left">
                                    <p><span className="Fid">A</span>t Vert Laboratoire, makeup is not just colors it shouldn’t just be safe for the people who wear it. It should also be safe for animals, the planet and the people who make it. Launch your natural makeup and start your own brand.  Our time tested formulas are designed with active levels of ingredients that shield skin from <span className="Fid2">blemishes, wrinkles, hyperpigmentation, breakouts, allergies, rashes.</span> Choose your makeup without compromising on the final finesse of the product it works seamlessly, it comes back to vibrancy, vivid washes of color with a focus on making your skin look and feel alive. </p>
                                </div>
                            </div>
                            <div className="col-md-4"> 
                            <div className="b_heading_bo_right">
                               <img src={makeup2} className="img-responsive"  alt="..."/>  
                            </div>
                            </div>
                        </div>
                        
                    </div>
                 </div>
                 <div className="b_heading_bo two">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-4"> 
                            <div className="b_heading_bo_right">
                            <img src={makeup1} className="img-responsive"  alt="..."/> 
                            </div>
                            </div>
                            <div className="col-md-8">
                                <div className="b_heading_bo_left">
                                     <p><span className="Fid">M</span>akeup is ever evolving in the current time and trends like no <span className="Fid2">makeup-makeup look, 80’s inspired look, colored mascara, graphic liners, wispy lashes, metallic bold eyes, fierce brows, natural foundation, nostalgic makeup, minimal and dewy makeup</span> and many more. Quarantine has given a new definition to makeup with mask being the highlight and makeup products like <span className="Fid2">eyeliners, mascaras and eye shadow</span> are winning the game. </p>
                                </div>
                            </div>
                           
                        </div>
                        
                    </div>
                 </div>
                 <div className="b_heading_bo three">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="b_heading_bo_left">
                                    <p><span className="Fid">Y</span>our skin deserves natural and organic ingredients and we as Green manufacturer highly believe in conscious sourcing of the ingredients from all around the world. By not disturbing the ecological system and give it back by selecting ingredients sustainably and ethically. </p>
                                    </div>
                            </div>
                            <div className="col-md-4"> 
                            <div className="b_heading_bo_right">
                              <img src={makeup3} className="img-responsive"  alt="..."/> 
                
                            </div>
                            </div>
                        </div>
                        
                    </div>
                 </div>
                 <div className="row_frem_makenewgirl">
                        <div className="col-md-1girl">
                            <div className="midd_makenew_middle">
                            <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                            <img src={MakeuoV7} className="img-responsive"  alt="..."/>                
                            </ScrollAnimation>
                            </div>
                            <div className="row_frem_makenew2">
                                <p><span className="Fid">W</span>e manufacture <span className="Fid2">safe makeup,non-toxic and without the nasty ingredients.</span> We offer wide range of options like <span className="Fid2">Natural makeup, Clean makeup, Organic makeup and Ayurveda makeup and others.</span> </p>
                           </div>
                        </div>
                 </div>
                 <div className="container">
                    <div className="row row_frem_makenewBo new">
                           <div className="col-md-12">
                               <p><span className="Fid2"> We do not use Sheep- Lanolin, Iac insect– Shellac, Sheep/buffalo – Caseinate, Beetle –Carmine, Shark Liver – Squalene, Fish/beef – Collagen, Fish – Elastin, Fish scales - Keratin Guanine and Animal Hair.  </span></p>
                           </div>
                       </div>
                       </div>

                 <div className="container">
                      
                     

                        <div className="row row_frem_makenew2gif">
                            <div className="col-md-2">
                            <div className="row_frem_makenew2gifim">
                                <img src={ColorMatte1} className="img-responsive"  alt="..."/>
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="row_frem_makenew2gifim">
                            <img src={ColorMatte2} className="img-responsive"  alt="..."/>
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="row_frem_makenew2gifim">
                            <img src={ColorMatte3} className="img-responsive"  alt="..."/>
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="row_frem_makenew2gifim">
                            <img src={ColorMatte4} className="img-responsive"  alt="..."/>
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="row_frem_makenew2gifim">
                            <img src={ColorMatte5} className="img-responsive"  alt="..."/>
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="row_frem_makenew2gifim">
                            <img src={ColorMatte6} className="img-responsive"  alt="..."/>
                            </div>
                            </div>
                        </div>
                      
                      

                        </div>
                  
                  

                     <div className="makeup_bottom_slider_section">
                     <div className="container">
                     <div className="makeup_bottom_slider_section-middle">
                         <h3>Explore Our Offerings</h3>  
                                <MakeupSlider/>
                     </div>
                      </div>
                     </div>   

                      <div className="container">
                            <div className="lipsp-makeuppage">
                                <p><span className="Fid">W</span>e manufacture <span className="Fid2">lipsticks, liquid lipsticks, matte lipstick, lip gloss, lip balm,tinted lip balm,  mascara, eye brow pencil, eyeliner, eye shadow, foundation, tinted moisturizer, lip liner, concealer, color corrector, blush, loose powder, compressed powder, bronzer, highlighter and more interesting products for each one of you. </span></p>
                            </div>
                      </div> 

                       <div className="Marquee_Makeup-page">   
                       <Marquee pauseOnHover={""} gradientColor={0,0,0} speed={90}>
                       
                        
                        <div className="Marquee_main_image_framemake">
                            <img src={M9} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="Marquee_main_image_framemake">
                            <img src={M7} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="Marquee_main_image_framemake">
                            <img src={M1} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="Marquee_main_image_framemake">
                            <img src={M3} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="Marquee_main_image_framemake">
                            <img src={M4} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="Marquee_main_image_framemake">
                            <img src={M5} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="Marquee_main_image_framemake">
                            <img src={M6} className="img-responsive"  alt="..."/>
                        </div>
                        <div className="Marquee_main_image_framemake">
                            <img src={M2} className="img-responsive"  alt="..."/>
                        </div>
                        </Marquee>

                       </div>
               

            </div>
        );
    }
}

export default MakeupNew;