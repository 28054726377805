import React, { Component } from "react";
import {Helmet} from "react-helmet";


export default class Career extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    
    return <div>
      <Helmet>
      <meta charset="utf-8" />
	  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />     
	<meta name="description" content="Vert laboratoire is an urban & innovative endeavor to produce, manufacture high quality , organic ingredients that are sustainably sourced and carefully crafted for a competitive price & a truly customized premium product line." />
	<title>Career of VertLaboratoirecosmetics organic natural Manufacturer India  </title>  
  
      </Helmet>
   <section id="banner-frame">
      <img src={"https://ik.imagekit.io/deepak/career_EF0yhOgA-s.jpg"} className="responsive" />
  </section>
  <section class="hotel-amenities-top-middle">
      <div class="container">
	     <div class="headeingamd">
			<h3>Career</h3> 
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" />
		</div>
		<div className="pa-tag-heading">
		    <p>If you feel you have knowledge and the skill for this position, Please submit  your resume for consideration.</p>
            <p> Send your resume to : <a href="mailto:wecare@verthpc.com"> wecare@verthpc.com </a> </p>

<p><b> Positions open : -<br/> *Internship <br/>*Purchase Supervisor<br/>*R&amp;D Executive <br/>*Regulatory Associate </b></p>
<p>VértLaboratoire is an urban &amp; innovative endeavour to manufacture high quality natural organic products that are made with ingredients, sourced sustainably and carefully crafted for a competitive price &amp; a truly personalized premium product line. Our Clean Beauty contemporary creations are rooted in nature with plant-derived raw material and enriching essential oils that offer an experience of synesthesia from head to toe.</p>
		</div>
	  </div>
</section>
    </div>;
  }
}
