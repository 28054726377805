import React, { Component } from 'react';

class Provenience extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="pre_main_frame">
                 <div className="pre_main_top_img">
                         <img className="img-responsive" src={"https://ik.imagekit.io/deepak/pre_ZQRQqIeEfy.jpg"}  />
                 </div>
                 <div className="container middle_main_pre_frame">
                        <h2>Provenance</h2>
                        <div class="bordern"></div>
                        <p>The province of  ingredients is equally important for a great product. And we have utmost care in sourcing the same through fair trade and we have developed  A huge vender base For the  supply of raw materials used in the products .Be it oils butters, Waxes spices, herbs, nuts etc.</p>
                        <p>We follow FDA protocols in sourcing the same and every batch of raw materials goes through a rigorous Lab testing before we accept the material for the production.</p>
                        <p>And We take immense pride in sharing that apart from being a FDA complaint  green factory </p>
                        <p>Also we are certificated by......</p>
                 </div>
                 <div className="footer_pre_frame">
                         <img className="img-responsive_al" src={"https://ik.imagekit.io/deepak/pre-2_r1czUDLOO.jpg"}  />       
                 </div>
            </div>
        );
    }
}

export default Provenience;