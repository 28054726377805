import React, { Component } from 'react';
import {Helmet} from "react-helmet";


class TreatSunburnOrganically extends Component {
  componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (   
            <div className="Pimples-page BakuchiolBlog-page_cont tretback treatsunrn">
               <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="Treat Sunburn Organically" />
	<title> Treat Sunburn Organically</title>  
	</Helmet>
            <div className="container">
                 
                  <div className="Image_frame_bakuchiol">
                  <h1>TREAT SUNBURN ORGANICALLY </h1>
                  <p>You head to your favorite vacation with warm weather and sea breeze and come back home with a good tan?? Oh maybe you forgot to apply sunscreen or was saving your money and didn’t applied enough and now your skin is tinged red. #sunburnt</p>
                  <img src={"https://ik.imagekit.io/deepak/Laboratoire/treat_oNjjdFIkq.jpg?updatedAt=1629888751401"} className="img-responsive"  alt="..."/>
                  
                  </div>
                  </div>
                  <div className="container new">
                
                 <div className="bottom_cont_BakuchiolBlog">
                     <h4><i>What is Sunburn? </i> </h4>
                     <p>When your skin is over-exposed to the sun, it responds with sunburn – discomfort, redness, pain, blisters and other problems suggesting damaged skin cells. Press the sunburnt skin, and you will observe it turn white, release and you will watch it retract to red. Plus severe skin damage can also lead to skin cancer. </p>
                  </div>
                <div className="bottom_cont_BakuchiolBlog">
                     <h4><i>Causes of Sunburn??  </i> </h4>
                     <p>Sunburn is the pay-off for sloppiness. You remembered to carry your favorite lip shade and missed the very important products from your vanity (Sunscreen). Despite warnings about sun damage, we tend to carelessly subject our skin to the sun’s UV rays and end up facing undesired consequences. Let’s have a closer look at what spurs the villainous sunburn.  </p>
                     <p>So, the sun emits three UV rays – UVA, UVB and UVC. Now, UVA and UVB penetrates your skin. What goes on below the skin surface? The rays alter your DNA and fuel premature aging. To fight the harmful UV rays, the body’s natural defense mechanism sets off, resulting in a toxic reaction – Sunburn. </p>
                  </div>      
                 <div className="bottom_cont_BakuchiolBlog">
                      <h4><i>NATURAL WAYS TO TREAT SUNBURN</i></h4>
                    <ul>
                       <li> <span><b>Coconut Oil </b> - Coconut oil for sunburn treatment, its antiseptic and anti-inflammatory properties help cool and soothe sunburned skin faster. Coconut oil help moisturize sunburned skin and help minimize itching and peeling. For skin only use organic, virgin coconut oil that’s been expeller pressed. Rub some coconut oil on palms and apply to affected skin. Let it stay until it dries completely.   </span></li>
                       <li> <span><b> Chamomile Oil  </b> – The anti-inflammatory properties of chamomile oil help reduce inflammation and relieve sunburn. This sunburn remedy is best known for its calming effect while easing out the pain and considerably reducing healing time. Add two drops of chamomile oil to one tablespoon of coconut oil. Mix well and apply on the affected area, let it stay until it dries completely.  </span></li>
                       <li> <span><b> Aloe Vera  </b> Aloe Vera identified as ‘Burnt Plant’ owing to its quick healing properties in an age-old sunburn remedy. An inexpensive yet effective sunburn treatment at home. Helps to fade sunburns faster while keeping skin moisturized. Take some fresh aloe Vera from your garden and whisk the gel using a stirrer for easy application. Apply on the affected area and let it stay for 20 min. Rinse off with water and pat dry.  </span></li>
                       <li> <span><b> Green Tea  </b> – When it comes to sunburn relief, green tea does the trick! Polyphenols, Tannic Acid and Theo bromine in green tea speed up the treatment of sunburn. Topical application of green tea on skin soothes inflammation and relieves damaged skin.  </span></li>
                       <li> <span><b> Ice Pack  </b> – Your sunburn solution is right there in your fridge. Ice pack numbs the affected area to reduce inflammation faster and gradually relieve sunburn. The cooling effect of ice can dramatically heal the burn faster.  </span></li>
                    </ul>
                     </div>
                 
                  
           </div>
      </div>  
        );
    }
}

export default TreatSunburnOrganically;