import React, { Component } from 'react';
import Summer from '../lab/blog/Summer.jpg';
import {Helmet} from "react-helmet";

class TipSkin extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane Tweaks skin">
            <Helmet>
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="robots" content="index, follow" />   
<meta name="description" content="Tips to protect your skin this summer " />
<title> Tips to protect your skin this summer </title>  
</Helmet>
           
     <div className="container">
     <div className="Heading_tu">
                 <h1> TIPS TO PROTECT YOUR SKIN THIS SUMMER  </h1>  
         </div>
     <div className="Image_frame">
                <img src={Summer} className="img-responsive"  alt="..."/>
        </div>
             <div className="Image_frame-middle">
             <div className="Heading_tu">
                 <p><span>S</span>un is more intense in the summer, which means that exposure to UV rays is greater. While sunburn is a clear sign that UV radiation has damaged the DNA in the skin cells, the damage to your skin actually occurs as soon as you go under the sun. </p>
                 <p>When sunlight comes into contact with our skin, it damages our skin by stripping of barriers of lipids, causing inflammation and stimulation collagen-destructing enzymes. This ages our skin prematurely in the form of brown spots, coarse skin and wrinkles. </p>
                 <p>Getting sunburned just once every 2 years can triple your risk of melanoma skin cancer. However, even cumulative UV exposure will increase your risk of skin cancer. That’s why it’s so important to follow summer skin care tips to protect your skin and reduce damage caused by UV exposure and especially during this time of the year. </p>  
              </div>
             <div className="Heading_tu">
                 <ul>
                     <li>Spend less time in sun</li>
                 </ul>
                <p>The longer you are out in the sun, the more UV rays you will receive. Reduce your risk of skin damage by spending less time under the sun. Walk under shaded areas and minimize your time outside during the sun’s peak hours (10 am to 4pm when UV radiation is at its greatest. </p>
                </div>
             <div className="Heading_tu">
                 <ul>
                     <li>Protect your skin with sunscreen  </li>
                 </ul>
                 <p>
                 Sunscreen has been proven to actually cause cancer. While some articles says so, but we would encourage you to do your own research. As we are not encouraging you not to use sunscreen. Any skin care product that you apply is absorbed into your body through your skin. So if you do use a sunscreen make sure that is natural, toxic-free and chemical free. 
                 </p>
                 </div>
                 <div className="Heading_tu">
                 <ul>
                     <li> Wear protective clothing </li>
                 </ul>
                <p>Cover up exposed area of skin with hats, sunglasses and long sleeved clothes and pants before going outdoors. The more skin you can cover, the better you can protect your skin from the sun.  </p>
                </div>
                <div className="Heading_tu">
                 <ul>
                     <li> Use sunscreen lip balm  </li>
                 </ul>
               <p>We often forget to protect one of the most exposed and sensitive parts of the body- the lip. Lip cancer is rarely heard of but more common than we think, and sunscreen is one of its major risk factors. Regular lip balm won’t stop UV rays from reaching your lips and may even focus UV rays on your lips. Protect your lip using SPF-fortified, organic lip balm that’s toxic-free and chemical free. It’ll also prevent cracked lips and keep your pout hydrated. Don’t forget to re-apply lip balm regularly and after every meal or drink. </p>
               </div>
               <div className="Heading_tu">
                 <ul>
                     <li>Don’t forget these typically missed spots </li>
                 </ul>
              <p>If you apply sunscreen, we tend to forget these areas, neck, ears, scalp, feet and eyelids because we can’t see them without a mirror. Wearing sunglasses to protect the eyes and the delicate skin around the eyes. If you are going to be exposed to the sun for longer periods of time, remember to apply organic and natural sunscreen to the tops of your hands and feet, and the front and back of your neck and ears.  </p>
               </div>
               <div className="Heading_tu">
                 <ul>
                     <li> Stay Hydrated </li>
                 </ul>
              <p>
                     Dehydrated skin is more vulnerable to damage, so it’s important to stay hydrated. Drinking enough water keeps skin hydrated and also assists I detoxification. While we all need sun exposure daily it’s important not to burn your skin. UV exposure is the greatest during summers, it’s important to keep in mind that UV rays still reach the Earth every day regardless of the season. So follow these summer skincare tips not only during summers but all year round to stay sun-safe and protected. 
              </p>         
               </div>
     </div>
     </div>
     </div>
        );
    }
}

export default TipSkin;