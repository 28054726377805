import React, { Component } from 'react';

class HeaderBG extends Component {
    render() {
        return (
            <div className="header_bg_new">  </div>
        );
    } 
}

export default HeaderBG;