import React, { Component } from 'react';
import PexelsPixabay from '../lab/blog/PexelsPixabay.jpg';
import {Helmet} from "react-helmet";



class TheDeadliestIngredientSqualane extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() { 
        return (
            <div className="TheDeadliestIngredientSqualane">
                   <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="The deadliest ingredient: squalane" />
	<title> The deadliest ingredient: squalane </title>  
	</Helmet>
                    <div className="Image_frame">
                       <img src={PexelsPixabay} className="img-responsive"  alt="..."/>
                    </div>
            <div className="container">
            
                    <div className="Image_frame-middle">
                    <div className="Heading_tu">
                        <h1>THE DEADLIEST INGREDIENT: SQUALANE</h1>
                        <p>2.7 million Sharks are captured and killed for their livers each year. The primary culprit
behind this inhumane hunt driving the extinction of dangerous and vulnerable deep
–sea species? The cosmetics industry.</p>
<p>Sharks liver contain an oil, known as squalene that is highly regarded for its moisturizing
and restorative properties. Squalene (and its derivative, squalane) increases the spread
ability and absorption of creams and lotions and has been proven to prevent moisture
loss, diminish the appearance of fine lines, and help in the prevention of wrinkles. These
qualities make it a highly sought after ingredients, especially for high – end facial
cosmetics.</p>
<p>As a favored emollient for sunscreen, foundation, face moisturizer, lipstick, eye makeup
and many other products, squalene has found its way into every corner of the personal
care industry – but at a huge, deadly cost of which many consumers remain unaware. </p>
                    </div>
                    <div className="Heading_tu">
                        <h4>WHY SHARKS?</h4>
                        <p>Most fish have a gas- filled organ, known as a swim bladder that helps them maintain
buoyancy in the ocean. Deep – sea sharks (those living in oceans depths of 300 to
1,500 meters), however, do not have a swim bladder. Instead, they possess an oily liver
that allows them to achieve neutral buoyancy without having to expend a lot of energy
(the high concentration of oil – a whapping 96% of which is squalene, specially – makes
the shark’s liver less dense than water). Depending on the species, a shark’s liver can
comprise up to 20% of its body weight- that’s a lot of oil.</p>
<p>Though squalene exists elsewhere in nature – predominantly in olives, amaranth seed,
sugarcane, rice bran and wheat gram – it does so in low concentration. In fact, plant
alternatives of the coveted oil are so much less rich in squalene than shark livers that flora
–sourced squalene cost about more than squalene from Sharks liver. These two factors
–price and potency – makes sharks squalene the most desired source in the market, and
act as a driving force behind the continued practice of capturing and killing millions of
sharks every year for their livers. </p>
                    </div>
            </div>
            </div>
            </div>
        );
    }
}

export default TheDeadliestIngredientSqualane;