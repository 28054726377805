import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Branding1 from '../lab/Branding1.mp4';
import Branding2 from '../lab/Branding2.jpg';
import Branding3 from '../lab/Branding3.jpg';
import Branding4 from '../lab/Branding4.jpg';
import Branding5 from '../lab/Branding5.mp4';
import Branding6 from '../lab/Branding6.mp4';
import Branding7 from '../lab/Branding7.mp4';
import Branding8 from '../lab/Branding8.jpg';
import Branding9 from '../lab/Branding9.jpg';
import Branding10 from '../lab/Branding10.jpg';
import Branding11 from '../lab/Branding11.jpg';

class Branding extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="branding_new_page">
               <div className="bodycareNewpageVideo">         
                        <video  width="100%"  playsInline
                        autoPlay
                        loop
                        muted>
                        <source src={"https://ik.imagekit.io/deepak/Branding1_IJLBdJ8Yg.mp4?updatedAt=1681209613227"} type="video/mp4" />
                        </video>
                     </div>
                    <div className="brandingvideobottom">
                        <div className="container">
                             <p>We offer a huge gamut of Branding Services under one roof. We help our clients with logo design, packaging design ,website  development , Brand visual ID  ,Audio identity, Product Shoot .</p>
                        </div>
                    </div>
                <div className="container">
                    <div className="row row_branding_top">
                        <div className="col-md-8">
                           <div className="branding_new_pae-left">
                              <h3>SUSTAINABLE PACKAGING</h3>
                             <p>Given the current situation on Landfills, Sea Animals, Sea water Contamination and Polluted Air. We take extra care to not suffocate the environment any further. This is a conscious choice that we exercise via different routes since 2011.Just to mention a few we use Glass, Aluminium, Stainless Steel, Wood and many other recyclable options to develop a mesmerizing brand Positioning.  </p>
                             </div>       
                        </div>
                        <div className="col-md-4">
                           <img  src={Branding2} class="img-responsive"/>     
                           <div className="brand-font-number one">
                               <p><span>d</span>1</p>
                            </div>                          
                        </div>
                        
                    </div>
                   
                    <div className="row row_branding_top">
                        <div className="col-md-8">
                           <div className="branding_new_pae-left">
                              <h3>DESIGNING</h3>
                             <p>The authenticity, relevance and romance of your brand is your story and we take the onus of telling it compellingly. Our vision commences where the customer's ends,presenting superior brand experiences and building productive conversations with the customer. We activate where the customer exists, introducing the web, social, print and environmental expressions of your brand.</p>
                             </div>       
                        </div>
                        <div className="col-md-4">
                           <img  src={Branding3} class="img-responsive"/>  
                           <div className="brand-font-number tw">
                               <p><span>d</span>2</p>
                            </div>                              
                        </div>
                        
                    </div>
                    <div className="row row_branding_top">
                        <div className="col-md-8">
                           <div className="branding_new_pae-left">
                              <h3>CREATIVE SERVICES</h3>
                             <p>Innovation is what we believe in.
From trade shows to showrooms,
campaign designs to
execution-and everything in between,
we ignite the spark of brand growth.
Our innate innovative and specialized approach,
stands your creative apart,
driving visibility and business action.</p>
                             </div>       
                        </div>
                        <div className="col-md-4">
                           <img  src={Branding4} class="img-responsive"/>   
                           <div className="brand-font-number 3">
                               <p><span>d</span>3</p>
                            </div>                             
                        </div>
                        
                    </div>
                    <div className="row row_branding_top">
                        <div className="col-md-8">
                           <div className="branding_new_pae-left">
                              <h3>VISUAL ID</h3>
                             <p>We don't just build and refresh brands.
We adopt your brand to build,
refresh and nourish it with a dash of creativity,
persistence, heart and soul such that
Your brand presence
is blazoned and sown in the consumer's mind.

</p>  
                             </div>       
                        </div>
                        <div className="col-md-4">
                            <div className="bodycareNewpageVideo-rightvideo"> 
                                <video  width="100%"  playsInline
                                    autoPlay
                                    loop
                                    muted>
                                    <source src={"https://ik.imagekit.io/deepak/Branding5_3xslH5K5s.mp4?updatedAt=1681209629769"} type="video/mp4" />
                                </video>  
                            </div>  
                            <div className="brand-font-number 5">
                               <p><span>d</span>4</p>
                            </div>                             
                        </div>
                        
                    </div>
                    <div className="row row_branding_top">
                        <div className="col-md-8">
                           <div className="branding_new_pae-left">
                              <h3>BRAND INTERACTION</h3>
                             <p>Your brand stands as an identity and hence, it speaks. Interactive
channels propel your brand towards evolving, multi dimensional relationship building.
We bridge the gap between consumers and the brand, built with fine content.
Our creative intelligence extends to websites, mobile, social and unique campaigns,
so your brand experience unfolds with zest across all digital mediums.</p>
                             </div>       
                        </div>
                        <div className="col-md-4">
                        <div className="bodycareNewpageVideo-rightvideo">
                                 
                                <video  width="100%"  playsInline
                                    autoPlay
                                    loop
                                    muted>
                                    <source src={"https://ik.imagekit.io/deepak/Branding6_4Oz6-lyfJ.mp4?updatedAt=1681209630092"} type="video/mp4" />
                                </video>   
                            </div>
                            <div className="brand-font-number 5">
                               <p><span>d</span>5</p>
                            </div>                               
                        </div>
                        
                    </div>

                    <div className="row row_branding_top">
                        <div className="col-md-8">
                           <div className="branding_new_pae-left">
                              <h3>AUDIO IDENTITY</h3>
                            <p>How does your brand sound? Does it resonate with the market?
That's where our internal brand identity and external advertising
expertise intervenes.
Audio Identity exhales the holistic expression of your brand and our insights
and strategies add a layer of brand equity to the way your brand sounds.</p>
                            </div>       
                        </div>
                        <div className="col-md-4">
                        <div className="bodycareNewpageVideo-rightvideo">
                               
                                <video  width="100%"  playsInline
                                    autoPlay
                                    loop
                                    muted>
                                    <source src={"https://ik.imagekit.io/deepak/Branding7_f2FJ0T8uUR.mp4?updatedAt=1681209633303"} type="video/mp4" />
                                </video>   
                            </div>  
                            <div className="brand-font-number 6">
                               <p><span>d</span>6</p>
                            </div>                             
                        </div>
                        
                    </div>
                    <div className="row row_branding_top">
                        <div className="col-md-12 bdex">
                               <h4>BRAND EXPERIENCE</h4>
                               <p>Brand storytelling is how you convey authenticity, relevance and romance of your brand. Our design and digital services are all about activating superior brand experiences and building meaningful conversations with your customers. We activate where the customer exists: everywhere, from web, social, print,Retail Merchandise,store experience to environmental expressions of your brand.

</p>
                        </div>
                    </div>
                     

                </div>


               <div className="branding-innvotivemain">
                    <div className="container">
                          <div className="row fisstsect">
                              <div className="col-md-7">
                                   <div className="left-content-frame">
                                         <p>INNOVATIVE BEAUTY CARE<br/>
PRODUCTS FORMULATED<br/>
TO THE HIGHEST STANDARDS,<br/>
PACKAGED WITH YOUR BRAND AND LABEL,<br/>
AS PER YOUR PREFERENCE.</p>
<p  className="Bring-center-margin">Bring Your Green Creation to Life with Our Wide Range of Recycled Packaging Options</p>
                              
                                   </div>
                              </div>
                              <div className="col-md-5">
                              <div className="left-content-frame-right">
                                   <img  src={Branding8} class="img-responsive"/>    
                              </div>
                              </div>
                             
                          </div>
                          <div className="row fisstsect second">
                          <div className="col-md-5">
                              <div className="left-content-frame-right">
                                   <img  src={Branding9} class="img-responsive"/>    
                              </div>
                              </div>
                              <div className="col-md-7">
                                   <div className="left-content-frame">
                                         <p>Simply select and start designing with our label design and packaging
team who ensure to deliver world className expertise with each
eco-conscious curation. Customize from shape of the bottle, color,
materials that complements your hotel property's brand personality
perfectly.</p>
                                   </div>
                              </div>
                             
                             
                          </div>
                    </div>
                </div>


                <div className="bottomreflesh-brand">
                      <div className="container">
                          <div className="row bottomreflesh-brand-first">
                                <div className="col-md-6">
                                <img  src={Branding10} class="img-responsive"/> 
                                </div>
                                <div className="col-md-6">
                                    <div className="bottomreflesh-brand-first-right">
                                        <p>Reflect your brand ideas with our label design team that efficaciously translates & executes your desired designs.</p>
                                        <p>Personalize one of our existing amenity collection with your logo or develop a fully customized formulation with our professional creative team.</p>
                                    </div>
                                    
                                </div>
                           </div>
                           <div className="row bottomreflesh-brand-first nd new">
                                
                                <div className="col-md-6 desktopbrand">
                                    <div className="bottomreflesh-brand-first-right">
                                       <p>Our R&D, Logo Design, Creative Visualizing and Marketing Teams Carefully
take in Scrutiny all Details to Innovate a Holistic Brand Pathway that Crafts the
Clients' core Brand Story.</p>
                              </div>
                                </div>
                                <div className="col-md-6">
                                <img  src={Branding11} class="img-responsive"/> 
                                </div>
                                <div className="col-md-6 mobilebrand">
                                    <div className="bottomreflesh-brand-first-right">
                                       <p>Our R&D, Logo Design, Creative Visualizing and Marketing Teams Carefully
take in Scrutiny all Details to Innovate a Holistic Brand Pathway that Crafts the
Clients' core Brand Story.</p>
                                         </div>
                                    
                                </div>
                           </div>
                      </div>
                </div>


            </div>
        );
    }
}

export default Branding;