import React, { Component } from 'react';
import CORKI from '../lab/blog/CORK-IMG.jpg';
import {Helmet} from "react-helmet";

class CORK extends Component {
    componentDidMount() {
		window.scrollTo(0, 0) 
	  }
    render() {
        return ( 
            <div className="TheDeadliestIngredientSqualane banish skin-exfoliation SEBUM Lanolin-Truth">
            <Helmet>    
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="index, follow" />   
                <meta name="description" content="Can Cork Save the Beauty Industry Carbon Footprints?? " />
                <title> Can Cork Save the Beauty Industry Carbon Footprints?? </title>  
            </Helmet>
            
                <div className="Image_frame">
                   <img src={CORKI} className="img-responsive"  alt="..."/>
                </div>
        <div className="container">
        <div className="Heading_tu">   
                    <h1>CAN CORK SAVE THE BEAUTY INDUSTRY CARBON FOOTPRINTS??</h1>
            </div>
                <div className="Image_frame-middle">
                <div className="Heading_tu">   
                   <p>Whenever you think of a cork, if you do, you probably think of dartboards or wine stoppers. But cork jars and compacts? Those are new. They don’t just exactly glisten in a shelf, but some sustainability experts are banking on cork to help shrink the beauty industry’s carbon footprint. FYI – in 2018 almost 7.9 billion units of rigid plastic were created for beauty and personal care products in the U.S. </p>
                   <p>Cork offers a renewable way forward: it is natural and legitimately biodegradable. Cork is harvested from the cork tree (quercussuber) found in the western Mediterranean and along the Atlantic coast, in seven countries including Portugal, Spain, France and Morocco. And cork can be cut from layer of bark without harming tress. Much as a shorn sheep regrows wool, cork regenerates-through a lot slower (a 25 year old cork oak tree can be responsibly stripped of its cork once every nine to 12 years.</p>
                   <p>The cork cell is about 90 percent air, making it light and insulating to the point of being virtually nonporous, which is why cork seals wine in bottles and why it is capable of packaging serums without sucking up precious product of letting it leak. </p>
                   <p>But the most persuasive argument for cork packaging may be this: Harvesting cork forces the tress to remanufacture its bark, and therefore to use photosynthesis and carbon dioxide from the air, which helps to mitigate greenhouse gases and fight climate change. On average, cork tress live between 150 to 200 years, but some may be several centuries old. Miraculously, cork tress are not highly combustible. The cork bark protects other parts of the tree, enabling them to sequester carbon dioxide even when wildfires decimate a forest. </p>
                   <p>Moreover, cork pots are currently available in U.K, for storing shampoo bars, is carbon-negative- harvesting the cork removes more carbon dioxide from the air than is emitted in producing the pot. This is just one of the big advantages cork has over other sustainable packaging solutions. Cork is far lighter than glass, which leaves behind a sizable carbon footprint in shipping. A cork can be up cycled. By now you’re probably wondering: Why aren’t all the lipsticks packaged in cork? Well, fungi van grow on cork and might make a product go worse. </p>
                   <p>But, it’s effective for single –use purpose, like sealing wine, after it’s used, cork expands. Companies are developing new blends of cork and other biodegradable materials. More and more brands are interested in developing such packaging for their products and help in decreasing the carbon foot print and help the world be a better place. </p>
                </div>  
                

                     
        </div>
        </div>
        </div>   
        );
    }
}

export default CORK;