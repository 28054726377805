import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";


export default class HotelandSpaAmenities extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
  render() {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay:true,
	  };
    return <div>
		 <Helmet>
		 <meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="Searching for the best hotel Toiletries & amenitiesbeauty Spa productsmanufacturers? Choose VertLaboratoire, For best solution. " />
	<title> Luxury hotel Toiletries & amenities | Natural organic Spa Range | Body wrap Scrub Oils Manufacturers India </title>  
		 </Helmet> 
		   <div className="banner-frame">
            <Slider {...settings}>
              <div>
              <img src={"https://ik.imagekit.io/deepak/hotel-amenities-banner_wt0XltiNF.jpg"} class="img-responsive"  alt="..."/>
              </div>
              <div>
              <img src={"https://ik.imagekit.io/deepak/Hotel-Amenities-Banner2_fQ_MDEK7d.jpg"} class="img-responsive"  alt="..."/>
              </div>
            </Slider>
			</div>
			<section className="hotel-amenities-top-middle">
      <div className="container">
	     <div className="headeingamd">
			<h3>Hotel Amenities</h3> 
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
		</div>
		<div className="pa-tag-heading">
		    <p>
			Vért provides the widest portfolio of International Amenity brands and serves to
majority of International Luxury Hotel &amp; Spa chains. Our premium quality Guest Toiletries are developed with all natural ingredients that are sustainably sourced and organically
treated using traditional techniques to give you only the best of botanical beauty.
Select from a variety of natural actives, pure essential oils, exquisite fragrances &amp; 100% paraben-free preservatives, ethically crafted by a soot-free process to propel the green
footprint. 
</p>
		</div>
	  </div>
</section>
<section className="sect-id-naormal">
<div className="container">
<div className="headeingamd-main">
<div className="headeingamd">
			<h3>Category</h3> 
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
		</div>
		</div>
		<div className="hotel-cate-normaol-main">
			<div className="row">
		   <div className="col-md-4">
		       <div className="hotel-cate-normaol-main-left">
					<ul>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"}/></span> Shampoo	 </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Conditioner </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Bubble Bath	 </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Aftersun Lotion </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Face Cream</li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Shower Gel </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Sunscreen </li>
					   
					</ul>
					
			   </div>
		   </div>
		   <div className="col-md-4">
		       <div className="hotel-cate-normaol-main-right">
			      
			       <ul>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Face Wash </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Hair Oil </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Hand Cream </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Solid Perfume </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Body Lotion </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Insect Repellent</li>		  
					</ul>
					
			   </div>
		   </div>
		    <div className="col-md-4">
		       <div className="hotel-cate-normaol-main-right">
			      
			       <ul>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Lip Balm </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Body Scrub </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Bath Bomb </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Body Oil </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span> Bathing Salt </li>
					   <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span>Shaving Cream</li>
                       <li><span><img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} /></span>Powder Face Cleansers</li>					   
					</ul>
					
			   </div>
		   </div>
		 </div>
		</div>
		
		<div className="clear"></div>
		
		<div className="hotel-last-img">
			<img src={"https://ik.imagekit.io/deepak/hoter-bott_4esBPoNeFy.jpg"} />
		</div>
		
		
</div>
</section>
<section className="hotel-amenities-top-middle">
      <div className="container">
	     <div className="headeingamd">
			<h3>Spa Amenities</h3> 
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
		</div>
		<div className="pa-tag-heading">
		    <p>Our luxurious &amp; exotic treatments for spas reflect our passion to combine the true principles of botanical healing with premium quality ingredients to elevate your wellness routine and give you a serene spa glow.</p>
			<p>Passionately focused on purity and sustainability, our products are derived from authentic &amp; all organic remedies, made in India, completely natural and free of artificial colors and
synthetic fragrances for a rejuvenating  clean &amp; green spa experience.</p>

		</div>
	  </div>
</section>
<section className="hotel-amenities-top-middle new">
      <div className="container">
	     <div className="headeingamd">
			<h3>Tailor Made Spa Amenities</h3> 
			<img src={"https://ik.imagekit.io/deepak/download_61xc7DSWk.png"} className="img-responsive" alt="..."/>
		</div>
		<div className="pa-tag-heading na">
		  <h5>State-of-the-art Formulas Featuring:</h5>
		    <p>First-to-market naturally derived ingredients &amp; custom-made formulas.<br/>
Certified-Organic ingredients &amp; delivery vehicle technologies.<br/>
An in house- R&amp;D team that listens to your unique ideas and meets your custom requirements.<br/>
Hundreds of high performance stock formulas tailored to treat anyone to a luxurious spa experience.</p>

		</div>
	  </div>
</section>
    </div>;
  }
}
