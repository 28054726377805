import React, { Component } from 'react';
import Exfo from '../lab/blog/Exfo.jpg';
import {Helmet} from "react-helmet";

class SkinExfoliation extends Component {
    componentDidMount() {
		window.scrollTo(0, 0) 
	  }
    render() {
        return (
            <div className="TheDeadliestIngredientSqualane banish skin-exfoliation">
                <Helmet>    
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="robots" content="index, follow" />   
                    <meta name="description" content="Banish Wrinkles Naturally" />
                    <title> Skin Exfoliation – What, How and Why?? </title>  
                </Helmet>
                    <div className="Image_frame">
                       <img src={Exfo} className="img-responsive"  alt="..."/>
                    </div>
            <div className="container">
            
                    <div className="Image_frame-middle">
                    <div className="Heading_tu">   
                        <h1>SKIN EXFOLIATION – WHAT, HOW AND WHY??</h1>
                        <p>Washing your skin daily, refreshing with face spray toners multiple times a day or even getting facial massages can sometimes leave you wanting more from your skin. More radiance, glow, smoothness and a better texture is something you can expect by exfoliating your skin once in a while. </p>
                        <p>We use number of facial cleansers available in the market. Even after a lot of efforts, our skin is not at its best and glowing as much as you want if you want it for everyone to use a set of standardized beauty products. The nature of the skin varies from person to person. Your skin might be very oily while your friend’s skin may be dry. </p>
                        <p>Skincare products vary from one person to another based on the nature of the skin. Dry, oily or combination skin, one of the best things that you can do to give your skin a natural glow is an exfoliation. </p>
                    </div> 
                    <div className="Heading_tu">
                        <h4>WHAT IS EXFOLIATION??</h4>
                       <p>It is the process in which the dead skin cells are sloughed off from the surface of your skin. This will obviously make your skin livelier and hence better. Exfoliation is of many different kinds and two types are most common and well known. </p>
                       <p><span>Chemical exfoliation:</span> As the name suggests is the use of chemicals on the skin to break down the surface build up. Usually, chemicals used for this are AHA, BHA, PHA, salicylic acid or enzymes or other similar chemicals that do not harm the skin. Use of bad chemicals can cause problems like pimples, permanent or temporary marks and so. </p>
                       <p><span>Mechanical exfoliation:</span> On the other hand, is physically scrubbing off your skin’s surface using scrub or other exfoliation skincare tools. This includes scrubs which can be used to rub dead cells of your skin, which is usually on the top layer. As there is good knowledge about exfoliation these days, people are trying out new products and scrubs. But the best products that are presently attracting beauty pageants are natural products or scrubs you can find organic scrubs in many places. </p>
                       </div>

                       <div className="Heading_tu">
                        <h4> BENEFITS OF EXFOLIATION- </h4>
                       <p>
                       Improvement of skin texture- exfoliating your skin speeds up the skin renewal process. Your skin will become shinier and softer. The complexion also becomes soft and glowing. On the whole, exfoliation will give better texture for your skin.
                       </p>
                       <p>
                       Slower ageing- as you become old, there are changes in your skin due to the slowing down of the cell rejuvenation process and that is why you start getting wrinkles and start looking old. Exfoliation can slow down the process on your skin.
                       </p>
                       <p>Makes your skin tone more consistent- Some people have dark spots and rough texture and it is frustrating to get it treated. Exfoliating properly and on a regular basis can smoothen the skin and make it better. Over time, the skin starts becoming more uniform and starts looking more radiant. </p>
                       </div>
            </div>
            </div>
            </div>  
        );
    }
}

export default SkinExfoliation;