import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import Summer2 from '../src/lab/blog/Summer2.jpg';
import TweaksBanner2 from '../src/lab/blog/TweaksBanner2.jpg';
import PexelsPixabay2 from '../src/lab/blog/PexelsPixabay2.jpg';
import Exfo2 from '../src/lab/blog/Exfo2.jpg';
import WrinklesNaturally2 from '../src/lab/blog/WrinklesNaturally2.jpg';
import SHAMPOOIMG2 from '../src/lab/blog/SHAMPOOIMG2.jpg';
import Sebumimg2 from '../src/lab/blog/Sebumimg2.jpg';
import Fungusimg2 from '../src/lab/blog/Fungusimg2.jpg';
import Peppermintimg2 from '../src/lab/blog/Peppermintimg2.jpg';
import CORKI from '../src/lab/blog/CORK-IMG2.jpg';
import SHEEPIMG from '../src/lab/blog/SHEEP-IMG2.jpg';
import Coconutimg2 from '../src/lab/blog/Coconutimg2.jpg';
import TEATREEIMG2 from '../src/lab/blog/TEATREEIMG2.jpg';
import Unevenimg2 from '../src/lab/blog/Unevenimg2.jpg';


class blog extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div>
          <Helmet>
			<meta charset="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta name="robots" content="index, follow" />   
			<meta name="description" content="Blog" />
			<title>Blog</title> 
       </Helmet>
                <div className="blog-banner">
                <img src="https://ik.imagekit.io/vert/blog-top-banner_Sy7wmJkr-.jpg" class="img-responsive"/>
                </div>
                <div className="container blog-middle">
                    <div className="itemListCategory">
                      <h1>Our Blogs</h1>   
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/a-non-living-burden">
                                <div className="catItemImage">
                                 <img src="https://ik.imagekit.io/vert/Blog/blog-1_uAUrBrduS.jpg?updatedAt=1629894634801" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> A Non-Living Burden/Terrorist  </h3>
                                <div className="catItemIntroText">
                                 <p>In our plastic filled world, avoiding plastic can be really challenging. Blue planetdocumentary has given a shocking  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/a-non-living-burden">Read more...</a>
                                </div>    
                               
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */} 

                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/Bakuchiol">
                                <div className="catItemImage">
                                 <img src="https://ik.imagekit.io/vert/Blog/blog-2_M2QTo_nJe.jpg?updatedAt=1629894629149" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Bakuchiol </h3>
                                <div className="catItemIntroText">
                                 <p>If you have any idea about skincare, then you know retinol is hugely acclaimed resource for good skin. One can  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a to="/blog/Bakuchiol">Read more...</a>
                                </div>    
                               
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */} 

                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a to="/blog/chemical-ingredients-to-avoid-in-daily-use-products">
                                <div className="catItemImage">
                                 <img src="https://ik.imagekit.io/vert/Blog/blog-3_LNX6ImoZ_.jpg?updatedAt=1629894629419" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Chemical Ingredients to Avoid in Daily Use Products  </h3>
                                <div className="catItemIntroText">
                                 <p>Every one love to take care of their skin and days remain incomplete without them. Even for those who do not particularly use cosmetics ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a to="/blog/chemical-ingredients-to-avoid-in-daily-use-products">Read more...</a>
                                </div>    
                                    
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */} 

                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a to="/blog/face-oils-all-you-need-to-know">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-4_TCYvQ64hrw.jpg?updatedAt=1629894629533" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Face Oils: all you need to Know </h3>
                                <div className="catItemIntroText">
                                 <p>Oil on the face, this thoughts throws you off, we get it. Maybe it’s because the idea of putting oil on, say, your acne-prone face seems certifiably insane ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/face-oils-all-you-need-to-know">Read more...</a>    
                                </div>    
                               
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */} 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/grease-cleanse-guide-home-remedies">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-5_yLC2QLlMC.jpg?updatedAt=1629894629819" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Grease Cleanse Guide: Home Remedies </h3>
                                <div className="catItemIntroText">
                                 <p>If your skincare routine ends at your face, maybe you’ve forgotten your scalp which is skin too. Your scalp needs attention as well ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/grease-cleanse-guide-home-remedies">Read more...</a>
                                </div>    
                               
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */} 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/5-skincare-trends-of-2021">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-6_C5tfPpeHLL.jpg?updatedAt=1629968458115" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> 5 Skincare Trends of 2021 </h3>
                                <div className="catItemIntroText">
                                 <p>2020 – Better it’s left behind. While you experimenteda multi-step routine for a ‘zoom face’ or couldn’t find the desire ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/5-skincare-trends-of-2021">Read more...</a>
                                </div>    
                               
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */}  



                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/how-to-treat-back-acne-organically">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-7_TDxBHY7-8.jpg?updatedAt=1629894631386" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> How to Treat back Acne Organically </h3>
                                <div className="catItemIntroText">
                                 <p>If only we stressed about our back as much as we do about our face, back acne might not have been so common. For those who are not familiar ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/how-to-treat-back-acne-organically">Read more...</a>
                                </div>    
                               
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */}  

                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/how-to-get-rid-of-pimples-on-your-butt-fast">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-8_pF3rnxmEl.jpg?updatedAt=1629894632152" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> How to get rid of Pimples on your Butt – Fast </h3>
                                <div className="catItemIntroText">
                                 <p>When the weather starts to heat up, some people may get stressed over showing more skin – especially if one’s prone to body breakouts ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/how-to-get-rid-of-pimples-on-your-butt-fast">Read more...</a> 
                                </div>    
                               
                            </div>
                            </a>
                            </div>
                        </div> {/* section end 4 */}  


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/treat-sunburn-organically">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-9_fGNowx1SE.jpg?updatedAt=1629894633231" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Treat Sunburn Organically</h3>
                                <div className="catItemIntroText">
                                 <p>You head to your favorite vacation with warm weather and sea breeze and come back home with a good tan?? Oh maybe  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/treat-sunburn-organically">Read more...</a>   
                                </div>    
                               
                            </div>
                            </a> 
                            </div>
                        </div> {/* section end 4 */}  



                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/2021-blush-techniques">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-10_6U9-erT32j.jpg?updatedAt=1629968877599" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> 2021 Blush Techniques </h3>
                                <div className="catItemIntroText">
                                 <p>Are you one of them who love that flush of color on your cheeks and if you thought there was just one way to   ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/2021-blush-techniques">Read more...</a>  
                                </div>    
                               
                            </div>
                            </a> 
                            </div>
                        </div> 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/Why-body-acne-is-happening-more-than-ever">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-11_IuKucmcWo.jpg?updatedAt=1629968878285" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Why body acne is happening more than ever???</h3>
                                <div className="catItemIntroText">
                                 <p>As many people have discovered, that periods of stress can lead to an increase in a stress hormone called cortisol,  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/Why-body-acne-is-happening-more-than-ever">Read more...</a>  
                                </div>    
                               
                            </div>
                            </a>      
                            </div>
                        </div> 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/dark-underarms-treatment">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/blog-13_vgr0zxJcSe.jpg?updatedAt=1629975352756" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Dark Underarms Treatment</h3>
                                <div className="catItemIntroText">
                                 <p>Dark underarms are not unusual. If you are looking for ways to brighten this area of skin that is prone to hyperpigmentation  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/dark-underarms-treatment">Read more...</a>  
                                </div>    
                               
                            </div>
                            </a> 
                            </div>
                        </div> 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/eucalyptus-oil-benefits-for-hair">
                                <div className="catItemImage">
                                   <img src="https://ik.imagekit.io/vert/Blog/blog-12_DwBMLHS1R2.jpg?updatedAt=1629968879222" class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Eucalyptus Oil Benefits for Hair</h3>
                                <div className="catItemIntroText">
                                 <p>If you’re searching for an essential oil to change your dull hair, you can give eucalyptus oil a shot. This oil is  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/eucalyptus-oil-benefits-for-hair">Read more...</a>  
                                </div>    
                               
                            </div>
                            </a> 
                            </div>
                        </div> 



                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/The-deadliest-ingredient-squalane">
                                <div className="catItemImage">
                                   <img src={PexelsPixabay2} class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> The Deadliest Ingredient: Squalane </h3>
                                <div className="catItemIntroText">
                                 <p>2.7 million Sharks are captured and killed for their livers each year. The primary culprit behind this inhumane hunt  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/The-deadliest-ingredient-squalane">Read more...</a>      
                                </div>    
                               
                            </div>
                            </a> 
                            </div>
                        </div> 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/Tips-to-protect-your-skin-this-summer">
                                <div className="catItemImage">
                                   <img src={Summer2} class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> Tips to protect your skin this summer </h3>
                                <div className="catItemIntroText">
                                 <p>Sun is more intense in the summer, which means that exposure to UV rays is greater.While sunburn is a clear sign that UV radiation has damaged  ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/Tips-to-protect-your-skin-this-summer">Read more...</a>
                                </div>    
                               
                            </div>
                            </a> 
                            </div>
                        </div> 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/these-small-tweaks-can-help-save-the-environment">
                                <div className="catItemImage">
                                   <img src={TweaksBanner2} class="img-responsive"/>
                                </div>
                            <div className="blog_bottom_frame">
                                <h3 className="catItemTitle"> These small tweaks can help save the environment </h3>
                                <div className="catItemIntroText">
                                 <p>The beauty industry is a major contributor to worldwide pollution. Luckily, you don’t have to choose ...</p>
                                </div>
                                <div className="catItemReadMore">
                                   <a href="/blog/these-small-tweaks-can-help-save-the-environment">Read more...</a>
                                </div>             
                               
                            </div>
                            </a>     
                            </div>
                        </div> 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/skin-exfoliation-what-how-and-why">
                                <div className="catItemImage">
                                  <img src={Exfo2} class="img-responsive"/>
                                </div>
                                <div className="blog_bottom_frame">
                                    <h3 className="catItemTitle"> Skin Exfoliation – What, How and Why?? </h3>
                                    <div className="catItemIntroText">
                                    <p>Washing your skin daily, refreshing with face spray toners multiple times a day or even getting facial massages  ...</p>
                                    </div>
                                    <div className="catItemReadMore">
                                    <a href="/blog/skin-exfoliation-what-how-and-why">Read more...</a>  
                                    </div>    
                                </div>   
                            </a>                    
                            </div>
                        </div>



                         <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/banish-wrinkles-naturally">
                                <div className="catItemImage">
                                  <img src={WrinklesNaturally2} class="img-responsive"/>
                                </div>
                                <div className="blog_bottom_frame">
                                    <h3 className="catItemTitle"> Banish Wrinkles Naturally </h3>
                                    <div className="catItemIntroText">
                                    <p>Crease are not good to look, be it your clothes or on your face! The onset of wrinkles, probably the one thing  ...</p>
                                    </div>
                                    <div className="catItemReadMore">
                                    <a href="/blog/banish-wrinkles-naturally">Read more...</a>
                                    </div>    
                                </div>   
                            </a>                    
                            </div>
                        </div> 


                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <a href="/blog/Sebum">
                                <div className="catItemImage">
                                  <img src={Sebumimg2} class="img-responsive"/>
                                </div>
                                <div className="blog_bottom_frame">
                                    <h3 className="catItemTitle"> Sebum </h3>
                                    <div className="catItemIntroText">
                                    <p>It’s oily and waxy, secreted from your own skin from head to toe. Though it may not be the most pleasant sounding skin-care term ...</p>
                                    </div>
                                    <div className="catItemReadMore">
                                    <a href="/blog/Sebum">Read more...</a>  
                                    </div>    
                                </div>   
                            </a>                    
                            </div>
                        </div>  

{/* section end 4 

                        <div className="col-md-4">
                            <div className="blog-img-frame">
                            <Link to="/blog/Problem-with-Silicones-Good-Bad-ugly">
                                <div className="catItemImage">
                                  <img src={SHAMPOOIMG2} class="img-responsive"/>
                                </div>
                                <div className="blog_bottom_frame">
                                    <h3 className="catItemTitle"> Problem with Silicones – Good/Bad & Ugly  </h3>
                                    <div className="catItemIntroText">
                                    <p>Do you know there is a high chance you come into contact with silicones on a daily basis?? Most products   ...</p>
                                    </div>
                                    <div className="catItemReadMore">
                                    <Link to="/blog/Problem-with-Silicones-Good-Bad-ugly">Read more...</Link>
                                    </div>    
                                </div>   
                            </Link>                    
                            </div>
                        </div> 
*/}  
          

                <div className="col-md-4">
                    <div className="blog-img-frame">
                    <a href="/blog/is-fungal-acne-real">
                    <div className="catItemImage">
                    <img src={Fungusimg2} class="img-responsive"/>
                    </div>
                    <div className="blog_bottom_frame">
                    <h3 className="catItemTitle"> Is fungal Acne Real?? </h3>
                    <div className="catItemIntroText">
                    <p>If you’ve taken a second to scroll through beauty sides or any other website, chances are high you’ve encountered someone’s fungal ...</p>
                    </div>
                    <div className="catItemReadMore">
                    <a href="/blog/is-fungal-acne-real">Read more...</a>  
                    </div>    
                    </div>   
                    </a>                    
                    </div>
                </div> 


                <div className="col-md-4">
                    <div className="blog-img-frame">
                    <a href="/blog/peppermint-oil-for-skin-benefits">
                    <div className="catItemImage">
                    <img src={Peppermintimg2} class="img-responsive"/>
                    </div>
                    <div className="blog_bottom_frame">
                    <h3 className="catItemTitle"> Peppermint Oil for Skin Benefits </h3>
                    <div className="catItemIntroText">
                    <p> Peppermint oil is an essential oil that is extracted from the leaves of the peppermint plant (menthapiperita). The leaves of peppermint ...</p>
                    </div>
                    <div className="catItemReadMore">
                    <a href="/blog/peppermint-oil-for-skin-benefits">Read more...</a>  
                    </div>    
                    </div>   
                    </a>                    
                    </div>
                </div> 


                <div className="col-md-4">
                    <div className="blog-img-frame">
                    <a href="/blog/lanolin-truth">
                    <div className="catItemImage">
                    <img src={SHEEPIMG} class="img-responsive"/>
                    </div>
                    <div className="blog_bottom_frame">
                    <h3 className="catItemTitle">Lanolin Truth  </h3>
                    <div className="catItemIntroText">
                    <p> You always notice same ingredients over and over again on cosmetics products, one of them is lanolin ...</p>
                    </div>
                    <div className="catItemReadMore">
                    <a href="/blog/lanolin-truth">Read more...</a>    
                    </div>    
                    </div>   
                    </a>                    
                    </div>
                </div> 


                <div className="col-md-4">
                    <div className="blog-img-frame">
                    <a href="/blog/can-cork-save-the-beauty-industry-carbon-footprints">
                    <div className="catItemImage">
                    <img src={CORKI} class="img-responsive"/>
                    </div>
                    <div className="blog_bottom_frame">
                    <h3 className="catItemTitle"> Can Cork Save the Beauty Industry Carbon Footprints?? </h3>
                    <div className="catItemIntroText">
                    <p> Whenever you think of a cork, if you do, you probably think of dartboards or wine stoppers. But cork jars and compacts ...</p>
                    </div>
                    <div className="catItemReadMore">
                    <a href="/blog/can-cork-save-the-beauty-industry-carbon-footprints">Read more...</a>  
                    </div>    
                    </div>   
                    </a>                    
                    </div>
                </div> 


                <div className="col-md-4">
                    <div className="blog-img-frame">
                    <a href="/blog/benefits-of-tea-tree-oil">
                    <div className="catItemImage">
                    <img src={TEATREEIMG2} class="img-responsive"/>
                    </div>
                    <div className="blog_bottom_frame">
                    <h3 className="catItemTitle"> Benefits of tea tree oil </h3>
                    <div className="catItemIntroText">
                    <p> Have a long history with breakouts, you might be familiar with the advantages of using tea tree oil. After all, tea tree oil can help ...</p>
                    </div>
                    <div className="catItemReadMore">
                    <a href="/blog/benefits-of-tea-tree-oil">Read more...</a>  
                    </div>    
                    </div>   
                    </a>                    
                    </div>
                </div> 


                <div className="col-md-4">
                    <div className="blog-img-frame">
                    <a href="/blog/coconut-oil-benefits-for-hair">
                    <div className="catItemImage">
                    <img src={Coconutimg2} class="img-responsive"/>
                    </div>
                    <div className="blog_bottom_frame">
                    <h3 className="catItemTitle"> Coconut oil benefits for hair</h3>
                    <div className="catItemIntroText">
                    <p> The easiest thing to do can sometimes be the most underestimated one, like applying coconut oil on hair. No wonder your grandma ...</p>
                    </div>
                    <div className="catItemReadMore">
                    <a href="/blog/coconut-oil-benefits-for-hair">Read more...</a>    
                    </div>    
                    </div>   
                    </a>                    
                    </div>
                </div> 


                <div className="col-md-4">
                    <div className="blog-img-frame">
                    <a href="/blog/how-to-treat-uneven-skin-tone">
                    <div className="catItemImage">
                    <img src={Unevenimg2} class="img-responsive"/>
                    </div>
                    <div className="blog_bottom_frame">
                    <h3 className="catItemTitle"> How to treat uneven skin-tone </h3>
                    <div className="catItemIntroText">
                    <p> Uneven skin tone is a gradual process and doesn’t just happen overnight. So, if you have uneven skin tone, it means you unfortunately...</p>
                    </div>
                    <div className="catItemReadMore">
                    <a href="/blog/how-to-treat-uneven-skin-tone">Read more...</a>          
                    </div>    
                    </div>   
                    </a>                    
                    </div>
                </div> 



                    
                    </div>
                </div>
            </div>
        );
    }
}

export default blog;  