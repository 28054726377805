import React, { Component } from 'react';
import MobHome4 from '../../src/img/MobHome4.mp4';

class VideoSoundMobile extends Component {
    render() {
        return (
            <div className="container--">
            <div className="video-container_home_paage">   
               <video className="home-page_tom_Mobile" autoPlay loop muted  controls  src={MobHome4}> 
                      Sorry, your browser doesn't support embedded videos.
               </video>
           </div>
          </div>     
        );
    }
}

export default VideoSoundMobile; 