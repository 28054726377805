import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Marquee from "react-fast-marquee"; 
import { Link } from "react-router-dom";
import AccordiOpen from '../src/AccordiOpen';
import TopBanner from '../src/lab/TopBanner.mp4';
import PhonePrivate from '../src/img/PhonePrivate.mp4';
import PhonePrivate2 from '../src/img/PhonePrivate2.mp4';
import menuBottom2 from '../src/lab/menuBottom2.mp4';
import Cruelty1 from '../src/lab/Cruelty1.gif';
import Cruelty2 from '../src/lab/Cruelty2.gif';
import Cruelty3 from '../src/lab/Cruelty3.gif';
import Cruelty4 from '../src/lab/Cruelty4.gif';
import Cruelty5 from '../src/lab/Cruelty5.gif';
import Cruelty6 from '../src/lab/Cruelty6.gif';
import Cruelty7 from '../src/lab/Cruelty7.gif';
import Cruelty8 from '../src/lab/Cruelty8.gif';
import Cruelty9 from '../src/lab/Cruelty9.gif';
import Cruelty10 from '../src/lab/Cruelty10.gif';
import Product1 from '../src/lab/Product1.jpg';
import Product2 from '../src/lab/Product2.jpg';
import Product3 from '../src/lab/Product3.jpg';
import Product4 from '../src/lab/Product4.jpg';
import Product5 from '../src/lab/Product5.jpg';
import Product6 from '../src/lab/Product6.jpg';
import Product7 from '../src/lab/Product7.jpg';
import Product8 from '../src/lab/Product8.jpg';
import Videoss from '../src/lab/Videoss.jpg';
import ManuB3 from '../src/img/ManuB3.jpg';


class PrivateLabeNew extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  } 
    render() {
        return (
            <div className="privel-label-newpage">
                <div className="top-video_privel Desktop">
                    <video autoPlay loop muted poster={Videoss}>
                        <source src={TopBanner} type="video/mp4"/>
                        <source src={TopBanner} type="video/ogg"/>
                    </video>
                </div>
                <div className="top-video_privel Mobile">
                    <video autoPlay loop muted poster={Videoss}>
                        <source src={PhonePrivate} type="video/mp4"/>
                        <source src={PhonePrivate} type="video/ogg"/>
                    </video>
                </div>
                <div className="container">
                <div className="bottom_heading_section">
                     <h5>INNOVATIVE BEAUTY CARE PRODUCTS FORMULATED TO THE HIGHEST STANDARDS,
PACKAGED WITH YOUR BRAND AND LABEL, AS PER YOUR PREFERENCE</h5>
<h1>Private Label</h1>
<p><span>A</span>t vert Laboratoire, we have a variety of ready- made formulas, which have been tested for
years and can be quickly modified in order to infuse them with your brand essence.</p>
                </div>
               
                <div className="cont_frame_prilabel">
                    <p><span className="First_let">W</span>e are decade old company with <span className="highlight">Green Chemistry</span> based brand factory, with  <span className="highlight">Innovation and
Sustainability</span> our key motto. We are propelling <span className="highlight">Green Foot Print </span> over the years and have created
a rich library with time tested
formulations in Skincare, Makeup, Hair Care and Personal Care, ensuring fantastic end- results for
your finished product line. </p>
<p>We are experts in formulating natural and plant based formulations with locally and ethically sourced 
ingredients. We take pride in getting the best and most potent <span className="highlight">Plant Based Raw Materials</span> from local
produce. With sustainability our core, we don’t stop constant research and  imagination to bring the
most creative, unique and plant based products to many skincare and cosmetic brands. </p>
<p>It takes lot of research, dedication, excellence, creativity and passion for personal care and cosmetics
formulation methodology, to bring the type of first-class products to our expanding elite list of clients.
We offer dynamic and limitless formulations, for you to easily  market your product. We believe to
achieve excellent formulations, with no compromise on quality.      
</p>
         </div>       
         </div>
       
                <div className="bottom_image_effect">
                <div className="container--">
                    <p>If you are looking to start a new cosmetic, skincare or personal care brand or want to add<br/>
some new products to your existing brand, we are here to listen to your needs and help you<br/>
create a formula with your desired <span>“Hero”</span> ingredient and develop new fragrances,<br/>
appearance that is truly unique to your brand. </p> 
                </div>
                </div>
                
                <div className="container">

                <div className="Top_last_cont_prilable">
                
                    <p>Our team of expert Research and Development, Chemists and Quality Assurance Professionals know the art of blending <span className="highlight">Green Chemistry</span> with contemporary aesthetics so that we can help you achieve your dream brand at a competitive advantage. At our <span className="highlight">Green Factory</span> we are <span className="highlight">Zero Waste Manufacturing Unit</span> and <span className="highlight">Zero Carbon Foot Print</span> Company.  </p>
               
                </div> 
                </div>

                <div className="Marquee_1">
                    <h3>Our Product PORTFOLIO</h3>
                <Marquee pauseOnHover={"false"} gradientColor={255, 255, 255} speed={90}>   
                  <div className="Marquee_main_image_frame">
                      <a href="/makeup">
                         <img src={Product1} className="img-responsive"  alt="..."/>
                      </a>      
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="#">
                         <img src={Product2} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/facecare">
                         <img src={Product3} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/Haircare">
                         <img src={Product4} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                      <a href="/makeup">
                         <img src={Product1} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="#">
                         <img src={Product2} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/facecare">
                         <img src={Product3} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/Haircare">
                         <img src={Product4} className="img-responsive"  alt="..."/>
                   </a>
                  </div>
               </Marquee>       
               </div>
               <div className="Marquee_2">
                <Marquee pauseOnHover={"false"}  gradientColor={255, 255, 255} speed={70}>   
                  <div className="Marquee_main_image_frame">
                      <a href="/babycare">
                         <img src={Product5} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/Mencare">
                         <img src={Product6} className="img-responsive"  alt="..."/>
                  </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/Bodycare">
                         <img src={Product7} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/hotel-and-spa-amenities">
                         <img src={Product8} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                      <a href="/babycare">
                         <img src={Product5} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/Mencare">
                         <img src={Product6} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/Bodycare">
                         <img src={Product7} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
                  <div className="Marquee_main_image_frame">
                  <a href="/hotel-and-spa-amenities">
                         <img src={Product8} className="img-responsive"  alt="..."/>
                      </a>
                  </div>
               </Marquee>
               </div>

               <div className="container">

                <div className="product_list_pri-lable">
                    <h4 className="proudly_we">WE PROUDLY ARE; </h4>
                    <ul>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                    <img src={Cruelty1} className="img-responsive"  alt="..."/>
                    <h5>CRUELTY FREE <br/>FACILITY</h5>
                    </ScrollAnimation>
                    </li>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      
                    <img src={Cruelty2} className="img-responsive"  alt="..."/>
                    <h5>ECO-FRIENDLY <br/>PRODUCTING</h5>
                    </ScrollAnimation>
                    </li>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      
                    <img src={Cruelty3} className="img-responsive"  alt="..."/>
                    <h5>PARABEN & <br/>SLS FREE</h5>
                    </ScrollAnimation>
                    </li>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      
                    <img src={Cruelty4} className="img-responsive"  alt="..."/>
                    <h5>VEGAN</h5>
                    </ScrollAnimation>
                    </li>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      
                    <img src={Cruelty9} className="img-responsive"  alt="..."/>
                    <h5>ZERO CARBON<br/> FOOT PRINT</h5>      
</ScrollAnimation>
                    </li>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      
                    <img src={Cruelty6} className="img-responsive"  alt="..."/>
                    <h5>ZERO WASTE<br/>
MANUFACTURING<br/>
UNIT</h5>
</ScrollAnimation>
                    </li>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      
                    <img src={Cruelty10} className="img-responsive"  alt="..."/>
                    <h5>REEF SAFE, NO PLASTIC BEEDS</h5>
</ScrollAnimation>
                    </li>
                    <li>
                    <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      
                    <img src={Cruelty8} className="img-responsive"  alt="..."/>
                    <h5>WE USE
RENEWABLE
ENERGY SOURCE
(SOLAR AND WIND)
FOR 
MANUFACTURING 
</h5>
</ScrollAnimation>
                    </li>
                    </ul>
                </div>
                <div className="clear"></div>
            
                <AccordiOpen/>

               
              
               
                </div>
               
                
               <div className="clear"></div>
               <div className="bottom-video_privel Start_now- pvt_lbl_desktop">
                    <a href="/contact">
                  <video autoPlay loop muted>
                        <source src={menuBottom2} type="video/mp4"/>
                        <source src={menuBottom2} type="video/ogg"/>       
                    </video>
                    </a>
                </div>
                <div className="bottom-video_privel Start_now- pvt_lbl_mobile">
                    <a href="/contact">
                  {/*<video autoPlay loop muted>
                        <source src={PhonePrivate2} type="video/mp4"/>
                        <source src={PhonePrivate2} type="video/ogg"/>         
                    </video>*/}
                    <img src={ManuB3} className="img-responsive"  alt="..."/>    
                    </a>
                </div>
               
            </div>
        );
    }
}

export default PrivateLabeNew;