import React, { Component } from 'react';
import Cream1 from '../img/facecream.mp4'; 
import Cream3 from '../img/Cream3.jpg'; 
import Cream4 from '../img/Cream4.jpg'; 
import Cream5 from '../img/Cream5.jpg'; 
import Cream6 from '../img/Cream6.jpg'; 

class FaceCream extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="facecare_main-page serums cream creampage">
                <div className="facecarebanner">
                <video autoPlay loop muted>
                            <source src={Cream1} type="video/mp4"/>
                            <source src={Cream1} type="video/ogg"/>       
                 </video> 
                </div>
                  <div className="facelotion_heading">
                     <h2>FACE CREAMS</h2>    
                 </div>
                 <div className="cream-market-frame">
                       <div className="cream-market-frame-inner">
                           <div className="container">
                               <p>Creams are part of cosmetic industry over centuries now. It is one of the parametric formats to exist. Earlier best night cream recipes contained not only moisturizing plant oil and animal fats, but also frankincense, known for its anti-inflammatory properties. In 17th and 18th century people used wax, sweet almond oil and rose water as best night cream solution. Women also preferred using buttermilk for clear white skin. Later in 19th century petroleum jelly and mineral oils where used for beautification. </p>
                            </div>
                       </div>
                  </div>
                  <div className="clear"></div>
                  <div className="creamdrivensection">
                  <div className="container">
                      <div className="row">
                         
                          <div className="col-md-8">
                              <div className="creamdrivensection-middle">   
                              <p>In today’s scenario face creams have largest revenue share of 60% in the global skin care market. The rising demand for natural and organic face creams is driving the growth of the market. With the growing awareness of the risk associated with the prolonged use of face creams made from synthetic materials have raised the demand for the face cream market. </p>
                              </div>
                          </div>
                          <div className="col-md-4">
                          <img src={Cream3} className="img-responsive"  alt="..."/>
                          </div>
                      </div>  
                  </div>

                  </div>

                  <div className="pride-formlation-maskframe">
                       <div className="container">
                            <p>We take immense pride by not using harmful chemicals like  <span class="high">parabens, sodium benzoate, benzyl alcohol, formaldehyde, sodium lauryl sulphate, petrolatum, coal tar, hydroquinone, triclosan, oxybenzone, phthalates, lead other</span> adverse chemicals. </p>
                            <h4>AT VERT LABORATOIRE, WE CAREFULLY CRAFT LUXE LUMINARIES PURE CREAM OFFERINGS LIKE</h4>
                            </div>
                       </div>

                       
                 

                  <div className="Cream-popular-forgro-ul-list">
                  <div className="container">
                  <div className="row">
                      
                      <div className="col-md-12">
                          <ul>
                              <li>HYDRATING CREAMS</li>
                              <li>AGE REPAIR CREAMS</li>
                              <li>WRINKLE CREAMS</li>
                              <li>SUNSCREENS</li>
                              <li>DAY CREAMS</li>
                              <li>NIGHT CREAMS </li>
                              <li>PIGMENTATION FREE CREAMS</li>
                              <li>COLLAGEN CREAMS</li>
                              <li>GEL BASED CREAMS </li>
                              <li>WATER BASED CREAMS</li>
                              <li>MOISTURIZING CREAMS</li>
                          </ul>
                      </div>
                  </div>
                  </div>
                  </div>


                  <div className="Cream-popular-forgro-frame-footer">   
                        <p>We are <span class="high">Organic products manufacturers</span> and can help you build your label with <span class="high">organic and natural</span> products. Create your own <span class="high">unique label</span> that speaks your essence!!</p>
                  </div>


                  
            </div>
        );
    }
}

export default FaceCream;