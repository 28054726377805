import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';

class Custom3 extends Component {
    render() {
        return (
            <div className="custom-3new_page">
                <div className="make-top-banner">
                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-1_iCa0oe2fj2.jpg?updatedAt=1629223838611"} className="img-responsive"  alt="..."/>
                </div>
                <div className="container">
                <div className="custom-2-heading_main_page">
                <AnimationOnScroll animateIn="animate__bounceIn">
                        <h2>COSMETIC DEVELOPMENT COMPANY</h2>
                    </AnimationOnScroll>
                        <div className="CustomFor_cutat2">
                        <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInDown">
                           <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-22_BiuvfnSgw.jpg?updatedAt=1629277188047"} className="img-responsive"  alt="..."/>
                         </ScrollAnimation> 
                           <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                         </div>

                    </div>

                    <div className="bespoke_main_row">
                    <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>DEVELOP UNIQUE COSMETICS</h3>  
                    </AnimationOnScroll>
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle one">
                             <div className="col-md-3">
                             <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-12_VrHPMJGA2.jpg?updatedAt=1629269044800"} className="img-responsive"  alt="..."/>
                            </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                   </ScrollAnimation> 
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>COSMETICS PRODUCT DEVELOPMENT PROCESS</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle two">
                             <div className="col-md-3">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-13_YsQiFQxxT2.jpg?updatedAt=1629269044888"} className="img-responsive"  alt="..."/>
                                    </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation> 
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>DEVELOPMENT OF GREEN BEAUTY PRODUCT</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle three">
                             <div className="col-md-3">
                                   <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-14_KwaV3PHIc.jpg?updatedAt=1629269045888"} className="img-responsive"  alt="..."/>
                                    </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation>
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>HOW LONG DOES IT TAKE?</h3> 
                        </AnimationOnScroll> 
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle four">
                             <div className="col-md-3">
                             <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                  <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-15_3DZ_4THKq.jpg?updatedAt=1629269046497"} className="img-responsive"  alt="..."/>
                              </ScrollAnimation> 
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </ScrollAnimation>
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="bespoke_main_row">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                             <h3>COSMETIC FORMULATION</h3>  
                        </AnimationOnScroll>
                             <div className="borken-boder"></div>
                             <div className="row bespoke_main_middle five">
                             <div className="col-md-3">
                             <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                    <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-16_pY-c68Esr.jpg?updatedAt=1629269044653"} className="img-responsive"  alt="..."/>
                             </ScrollAnimation>
                                 </div>
                                 <div className="col-md-9">
                                 <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight">
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                 </ScrollAnimation>
                                 </div>
                                
                            </div>          
                        </div>
                        <div className="row bespoke_main_bottom">
                        <div className="col-md-1"></div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>BESPOKE FORMULATIONS</h4>
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-18_KDDsQO6M_.jpg?updatedAt=1629272727964"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </ScrollAnimation>      
                                   </div>
                              </div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led two">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>STABILITY & COPMPATIBILITY TESTING</h4> 
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-19_D4sUJzN7t.jpg?updatedAt=1629272728873"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </ScrollAnimation>
                                   </div>
                              </div>
                              <div className="col-md-1"></div>
                        </div>
                        <div className="row bespoke_main_bottom">
                        <div className="col-md-1"></div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led treee">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>RESEARCH & DEVELOPMENT FOR INITIAL PROJECT BREIF</h4>
                                    </AnimationOnScroll>
                                    <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInLeft">
                                       <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-17_TttPkVorp.jpg?updatedAt=1629272091938"} className="img-responsive"  alt="..."/>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                     </ScrollAnimation> 
                                   </div>
                              </div>
                              <div className="col-md-5">
                                   <div className="bespoke_main_bottom_led four">
                                   <AnimationOnScroll animateIn="animate__bounceIn">
                                       <h4>TRIAL BATCH ASSESMENTS</h4> 
                                       </AnimationOnScroll>
                                       <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                                         <img src={"https://ik.imagekit.io/deepak/Laboratoire/custom-20_kGN2ho8rW.jpg?updatedAt=1629272729794"} className="img-responsive"  alt="..."/>
                                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                       </ScrollAnimation>
                                   </div>
                              </div>
                              <div className="col-md-1"></div>
                        </div>

                        <div className="bottom_frame_cuts-3">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                            <h4>COSMETIC PRODUCT DEVELOPMENT TIMELINE</h4>
                        </AnimationOnScroll>
                            <div className="bottom_frame_cuts-3_cont">
                            <AnimationOnScroll animateIn="animate__bounceIn">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </AnimationOnScroll>
                            </div>
                        </div>   


                </div>
            </div>
        );
    }
}

export default Custom3;