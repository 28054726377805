import React, { useEffect, useRef } from "react";
import ProductionNew from '../../src/img/ProductionNew.mp4';



const VideoSound = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: 0.4
    };

    let handlePlay = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();   
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);

    observer.observe(videoRef.current);
  });
  

  return (
   
      <div className="container--">
        <div className="video-container_home_paage">
          <video className="home-page_tom_desktop" autoPlay loop muted  controls ref={videoRef} src={ProductionNew}> 
             Sorry, your browser doesn't support embedded videos.
          </video>
         
       </div>
      </div>
 
  );
};

export default VideoSound;
