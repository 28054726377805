import React, { Component } from 'react';
import SHAMPOOIMG from '../lab/blog/SHAMPOOIMG.jpg';
import {Helmet} from "react-helmet";

class ProblemSilicones extends Component {
    componentDidMount() {
		window.scrollTo(0, 0) 
	  }
    render() { 
        return (
            <div className="TheDeadliestIngredientSqualane banish skin-exfoliation SEBUM">
            <Helmet>    
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="index, follow" />   
                <meta name="description" content="Problem with Silicones – Good/Bad & Ugly " />
                <title> Problem with Silicones – Good/Bad & Ugly </title>  
            </Helmet>
           
                <div className="Image_frame">
                   <img src={SHAMPOOIMG} className="img-responsive"  alt="..."/>
                </div>
        <div className="container">
        <div className="Heading_tu">   
                    <h1>PROBLEM WITH SILICONES – GOOD/BAD & UGLY </h1>
            </div>
                <div className="Image_frame-middle">
                <div className="Heading_tu">   
                    
                    <p>
                    
Do you know there is a high chance you come into contact with silicones on a daily basis?? Most products use silicones in their skincare and hair care products because silicones provide a sleek feeling. At first glance, there’s not much to think about, but you might change your mind at a second glance. 

                    </p>
                </div> 
                <div className="Heading_tu">
                    <h4>WHAT ARE THEY??</h4>
                   <p>
                   Silicones come from silicon (a mineral sand element). So in a way, silicones are synthetically created chemicals. Hair care products and skincare products favor silicones for their ability to provide shine, a smooth sleek feel, and a soft touch. We treat silicone as a plastic like any other, given that it has many plastic like properties: flexibility, malleability, clarity, temperature resistance and water resistant. 
                   </p>
                   </div>

                   <div className="Heading_tu">
                    <h4> CONTROVERSY AND GREATNESS OF SILICONES </h4>
                   <p>
                   Silicones provide a silky texture and feel in your hair or skin, but there are some drawbacks. Some silicones don’t break down in your hair and cause buildup which leads to greasy hair. In other cases, silicones also provide too much of a barrier between your hair and harmful elements (humidity and pollution). Which can cause hair to turn dry and brittle. 
                   </p>
                   <p>On the other hand, they lock in the hydration your hair and skin needs. But they also refuse to let other helpful ingredients skink in. The best way to determine between good silicone and bad silicone is by looking at the silicone structure. The good silicone are ones that are breathable, water soluble and non-cyclic. They give your hair shine without being too damaging. Cyclic silicones have been deemed bad in the beauty industry. Most silicones are also non-water soluble which means they will stay and linger in your hair no matter how many times you wash your hair and repeat the process. </p>
                   </div>
                   <div className="Heading_tu">
                    <h4> SILICONES AND FORMULATING ISSUES</h4>
                   <p>
                   When you’re in the shower, you want a product that will glide nicely in between your palms and through your hair for a good cleaning. Silicones offer a sleek, smooth feel in your hands and your hair. They also lock in hydration so that your hair won’t feel dry and brittle. However, there are some silicones that are found to not break down properly. Continual usage of such silicone will cause buildup and, well, bad hair. It’s kind of an odd thing. Right silicones supposed to prevent dry and brittle hair, but the wrong silicones will cause dry and brittle hair. And will get the bad case of greasy roots. 
                  </p>
                  <p>Skincare products are different beast. Silicones offer a nice smooth feel when you dip your finger in that face mask with Shea butter. There’s a reason why many actual silicone-free natural skincare products are more rough feeling and less creamy. </p>
                   <p>At Vert Laboratoire, we are committed to green beauty. That includes promoting luxury and sustainability. We are proud to say that we are silicone free and chemical free. We also prefer to be a head of the game and provide the best to the society. Moreover, we use natural plant oils, amino acids and other natural minerals to give our products their silky glide. </p>
                   </div>
                    
        </div>
        </div>
        </div>    
        );
    }
}

export default ProblemSilicones;