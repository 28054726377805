import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class DarkUnderarmsTreatment extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }  
    render() {
        return (
            <div className="BakuchiolBlog-page BakuchiolBlog-page_cont dark-underarms-treatment">
            <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="robots" content="index, follow" />   
            <meta name="description" content="Dark Underarms Treatment " />
            <title>Dark Underarms Treatment </title>  
            </Helmet> 
           <div className="container">
                  <div className="Image_frame_bakuchiol">
               <h1>DARK UNDERARMS TREATMENT </h1>
               </div>  
                  <div className="Image_frame_bakuchiol">
                     <img src={"https://ik.imagekit.io/vert/blush4_UsQ7jwBcr.jpg?updatedAt=1629975353167"} className="img-responsive"  alt="..."/>
                     <p className="text-center">Dark underarms are not unusual. If you are looking for ways to brighten this area of skin that is prone to hyperpigmentation, don’t worry we’ve got you covered. Although, before you check for remedies for dark underarms, it’s essential to first understand the reason for the same. </p>
                   </div>        
                  </div> 
                  <div className="container new"> 
                      
                        <div className="bottom_cont_BakuchiolBlog">
                            <h4 className="text-center">REASONS FOR DARK UNDERARMS</h4>
                            <ul>
                                <li>Lack of exfoliation allows layers of dead skin to settle in your armpits. The result? Darkened skin. Regular scrubbing with a gentle exfoliator is recommended to buff away accumulated dead cells.</li>
                                <li>Using your same old chemically saturated deodorant??? Deodorizing products that include harsh ingredients, such as aluminum, baking soda and alcohol. Switch to safer and non-toxic alternatives that don’t result in discoloration. </li>
                                <li>Certain medical conditions, such as diabetes, obesity and polycystic ovary syndrome (PCOS) are known to cause dark underarms. So before you opt for superficial products in such cases, work on treating the underlying medical issues instead. 
Wearing extremely tight clothes can cause friction between the fabric and your skin, resulting in darkened skin. Try wearing loose-fitting clothes whenever possible. 
</li>
<li>Excessive shaving or using razor to get rid of underarms hair can result in darkening and thickening of skin. </li>
                            </ul>
                            </div>
                            <div className="bottom_cont_BakuchiolBlog">
                            <h4 className="text-center">INGREDIENTS WHICH HELP IN TREATING DARK UNDERARMS:-</h4>
                            <ul>
                                <li><b>Tea Tree Oil</b> – Tea tree oil has proven to be an excellent cure for dark underarms. Not only does it aid in the process of lightening your underarms but also helps keep it odor-free. Its anti-microbial properties combat microbes and ensure that your underarms stay free from bad odor. </li>
                                <li><b>Potato</b> – Potatois also a great natural bleach and an anti-irritant that helps to lighten underarms and provides instant relief from itchiness. Its juice is best skin lightening since it is a natural mild bleaching properties. Help to reduce melanin production, lighten the skin tone. It contains vitamin C in abundance which regulates collagen production and maintains skin elasticity. </li>
                                <li><b>Apple Cider vinegar</b> – this contains mild acids that help to remove dead cells, and is also a great disinfectant. Moreover, its astringent properties aid in unclogging and reducing dark spots. </li>
                                <li><b>Aloe Vera</b> – Aloe Vera is a tyrosine inhibitor, an enzymes that is responsible for that annoying skin pigmentation. By hindering that activity of this enzymes, aloe Vera can lighten discolored armpits by hindering the activity of enzymes. Furthermore, it has antibacterial properties and can calm your inflamed skin. </li>
                                <li><b>Turmeric</b> – It is anti-inflammatory in nature and can help you to lighten your dark underarms. </li>
                            </ul>
                            </div>
                        
                      
                  </div>   
            </div> 
        );
    }
}

export default DarkUnderarmsTreatment;