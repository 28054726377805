import React, { Component } from 'react';
import aboutus1 from '../src/lab/aboutus1.mp4';
import aboutus2 from '../src/lab/aboutus2.mp4';
import aboutus3 from '../src/lab/aboutus3.mp4';
import aboutus4 from '../src/lab/aboutus4.mp4';
import aboutus10 from '../src/lab/aboutus10.mp4';
import aboutus5 from '../src/lab/aboutus5.mp4';
import aboutus6 from '../src/lab/aboutus6.png';
import aboutus7 from '../src/lab/aboutus7.png';
import aboutus8 from '../src/lab/aboutus8.png';
import about10 from '../src/lab/about10.gif';
import about11 from '../src/lab/about11.gif';
import about12 from '../src/lab/about12.gif';
import about13 from '../src/lab/about13.gif';
import about14 from '../src/lab/about14.gif';
import about15 from '../src/lab/about15.gif';
import about16 from '../src/lab/about16.gif';
import about17 from '../src/lab/about17.gif';
import about19 from '../src/lab/about18.gif';
import about18 from '../src/lab/about18.png';

class AboutUsNew extends Component {
    render() {
        return (
            <div className="aboutusnewpage">
               
                      <div className="bodycareNewpageVideo">
                            <video autoPlay loop muted>
                                <source src={aboutus1} type="video/mp4"/>
                                <source src={aboutus1} type="video/ogg"/>          
                            </video>   
                     </div>
                     <div className="topmiddle-aboutusnewpage">
                     <div className="container">
                     <div className="about-us-new-pageheading">
                        <img  src={aboutus6} class="img-responsive"/>  
                        <h2>Vert Laboratoire <span>Means</span> The Green Brand Factory </h2>
                        <p>We are in business of making people and planet beautiful. We seek Harmony and drive the circular economy through green innovations. We fuel growth with values and responsibility. </p>  
                     </div>
                     <div className="row">
                          <div className="col-md-6">
                              <div className="abou-left-frame">
                                 <img  src={aboutus7} class="img-responsive"/>  
                                 <p>Not only what goes inside the product matters but from where and how the ingredients are sourced is equally important.We take conscious decisions to source ethically sustainable natural ingredients </p>
                              </div>
                          </div>
                          <div className="col-md-6">
                                <div className="abouth-right">
                                    <video autoPlay loop muted>
                                    <source src={aboutus2} type="video/mp4"/>
                                    <source src={aboutus2} type="video/ogg"/>          
                                    </video>    
                                </div>
                          </div>
                     </div>
                     </div>
                     </div>

                    <div className="suteb-frame-aboutus-new">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="suteb-frame-aboutus-new-left">
                                        <div className="suteb-frame-aboutus-new-left-border"></div>
                                        <video autoPlay loop muted>
                                        <source src={aboutus3} type="video/mp4"/>
                                        <source src={aboutus3} type="video/ogg"/>          
                                        </video>    
                                    </div>
                                </div>
                                <div className="col-md-7">
                                <div className="suteb-frame-aboutus-new-right">
                                    <h3>Sustainable Packaging <br/> Solutions</h3>
                                    <p>Given the current situation on Landfills, Sea Animals, Sea water Contamination and Polluted Air. We take extra care to not suffocate the environment  This is a deliberate  choice that we exercise via different routes. Hence we use Glass, Aluminium, Stainless Steel, Wood and many other green options to develop a mesmerizing Brand packaging  for our clients. </p>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                
                                <div className="col-md-7">
                                <div className="suteb-frame-aboutus-new-right">
                                    <h3>Biodegradable<br/> Formulations</h3>
                                    <p>We dedicate a lot of Research & Development efforts to develop biodegradable formulations and contribute to limit the impact of cosmetics formulae released into the natural environment over time.</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="suteb-frame-aboutus-new-left">
                                        <div className="suteb-frame-aboutus-new-left-border"></div>
                                        <video autoPlay loop muted>
                                        <source src={aboutus10} type="video/mp4"/>
                                        <source src={aboutus10} type="video/ogg"/>          
                                        </video>    
                                    </div>
                                </div> 
                            </div>
                            
                            <div className="row Green-Manufacturing-aboutusnew">
                            <div className="col-md-5">
                                    <div className="suteb-frame-aboutus-new-left">
                                        <div className="suteb-frame-aboutus-new-left-border"></div>
                                        <video autoPlay loop muted>
                                        <source src={aboutus4} type="video/mp4"/>   
                                        <source src={aboutus4} type="video/ogg"/>          
                                        </video>    
                                    </div>
                                </div>
                            <div className="col-md-7">
                                <div className="suteb-frame-aboutus-new-right">
                                    <h3>Green  Manufacturing <br/> Unit</h3>
                                    <p> We have been dedicated GMP – Good Manufacturing Practice with Innovative and High Quality Clean standards.  We only practice Green and Sustainable ways of manufacturing. With our soot free Factory we do not pollute the environment </p>
                                    <p>We believe in propelling the Green Foot Print by using Renewable Energy (solar and wind) for our manufacturing. We favor the use of green methods of production which enables us to maintain a high potency of our Raw Materials  </p>
                             </div>
                            </div>          
                               
                               
                            </div>
                            <div className="row">
                            <div className="col-md-7">
                                <div className="suteb-frame-aboutus-new-right">
                                    <h3>Land & Marine Life Safe <br/> Product Offerings</h3>
                                    <p>We do not use any ingredient sourced from marine  life or from land animals.We do not test on animals for research or trials. Our products are safely manufactured with high performance plant based safe formulations. </p>
                                </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="suteb-frame-aboutus-new-left">
                                        <div className="suteb-frame-aboutus-new-left-border"></div>
                                        <video autoPlay loop muted>
                                        <source src={aboutus5} type="video/mp4"/>
                                        <source src={aboutus5} type="video/ogg"/>          
                                        </video>    
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="clean-innovation-about-is-new">
                      <div className="container">  
                             <div className="clean-innovation-about-is-new-top">
                                 <h3>CLEAN INNOVATIONS</h3>
                                 <p>In the ever evolving Beauty Industry we constantly try to understand the consumers need gaps, trends and behaviour. We innovate new product forms, categories and formats with Clean Green at the Core of our offerings. </p>
                             </div>
                             <div className="row clean-innovation-about-is-new-big">
                                 <div className="col-md-5">
                                       <div className="clean-innovation-about-is-new-big-left">
                                           <h4>WE LEAVE NO<br/> STONE UNTURNED<br/> TO<br/> PURSUE<br/> HARMONY </h4>
                                       </div>
                                 </div>
                                 <div className="col-md-7">
                                 <div className="clean-innovation-about-is-new-big-right">
                                       <div className="row">
                                            <div className="col-md-4">
                                               <img  src={about10} class="img-responsive"/> 
                                               <h5>We reduce waste</h5> 
                                            </div>
                                            <div className="col-md-4">
                                               <img  src={about11} class="img-responsive"/>  
                                               <h5>We use sustainable materials</h5>
                                            </div>
                                            <div className="col-md-4">
                                               <img  src={about12} class="img-responsive"/>  
                                               <h5>Eco Friendly ingredients</h5>
                                            </div>
                                       </div>
                                       <div className="row">
                                            <div className="col-md-4">
                                               <img  src={about13} class="img-responsive"/>  
                                               <h5>Ethical Sourcing </h5>
                                            </div>
                                            <div className="col-md-4">
                                               <img  src={about14} class="img-responsive"/> 
                                               <h5>Gender Equality </h5> 
                                            </div>
                                            <div className="col-md-4">
                                               <img  src={about15} class="img-responsive"/> 
                                               <h5>We don't add to Plastic Waste</h5> 
                                            </div>
                                       </div>
                                       <div className="row">
                                            <div className="col-md-4">
                                               <img  src={about16} class="img-responsive"/>
                                               <h5>Reef Safe products</h5>  
                                            </div>
                                            <div className="col-md-4">
                                               <img  src={about17} class="img-responsive"/> 
                                               <h5>Support Organic Farming</h5> 
                                            </div>
                                            <div className="col-md-4">
                                               <img  src={about19} class="img-responsive"/> 
                                               <h5>Making peacefully strong Ecosystem</h5> 
                                            </div>
                                             
                                       </div>
                                 </div>
                                  </div>
                             </div>
                     </div>
                   </div>

                   <div className="pro-comped-frame-about-new">
                        <div className="container">
                             <div className="row">
                                 <div className="col-md-8">
                                     <div className="pro-comped-frame-about-new-left">
                                         <p>We provide complete end-to-end private label and custom formulation services right from Packaging, Designing and Manufacturing in house, along with Marketing and Design Studio as well. </p>
                                         <p>Our in house Research and Development team with 35+ years of expertise is inspired, imaginative and inventive and is given the freedom of creativity to work a wide variety of clients that require attention to detail and a comprehensive focus on product brief. </p>
                                     </div>
                                 </div>
                                 <div className="col-md-4">
                                   <div className="pro-comped-frame-about-new-right">
                                        <p>Turning Ideas <br/>into Real Brands </p>
                                   </div>
                                 </div>
                             </div>
                             <div className="row about-newlastpara">   
                                 <div className="col-md-12">  
                                   <img  src={about18} class="img-responsive"/>  
                                 </div>
                             </div>
                        </div>
                   </div>

            </div>
        );
    }   
}

export default AboutUsNew;