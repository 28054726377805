import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MenCare extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
            <div className="make-top-banner">
               <img src={"https://ik.imagekit.io/vert/vertlaboratoire/banner-3_zl7TJ5w4uj.jpg"} className="img-responsive"  alt="..."/>
            </div>
            <div className="makeup-middle-sect Body-care mencare_new">  
            
             <div className="container">
                  <div className="percare_care">
                  <p><span className="bea-t">I</span> <span className="bea-t-right">
                  ong gone the days when people use to say (Personal Care for Men). Global men’s skincare products market is forecasted to reach 18.92 billion* by 2027. <b>Yes you read right!!!!</b>
  </span></p>
                  </div>
             </div>

             <div className="mencare_mid_cont">
             <div className="container">
                 <div className="row">
                    
                     <div className="col-md-4">  
                       <img src={"https://ik.imagekit.io/vert/vertlaboratoire/men6_gVIwtnezf.gif"} className="img-responsive"  alt="..."/>
                     </div>
                     <div className="col-md-8"> 
                     <p><span className="bea-t">S</span> <span className="bea-t-right">
                     kincare for men is no longer a myth. Skincare is generic devoid of gender differentiation, and so, it becomes of paramount importance that the market keeps abreast with men’s needs too. Growing demand for increasing concerns of self-grooming, body-image, health and hygiene among men is a driving factor for growth in the market. As social media is changing the whole scenario of how one should look, the overall personal care regime with emerging trends in social media, fashion and aesthetically appealing looks is also booming the market growth. Trends on social media where gender has no boundaries made it clear that everyone has skin, regardless of the fact they are male or female. 
                  </span></p>
                  
                      </div>
                 </div>
                   
                 <div className="row-">
                     <div className="col-md-12"> 
                     <p><span className="bea-t">n</span> <span className="bea-t-right">
                     atural and organic products have never been more popular than now. As the market is moving towards high performance formulations instead of chemical infused products. The movement towards ethical labelling and sustainability has touched people’s heart to wards “clean beauty”. Natural products are powerful and have good antioxidants that helps in slowing aging process and also helps in glowing and healthier skin. As these products are made from non-artificial ingredient like <b>fruits acids, plants, extracts, natural oils and minerals.</b> 
                  </span></p>
                 
                      </div>
                     
                 </div>
                 <div className="row">
                
                     <div className="col-md-8">  
                     <p className="precise_range"><span className="bea-t">a</span> <span className="bea-t-right">
                     t Vert laboratoire, we have the precise range of products, we offer face wash, face moisturizer, beard oil, face mask, face mists, face serums, shaving soaps, shave lotion, essential oils, massage oils, shower gel and many more exciting products. 
                    </span></p>
                   
                      </div>
                      <div className="col-md-4">  
                       <img src={"https://ik.imagekit.io/vert/vertlaboratoire/men5_p108lj2By.gif"} className="img-responsive"  alt="..."/>
                     </div>
                 </div>
                
                 <div className="row-">
                     <div className="col-md-12">  
                     <p><span className="bea-t w_w">W</span> <span className="bea-t-right">
                     e, as <b>green manufactures</b> produce non-toxic, chemical free and organic products which are suited not only for green consumers but also take care of the production process and use of these products leave minimum carbon footprints on the ecological system. As private label manufactures can help you build your label with<b> organic and natural products.</b> 
A must for your brand portfolio launch.
   </span></p>
                  <p className="te_sou_right">Sourced* - Grand View Research</p>
                      </div>
                     
                 </div>
                 </div>
             </div>
            

            <div className="rech_now_cont">
                <h5>REACH US NOW TO LAUNCH YOUR BRAND !!!</h5>
                <p><Link to="/contact">Contact Us</Link></p>      
            </div>
  
            </div>
        </div>
        );
    }
}

export default MenCare;