import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Player } from 'video-react';
import {Helmet} from "react-helmet";
import Formulation1 from '../lab/Formulation1.png';
import Deepak from '../lab/Deepak.jpg';
import Formulation3 from '../lab/Formulation3.jpg';
import Formulation4 from '../lab/Formulation4.jpg';
import Formulation5 from '../lab/Formulation5.jpg';
import Formulation6 from '../lab/Formulation6.jpg';
import Formulation7 from '../lab/Formulation7.jpg';
import Formulation8 from '../lab/Formulation8.jpg';
import Formulation9 from '../lab/Formulation9.jpg';
import Formulation10 from '../lab/Formulation10.jpg';
import Formulation11 from '../lab/Formulation11.jpg';
import Formulation12 from '../lab/Formulation12.jpg';
import Formulation13 from '../lab/Formulation13.jpg';
import Formulation14 from '../lab/Formulation14.gif';
import Formulation15 from '../lab/Formulation15.jpg';
import Formulation16 from '../lab/Formulation16.jpg';
import Formulation17 from '../lab/Formulation17.jpg';
import Formulation18 from '../lab/Formulation18.jpg';
import Formulation19 from '../lab/Formulation19.jpg';
import Formulation20 from '../lab/Formulation20.jpg';
import Formulation21 from '../lab/Formulation21.jpg';
import Formulation22 from '../lab/Formulation22.jpg';
import Formulation23 from '../lab/Formulation23.jpg';
import Formulation24 from '../lab/Formulation24.jpg';
import Formulation25 from '../lab/Formulation25.jpg';
import Formulation26 from '../lab/Formulation26.jpg';
import Formulation27 from '../lab/Formulation27.jpg';
import Formulation28 from '../lab/Formulation28.jpg';
import Formulation29 from '../lab/Formulation29.jpg';
import Formulation30 from '../lab/Formulation30.jpg';
import Formulation31 from '../lab/Formulation31.png';
import Formulation33 from '../lab/Formulation33.jpg';
import Formulation34 from '../lab/Formulation34.jpg';
import Formulation35 from '../lab/Formulation35.jpg';
import Formulation36 from '../lab/Formulation36.jpg';
import CustomCont from '../img/CustomCont.mp4';
import ManuB2 from '../img/ManuB2.jpg';

class CustomFormulating extends Component {  
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="custom-1mainpage new">
                 <Helmet>
			<meta charset="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta name="robots" content="index, follow" />   
			<meta name="description" content="Custom Formulation" />
			<title>Custom Formulation</title> 
       </Helmet>
            <div className="make-top-banner">
                <div className="top-back-banner_all">
                <div className="container">
                    <img src={Formulation1} className="img-responsive"  alt="..."/>
                </div>
                </div>          
                <div className="top-video_sect">
                 {/* <img src={Deepak} className="img-responsive"  alt="..."/>*/} 
                    <video autoPlay loop muted poster={Deepak}>
                         <source src="https://vertlaboratoire.com/Top3.mp4" type="video/mp4"/>
                         <source src="https://vertlaboratoire.com/Top3.mp4" type="video/ogg"/>
                    </video>
                    </div>
     </div>
             
             <div className="container">
              
                  <div className="top_main_page_ctstom1">
                        
                      <h2>Custom Formulating for Over A Decade</h2>
                      <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                      <div className="thing-all"><h5>ALL THINGS GREEN & YOU</h5> </div>
                        
                     {/* <img src={Formulation27} className="img-responsive-green"  alt="..."/>*/}
                      </ScrollAnimation>
                            
                      <p className="decade"><span>W</span>ith a decade plus experience of Conceptualising & Formulating new product ideas our team has a deep
understanding of <span className="bold_new">beauty Industry trends ,</span> gaps & opportunities. Whether you’re developing the elementary
product or creating conceptual range, we will use our extensive knowledge to make your product <span className="bold_new">stand out.</span> </p>
                 
                  </div>
                  <div className="row top_main_page_ctstom2">
                      <div className="col-md-4">
                          <div className="middle_img_frame_custom-1">
                          <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInLeft" >
                          <img src={Formulation22} className="img-responsive"  alt="..."/>
                         </ScrollAnimation> 
                          </div>
                      </div>
                      <div className="col-md-4">
                          <div className="middle_img_frame_custom-1">
                          <img src={Formulation23} className="img-responsive"  alt="..."/> 
                            </div>
                      </div>
                      <div className="col-md-4">
                          <div className="middle_img_frame_custom-1">
                          <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="bounceInRight" >
                          <img src={Formulation24} className="img-responsive"  alt="..."/>
                          </ScrollAnimation> 
                         
                          </div>
                      </div>
                  </div>
                  <div className="CustomFor_cutat1 new">
                  <img src={Formulation26} className="img-responsive-brand"  alt="..."/> 
                      {/*<h2>EVERY BRAND IS UNIQUE</h2>*/} 
                  
                     <div className="CustomFor_cutat2-new">
                     
                        <p> <span>A</span>t <span className="bold_new">Vert Laboratoire,</span> our in house Research and Development team with substantial expertise is <span className="bold_new">inspired,
imaginative, inventive</span> and is given the freedom of creativity to work with a wide variety of clients that require
attention to detail and a comprehensive focus on product brief</p>
         <p><span className="bold_new">As a cosmetic Development Company,</span> we recognize that no two projects are the same.
          However, is with the same level of <span className="bold_new">honesty, enthusiasm and expertise.</span> Innovation is key and our aim is to help you
bring your <span className="bold_new">unique skincare and cosmetics products to the competitive market.</span> Our focus is  to help make your brand
and product, stand out from the competition and convert your target demographic to become your <span className="bold_new">brand
followers.</span></p>
<p>Developing new products with our highly experienced team and using the best of both traditional and modern
raw materials to formulate the finest bespoke products for your target market.</p>
<p>Not just thinking in one way. We prefer to utilize every piece of knowledge we have gained over the years, and
apply it to each project to develop completely <span className="bold_new">unique cosmetics.</span></p>
                       
                      </div>
                     
                  </div>



                  <div className="row top_main_page_ctstom2">
                      <div className="col-md-4">
                          <div className="middle_img_frame_custom-1">
                          <ScrollAnimation  offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                          <img src={Formulation3} className="img-responsive"  alt="..."/>
                           </ScrollAnimation> 
                          </div>
                      </div>
                      <div className="col-md-4">
                          <div className="middle_img_frame_custom-1">
                          <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                          <img src={Formulation4} className="img-responsive"  alt="..."/>
                           </ScrollAnimation>
                          </div>
                      </div>
                      <div className="col-md-4">
                          <div className="middle_img_frame_custom-1">
                          <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn" >
                          <img src={Formulation5} className="img-responsive"  alt="..."/>     
                        </ScrollAnimation> 
                         
                          </div>
                      </div>
                  </div>
                     
                   
                     <div className="how-it-word_custfor">
                     <img src={Formulation25} className="img-responsive-work"  alt="..."/>     
                        
                        {/*<h2>HOW IT WORKS</h2>*/}   
                     <div className="row">
                         <div className="col-md-4">
                                <div className ="bespoke_main_bottom_led Ne">
                                <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                   <h4><span><b>U</b>NDERSTANDING</span>YOUR BRAND</h4>     
                                 </ScrollAnimation>      
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                    <div className="formu-middle-fram-img">
                                          <img src={Formulation6} className="img-responsive"  alt="..."/>
                                            <div class="overlay-bottom-slide">
                                            <p className="text under_stand">Our first target is to always gain a thorough
understanding of your <span className="bold_new">concept , ideas &
expectations</span> for your brand and the 
philosophy guiding your concept. Whether
you are <span className="bold_new">starting your own brand</span> that is new to the
beauty industry or an <span className="bold_new">established business </span>
 we have worked with before, we still approach 
every project in the same way.
<span>Once we have the thorough understanding
of your vision, we research and review the
finer details for each product. This includes
preferred ingredients, <span className="bold_new">fragrance, color, texture </span>
and everything that will make both the
product and brand reflect your <span className="bold_new">highest Clean
Standards</span> of quality & <span className="bold_new"> Unique selling
preposition.</span></span>
</p>
                                            </div>

                                       
                                    </div>
                                   </ScrollAnimation>      
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className ="bespoke_main_bottom_led Ne new2">
                                <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                   <h4><span><b className="bespoke">B</b>ESPOKE COSMETIC</span>FORMULATION</h4>     
                                 </ScrollAnimation>      
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                    <div className="formu-middle-fram-img">
                                         <img src={Formulation7} className="img-responsive"  alt="..."/>
                                          <div className="overlay-bottom-slide">
                                              
                                            <p className="text">Our team will provide insight into <span className="bold_new">emerging and
current trends</span> and utilise their expertise to
identify material compatibility. During the
course of formulation development, your will be <span className="bold_new">constantly updated </span>
with process of development. Our aim is to
deliver <span className="bold_new">creative</span> yet <span className="bold_new">exceptional</span> results for every
client and their bespoke cosmetic formulation. 

</p>  

                                        </div>
                                    </div>
                                   </ScrollAnimation>      
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className ="bespoke_main_bottom_led Ne new3">
                                <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                   <h4><span><b className="guidence">E</b>XPERT GUIDENCE &</span>ADVANCED INNOVATION</h4>     
                                 </ScrollAnimation>      
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                    <div className="formu-middle-fram-img">
                                    <img src={Formulation8} className="img-responsive"  alt="..."/>
                                          <div className="overlay-bottom-slide">
                                            <p className="text">At Vert Laboratoire, our guidance and support is
available throughout your entire journey, so
whenever you have any questions or looking for
guidance, our <span className="bold_new">experts</span> are there to help. Our
team is updated with industry’s <span className="bold_new">news</span> and latest
<span className="bold_new"> trends, </span> such as <span className="bold_new">Natural Clean Beauty </span> and have
years of experience in <span className="bold_new">Plant based cosmetics, </span> so
will be able to guide wherever you need</p>

                                        </div>
                                    </div>
                                   </ScrollAnimation>      
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className ="bespoke_main_bottom_led Ne new4">
                                <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                   <h4><span><b className="research">R</b>ESEARCH AND</span>DEVELOPEMNT</h4>     
                                 </ScrollAnimation>      
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                    <div className="formu-middle-fram-img">
                                    <img src={Formulation9} className="img-responsive"  alt="..."/>
                                          <div className="overlay-bottom-slide">
                                            <p className="text">In additional to taking the time to study the
project briefy initially, our team does research
before manufacturing. This includes looking at
<span className="bold_new"> efficient usefulness</span> and <span className="bold_new">scale-up</span> of the project,
so we are well prepared for our <span className="bold_new">high quality
production</span></p>

                                        </div>
                                    </div>
                                   </ScrollAnimation>      
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className ="bespoke_main_bottom_led Ne new5">
                                <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                   <h4><span><b className="prototype">P</b>ROTOTYPE</span>MAKING</h4>     
                                 </ScrollAnimation>      
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                    <div className="formu-middle-fram-img">
                                    <img src={Formulation10} className="img-responsive"  alt="..."/>
                                           <div className="overlay-bottom-slide">
                                            <p className="text">Once the formulation of your product has been
confirmed, a <span className="bold_new">trial batch</span> is created and assessed
by our team and yourself. 
</p>

                                        </div>
                                    </div>
                                   </ScrollAnimation>      
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className ="bespoke_main_bottom_led Ne new6">
                                <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                   <h4><span><b className="stability">S</b>TABILITY AND</span>COMPATIBILITY TESTING</h4>     
                                 </ScrollAnimation>      
                                 <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                    <div className="formu-middle-fram-img">
                                    <img src={Formulation11} className="img-responsive"  alt="..."/>
                                     <div className="overlay-bottom-slide">
                                            <p className="text">Every <span className="bold_new">approved</span> formula that goes to market is
subject to series of legally required, <span className="bold_new">stringent
tests,</span> which assess factors such as <span className="bold_new">pack
compatibility, shelf life</span> and microbiological
stability. We engage in a 21st Century Clean
Beauty Factory process to ensure the required   
testing of your custom product is chemically
trusted, safe and suitable for application. We
make products eco-friendly and sustainable as the
production process is all Green and Soot-free to
ensure compatibility with the environment. </p>

                                        </div>
                                    </div>
                                   </ScrollAnimation>      
                                </div>
                            </div>
                          </div>
                        </div>
                    
             </div>


  <div className="custom-3new_page">
            
             <div className="container">
              
                   
                    
                     <div className="bespoke_main_row long-to">
                     <div className="text-center">
                     <AnimationOnScroll animateIn="animate__bounceIn">  
                         <img src={Formulation30} className="img-responsive-development"  alt="..."/>
                         {/* <h3>HOW <span>LONG</span> DOES IT TAKE ?</h3> */} 
                     </AnimationOnScroll> 
                          </div>
                          <div className="row bespoke_main_middlennn">
                          <div className="col-md-3 padding">
                          <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true}  animateIn="fadeIn">
                                <img src={Formulation12} className="img-responsive"  alt="..."/>
                           </ScrollAnimation>      
                              </div>
                              <div className="col-md-9 padding">
                                  <div className="bespoke_main_middlennn-rigt">
                              <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                <p><span>T</span>he cosmetic product development process relies on <span class="bold_new">time,</span> thought and efforts, plus
highly qualified technical team. It will take a minimum of <span class="bold_new">3</span> to <span class="bold_new">6</span> months, during this
time we supply <span class="bold_new">monthly process reports</span> on how testing is developing</p> 
                                <p>The creative product development process is complex, so our experienced team will be
on hand to guide you through all stages. We will provide expert knowledge on what
we know best and advise you on other essentials you need to consider when delivering
your final product. 
</p>
<p>We understand that requirements are different for each of our customers, but you can
see a typical product development timeline below. </p> 
                                </ScrollAnimation>
                              </div>
                              </div>
                         </div>          
                     </div>

                <fieldset>
                    <img src={Formulation28} className="img-responsive-osmetic"  alt="..."/>
                    {/*<legend>INDIACTIVE PRODUCTION TIME LINE</legend>*/}  
                    <p><span>C</span>osmetic product manufacturing <span class="bold_new">lead-times</span> are quoted at the time of <span class="bold_new">ordering</span> and are <span class="bold_new">confirmed via an
official order confirmation email.</span> There are many factors that affect cosmetic production leads-times, including
the availability of <span class="bold_new">raw materials. </span></p>
<p>Due to organic and natural raw materials with specific provenance being purchased on an as-need basis, this
may effect lead times, as will seasonal demand.</p> 
<div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-8">
         <img src={Formulation13} className="img-responsive"  alt="..."/>
    </div> 
    <div className="col-md-2"></div>
</div>
        </fieldset>



                   </div>
         </div>


<div className="custom-2ndmainpage">    
              
             <div className="container">
                <div className="bespoke_main_row product_plus">
                        <div className="product_plus-new">
                        <AnimationOnScroll animateIn="animate__bounceIn">
                        <h4>WHAT IS PRODUCT<span> PLUS</span></h4> 
                        <h2>COSMETIC MANUFACTURING CAPABILITIES</h2>
                        </AnimationOnScroll> 
                        </div>
                         
                          <div className="row bespoke_main_middle new3">
                          <div className="col-md-3">
                              <ScrollAnimation offset={10} duration={3} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                   <img src={Formulation14} className="img-responsive"  alt="..."/>
                              </ScrollAnimation> 
                              </div>    
                              <div className="col-md-9 right-bespoke_main_middle">  
                              <ScrollAnimation offset={10} duration={2} animatePreScroll={false} initiallyVisible={true} animateIn="fadeIn">
                                  <p><span className="blod">C</span>reating and innovating <span class="bold_new">new ideas</span> for a
product and <span class="bold_new">unique formulae</span> is what we do
best. The formulae we create and manufacture
are completely varied, no one the same, and we
always challenge ourselves with new
possibilities and unique product or package.</p>  
                               </ScrollAnimation> 
                              </div>
                              
                         </div>          
                     </div>
                     
                    <div className="product_fl_main desktop">
                    <div className="row">
                         <div className="col-md-3 product_fl_main_middle">
                         <h4><span>P</span>RODUCT FILLING</h4> 
                         <div class="flip-card">
                             <div className="product_fl_main_middle flip-card-inner">
                             
                            <div class="flip-card-front">
                           
                            <img src={Formulation15} className="img-responsive"  alt="..."/>
                            </div>
                            <div class="flip-card-back">
                            <p> <span className="blod">T</span>he end result is just
                            as important as the
                            attention and care
                            paid to <span class="bold_new">creation</span> and
                            <span class="bold_new">development. </span> The
                            product fill has to be
                            perfect with
                            absolutely no
                            compromise. We take
                            <span class="bold_new"> extra measures </span> for
                            each package as it
                            comes off the line and
                            record it for complete
                            accountability. </p>
                            </div>
                            </div> 
                             </div>
                         </div>
                         <div className="col-md-3 product_fl_main_middle">
                         <h4><span>P</span>ACKAGING</h4>
                         <div class="flip-card">
                             <div className="product_fl_main_middle two flip-card-inner">
                             
                             <div class="flip-card-front">
                               
                                <img src={Formulation16} className="img-responsive"  alt="..."/>
                                </div>
                                <div class="flip-card-back">
                                <p> <span className="blod">B</span>ring your <span class="bold_new">Green</span>
<span class="bold_new">Creation</span> to life with
our wide range of
<span class="bold_new"> recycled </span> packaging
options. Simply select
and start designing
with our label design
and packaging team
who ensure to deliver
world class name
expertise with each
eco-conscious
creation. Customize
from <span class="bold_new">shape</span> of the
<span class="bold_new"> bottle, color, materials </span>
that complements
your <span class="bold_new">brand
personality. </span>
</p>
</div>
                               </div>
                             </div>
                         </div>
                         <div className="col-md-3 product_fl_main_middle">
                         <h4><span>Q</span>UALITY CONTROL</h4> 
                         <div class="flip-card">
                             <div className="product_fl_main_middle three flip-card-inner">
                             
                             <div class="flip-card-front">
                                  
                                   <img src={Formulation17} className="img-responsive"  alt="..."/>
                                </div>
                                <div class="flip-card-back">
                                 <p> 
                                 <span className="blod">O</span>ur quality control
procedures are
performed from
<span class="bold_new"> concept </span> to <span class="bold_new"> product </span>
and every member of
our dedicated Quality
Team is trained to
identify improvements.
We are <span class="bold_new"> ISO Certified </span>
and <span class="bold_new">fully compliant </span> with  <br/><span class="bold_new"> Good Manufacturing Practices (GMP)</span></p> 
                            </div>
                             </div>
                         </div>
                         </div>  
                         <div className="col-md-3 product_fl_main_middle four">
                         <h4><span>F</span>INISHED GOODS READY FOR DISPATCH</h4> 
                         <div class="flip-card">
                             <div className="product_fl_main_middle four flip-card-inner">
                             
                             <div class="flip-card-front">
                                
                                  <img src={Formulation18} className="img-responsive"  alt="..."/>
                             </div> 
                             <div class="flip-card-back">
                                   <p> <span className="blod">O</span>ur Products are Carefully Packed with <span class="bold_new">eco - conscious materials</span> and methods. <br/><br/><span class="bold_new">VIOLA!</span><br/><br/> your goods are ready to be <br/><span class="bold_new">sold.</span> </p>
                          </div>
                             </div> 
                         </div>
                         </div>
                    </div>
                    </div>
                  
                  {/* Mobile start */}                   

                  <div className="product_fl_main Mobile">
                    <div className="row">
                         <div className="col-md-3 product_fl_main_middle">
                         <h4><span>P</span>RODUCT FILLING</h4> 
                         <div class="flip-card">
                             <div className="product_fl_main_middle flip-card-inner">
                             
                            <div class="flip-card-front---">
                             <img src={Formulation33} className="img-responsive--"  alt="..."/>
                            <p> <span className="blod">T</span>he end result is just
                                    as important as the
                                    attention and care
                                    paid to <span class="bold_new">creation</span> and
                                    <span class="bold_new"> development.</span> The
                                    product fill has to be
                                    perfect with
                                    absolutely no
                                    compromise. We take
                                    <span class="bold_new"> extra measures</span> for
                                    each package as it
                                    comes off the line and
                                    record it for complete
                                    accountability. </p> 
                            </div>
                                  
                          
                            </div> 
                             </div>
                         </div>
                         <div className="col-md-3 product_fl_main_middle tww">   
                         <h4><span>P</span>ACKAGING</h4>
                         <div class="flip-card">
                             <div className="product_fl_main_middle two flip-card-inner">
                             
                             <div class="flip-card-front---">
                               
                                <img src={Formulation34} className="img-responsive--"  alt="..."/>
                                <p> <span className="blod">B</span>ring your <span class="bold_new">Green </span>
<span class="bold_new">Creation </span> to life with
our wide range of
<span class="bold_new"> recycled </span> packaging
options. Simply select
and start designing
with our label design
and packaging team
who ensure to deliver
world class name
expertise with each
eco-conscious
creation. Customize
from <span class="bold_new">shape</span> of the
<span class="bold_new"> bottle, color, materials </span>
that complements
your <span class="bold_new">brand
personality. </span>
</p>
                                </div>
                              
                               </div>
                             </div>
                         </div>
                         <div className="col-md-3 product_fl_main_middle thee">
                         <h4><span>Q</span>UALITY CONTROL</h4> 
                         <div class="flip-card">
                             <div className="product_fl_main_middle three flip-card-inner">
                             
                             <div class="flip-card-front---">
                                  
                                   <img src={Formulation35} className="img-responsive"  alt="..."/>
                                   <p> 
                                 <span className="blod">O</span>ur quality control
procedures are
performed from
<span class="bold_new"> concept </span> to <span class="bold_new">product </span>
and every member of
our dedicated Quality
Team is trained to
identify improvements.
We are <span class="bold_new">ISO Certified </span>
and <span class="bold_new">fully compliant</span> with  <br/><span class="bold_new"> Good Manufacturing Practices (GMP)</span></p> 
                           
                            
                                </div>
                               
                             </div>
                         </div>
                         </div>  
                         <div className="col-md-3 product_fl_main_middle four">
                         <h4><span>F</span>INISHED GOOD READY FOR DISPATCH</h4> 
                         <div class="flip-card">
                             <div className="product_fl_main_middle four flip-card-inner">
                             
                             <div class="flip-card-front---">
                                
                                  <img src={Formulation36} className="img-responsive"  alt="..."/>
                                  <p> <span className="blod">O</span>ur Products are Carefully Packed with <span class="bold_new">eco - conscious materials</span> and methods. <br/><br/><span class="bold_new">VIOLA!</span><br/><br/> your goods are ready to be <br/><span class="bold_new">sold.</span> </p>
                            
                        
                             </div> 
                           
                             </div> 
                         </div>
                         </div>
                    </div>
                    </div>


                    {/* Mobile End */}






                    <div className="clear"></div>
                    <div className="product_fl_main-new-bottom_heading">
                           {/*} <h4>OUR CLIENTS</h4>*/}     
                            <img src={Formulation29} className="img-responsive-client"  alt="..."/>
                        </div>
                    <div className="row product_fl_main-new-bottom-client">
                        <div className="col-md-4">
                             <div className="product_fl_main_middle-client">
                             <img src={Formulation19} className="img-responsive"  alt="..."/> 
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="product_fl_main_middle-client two">
                                <img src={Formulation20} className="img-responsive"  alt="..."/>  
                             </div>
                         </div>
                         <div className="col-md-4">      
                             <div className="product_fl_main_middle-client thre">
                                 <img src={Formulation21} className="img-responsive"  alt="..."/>    
                             </div>
                         </div>

                      </div>
 

                  <div className="Last_buttom_filecus desktop">
                  <a href="/contact">
                  <video autoPlay loop muted>
                         <source src={CustomCont} type="video/mp4"/> 
                         <source src={CustomCont} type="video/ogg"/>
                    </video>
                   
                   
                  </a>
                  </div>

                  <div className="Last_buttom_filecus Mobile">
                  <a href="/contact">   
                 {/* <video autoPlay loop muted>
                         <source src={CustomCont} type="video/mp4"/>
                         <source src={CustomCont} type="video/ogg"/>
                    </video>*/}  
                    <img src={ManuB2} className="img-responsive"  alt="..."/>
                 
                  </a>    
                  </div>
                 


              </div>
         </div>
      </div>
        );
    }
}

export default CustomFormulating;