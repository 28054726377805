import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class GreesyHairBlog extends Component {
  componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="GreesyhairBlog-page BakuchiolBlog-page_cont">
              <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="face oils all you need to know" />
	<title> grease cleanse guide home remedies</title>  
	</Helmet>
            <div className="container">
                 
                  <div className="Image_frame_bakuchiol">
                  <h1>GREASE CLEANSE GUIDE: HOME REMEDIES</h1>
                  <img src={"https://ik.imagekit.io/deepak/Laboratoire/greesy-hair-blog_JmKYW4EbL.jpg?updatedAt=1629868879571"} className="img-responsive"  alt="..."/>
                  </div>
                 
                  </div>
                  <div className="container new">
                  <div className="Image_frame_bakuchiol">
                 <p className="text-center">If your skincare routine ends at your face, maybe you’ve forgotten your scalp which is skin too. Your scalp needs attention as well, else you will wake up to greasy strands in the morning and who loves that? Wrong product, poor lifestyle or even genes can leave you with oily scalp and hair, doesn’t mean you can’t refresh the greasy roots and keep your crowning glory from appearing flat. All you need is a renewed hair care routine that dials down the grease.
</p>

                  </div>
                 <div className="bottom_cont_BakuchiolBlog">
                      <h4>Reasons for Oily Scalp</h4>
                      <p>Before we get to the solution, let’s first zero in on the cause for your oily scalp. </p>
                      <ul>
                          <li>Have you been stressing out a lot lately? Well, here’s a newsflash. The more you stress, the more you trigger the oil production sebaceous glands to go on an overdrive. The result is of course, oily scalp. </li>
                          <li>Blame it on your genes if someone in your immediate family deals with an oily scalp. </li>
                          <li>A head shower feels amazing. However, going overboard on hair wash and washing it every other day can strip your scalp of its natural oils. To compensate for the loss, your scalp then produces more oil thereby, fueling greasiness. </li>
                          <li>Hormonal fluctuation especially during puberty and menopause can be the root cause of oily scalp too. This might require you to consult with your doctor and figure out a way to treat it. </li>
                          <li>Overzealous with the styling? Using too many styling products can cause a build-up on your scalp that contributes to the greasiness. Your best bet at a grease-free scalp is regular detoxifying with a clarifying shampoo that works at exfoliating the scalp. </li>
                          <li>If you have an oily complexion, naturally your scalp which is an extension of your skin in prone to getting oily too. </li>
                          <li>Running your fingers through your hair again and again? The oil from your fingertips gets transferred to your strands and makes oilier. </li>
                          <li>The wrong product can ruin your hair. Check the ingredients and stick to products that will actually work with your hair and not against it to ensure the oiliness scales down. </li>
                      </ul>      
                </div>
                 
                  <div className="bottom_cont_BakuchiolBlog">
                      <h4> Home Remedies for Oily Scalp </h4>
                        <ul>
                          <li><b>Fuller Earth </b>– Fuller earth, is a clay that has many benefits for skin and hair. Its absorbent properties allow it to soak up oil naturally while it also conditions your scalp. Its abrasive properties exfoliate, which may help remove dead skin and flakes. </li>
                          <li><b>Egg White and Lemon Juice</b> – Just like with oily skin, egg white controls sebum secretion in the scalp by tightening the hair follicles. This will reduce the amount of sebum secreted through the hair follicles and remove excess oils from your scalp and hair. </li>
                          <li><b>Aloe Vera</b> – Aloe Vera is beneficial in variety of ways as it has anti-inflammatory actions that may help reduce scalp irritation. It has moisturizing effect and enzymes and fatty acid content that may help reduce inflammation. Vitamins C, E, B-12, Folic acid and Choline content that may help nourish and strengthen hair. </li>
                          <li><b>Black Tea</b> – Black tea rinses can be very effective for helping to reduce hair shedding. The natural properties of black tea can help to block the hormone DHT, which is responsible for hair shedding. It also helps prevent greasy hair, the compounds in tea can naturally balance pH levels on the scalp and help to regulate the amount of oil it produces.</li>
                          <li><b>Apple Cider Vinegar</b> - Apple cider vinegar contains acetic acid which helps in restoring shine to hair and reducing dryness, making them less frizzy. The antimicrobial qualities of apple cider vinegar make it effective against fungi and bacteria that cause scalp infections. Apple cider vinegar is good for hair because it contains several vitamins and minerals that nourish your hair leaving them shiny and healthy. </li>
                         </ul>      
                </div>
                 
                  
                  
                  
                  </div>
      </div>
        );
    }
}

export default GreesyHairBlog;