import React from 'react';
import axios from 'axios';
import SliderInsta from "./SliderInsta";

class Instagram extends React.Component {
    state = {
        images: [],
    };
    componentDidMount() {
        let token = 'IGQVJYanVpY1FqWjR6RmllSlVxNHo0QnVnc21xVkt4T1FNRDJZAWDRXMjRINzNsZA2NxSWRmRHliQnloVG1ObnAxcVh4MDU4TnZAXRmJPMU1mNUVkejV5NTZAEZAko3X1BFamJ6cXlCUkxuaUNzbVRHcjU4dwZDZD';
        let num_photos = 100;
        axios.get('https://api.instagram.com/v1/users/self/media/recent/?access_token=' + token + '&count=' + num_photos)
            .then(res => {
                console.log(res.data.data[0].images);
                    this.setState({ images: res.data.data });
            })
            .catch(err => {
                console.log(err)
            })       
    }
    render() { 
        return(
            <div className="Instagram-bottom-section">  
                <h4>Instagram Inspirations</h4>  
                 <SliderInsta images={ this.state.images }/> 
                 <p> <a href="https://www.instagram.com/vertlaboratoire/" target="_blank">Follow on Instagram</a> </p> 
            </div>       
        )
    }
}

export default Instagram;