import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class SkincareTrends extends Component {
    componentDidMount() {
		window.scrollTo(0, 0)
	  }
    render() {
        return (
            <div className="skincare-trendsBlog-page BakuchiolBlog-page_cont chmicalBlog-page">
                <Helmet>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<meta name="robots" content="index, follow" />   
	<meta name="description" content="5 skincare trends of 2021" />
	<title> 5 skincare trends of 2021</title>  
	</Helmet>
              <div className="container">
                <div className="Image_frame_bakuchiol">
                <h1>5 SKINCARE TRENDS OF 2021</h1> 
                <img src={"https://ik.imagekit.io/vert/Blog/trends-2-2021_Un7BNyQWnr.jpg?updatedAt=1629968328697"} className="img-responsive"  alt="..."/>
               </div>
                
            </div>
            <div className="container new">
            <div className="Image_frame_bakuchiol">
                 <p className="text-center">2020 – Better it’s left behind. While you experimenteda multi-step routine for a ‘zoom face’ or couldn’t find the desire to prioritize your skin, things change fast in the world of skincare. Search bar replaced with offline browsing, mask induced breakouts and social media became all-encompassingall of this became a real thing. </p>
                </div>
                  <div className="bottom_cont_BakuchiolBlog">
                      <h3>So here are some skincare trends that are growing in 2021:</h3>
                      <h4>2- HAND CARE </h4>
                      <p>The ceaseless dish washing, hand washing and sanitizing from 2020 has made hand care most important. After a year of unpleasant smelling sanitizers, our hands begged for everything extravagant. From pleasant hand creams to body butters that bring back that hydration and sheen. Yes, believe it or not hand care will find a place in 2021 trends for sure.  </p>
                  </div> 
                  <div className="bottom_cont_BakuchiolBlog">
                      <h4>3- USE OF AHAS AND BHAS</h4>
                      <p>Acids have been in the market for a couple of years now, and the fact that people have now understood the context of acids as hero ingredients rather than scary, has boost their popularity. However, 2012 will see more focused and direct approach to skincare products with acids. Products containing more acne and pigmentation serums are more likely to be picked over numerous individual products which leads to irritation and over-stimulation.  </p>
                  </div>
                 <div className="bottom_cont_BakuchiolBlog">
                      <h4> 4- PREVENTIVE ROUTINES </h4>
                      <p> Gone are the days where we felt a skincare routine was optional. With greater awareness, the focus is now on dealing with breakouts and skin issues. Whether it’s fighting off early signs of aging or sun-damage and caring for acne scarring, preventive skincare routines are going to cement their place in 2021.  </p>
                  </div>
                 <div className="bottom_cont_BakuchiolBlog"> 
                      <h4> 5- CONSULTATION </h4>
                      <p> While dermatology moved over online consultancy during 2020, buying skincare online was already been practiced way before. With more research done on skincare products online, by the consumers it will continue to be increasing essential for brands to provide best recommendation tools for skincare products.  </p>
                  </div>
                  
            </div>
            </div>
        );
    }
}

export default SkincareTrends;