import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Pack1 from '../img/Pack1.mp4';
import Packbr from '../img/Packbr.jpg';
import PlusM from '../img/PlusM.png';
import Pack2 from '../img/Pack2.mp4';
import Pack3 from '../img/Pack3.mp4';
import PackagingNew2 from '../img/PackagingNew2.mp4';
import PackagingNew3 from '../img/PackagingNew3.mp4';
import ManuB4 from '../img/ManuB4.jpg';


class Packaging_New extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return ( 
            <div className="Packaging_main_page">
                <div className="Packaging-top-banner_video Desktop">
                    <video autoPlay loop muted>
                        <source src={Pack1} type="video/mp4"/>
                        <source src={Pack1} type="video/ogg"/>       
                    </video>
                </div> 
                <div className="Packaging-top-banner_video Mobile">
                    <video autoPlay loop muted>
                        <source src={PackagingNew2} type="video/mp4"/>
                        <source src={PackagingNew2} type="video/ogg"/>       
                    </video>
                </div> 
                <div className="packing_heading_frame">
                    <div className="container">
                          <div className="row">
                              <div className="col-md-4">
                                  <div className="packing_heading_frame-left">
                                      <h1>PACKAGING</h1>
                                      <h4>ENVIRONMENTAL FRIENDLY <br/> COMPONENTS</h4>
                                  </div>
                              </div>
                              <div className="col-md-8"> 
                                  <div className="packing_heading_frame-right">
                                        <p>In the evident global warming and other environmental issues. It is even more imperative to foster the <span className="sect">Green Foot Print.</span> Current scenario of plastic waste is about <span className="sect">150 million tons</span> every year. That’s nearly equivalent to the weight of the entire human population. Due to complexities in the recycling process, huge amounts of single – use plastic that people try to recycle ultimately <span className="sect">end up getting burned or tossed into landfills.</span> Studies shows that, of all the plastic waste generated globally up to 2015, just <span className="sect">9%</span> has been recycled, while <span className="sect">12%</span> was incinerated and the rest ended up in <span className="sect">landfills or scattered around the natural environment.</span> </p>
                                  </div>                 
                              </div>
                              <div className="col-md-12"> 
                                  <div className="packing_heading_frame-center">
                                        <p>Drawing from an extensive research to develop a cover that mesmerizes prospective customers, is our prime forte. We intelligently interpret the most complex briefs and metamorphosis them into <span className="sect">stunning, meaningful</span> and <span className="sect">creative package.</span> Also given the current situation on Landfills, Sea Water Contamination and Polluted Air - we take extra care to not suffocate the environment any further. This is a conscious choice that we exercise via different routes.  </p>
                                  </div>                 
                              </div>
                          </div>
                    </div>
                </div> 
                <div className="middle_main_pack_pic_frame_main">
                <div className="container">
                    <div className="middle_main_pack_pic_frame">
                         
                          <div className="packaging_new_all_main">
                          <div className="row packaging_new_all">
                                <div className="col-md-6">
                                    <div className="packaging_new_all_clickone one_1">
                                        
                                         <div className="packaging_new_all_clickone_u">
                                             <p> DO NOT OVER PACK</p>
                                             <p className="all_clickone_u_midum">Reduce the extra cartons, boxes or <br/>leaflets /brochures/pamphlets</p>
                                         
                                             <p></p>
                                         </div>
                                         <div className="Plus_imh_all_packing">
                                            <img src={PlusM} className="img-responsive"  alt="..."/>
                                        </div>
                                    </div>
                                    <div className="packaging_new_all_clickone one_2">
                                         <div className="packaging_new_all_clickone_u">
                                             <p> CLEAN GLASS</p>
                                             <p className="all_clickone_u_midum">Heavier substrate, breakable but <br/>very compatible with products and<br/> looks premium and stunning</p>
                                        
                                         </div>
                                         <div className="Plus_imh_all_packing">
                                            <img src={PlusM} className="img-responsive"  alt="..."/>
                                        </div>
                                    </div>
                                    <div className="packaging_new_all_clickone one_3">
                                         <div className="packaging_new_all_clickone_u">
                                             <p> WOOD</p>
                                             <p className="all_clickone_u_midum">Medium weight, reusable <br/> and looks good.</p>
                                         </div>
                                         <div className="Plus_imh_all_packing">
                                            <img src={PlusM} className="img-responsive"  alt="..."/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="packaging_new_all_clickone one_4">
                                    <div className="Plus_imh_all_packing">
                                            <img src={PlusM} className="img-responsive"  alt="..."/>
                                        </div>
                                         <div className="packaging_new_all_clickone_u">
                                             <p> STAINLESS STEEL</p>  
                                             <p className="all_clickone_u_midum">Medium weight, reusable and <br/> unbreakable substrate</p>
                                        
                                         </div>
                                    </div>
                                    <div className="packaging_new_all_clickone one_5">
                                    <div className="Plus_imh_all_packing">
                                            <img src={PlusM} className="img-responsive"  alt="..."/>
                                        </div>
                                         <div className="packaging_new_all_clickone_u">
                                             <p> FSC CERTIFIED BOARD PAPER</p>
                                             <p className="all_clickone_u_midum">Flexible and  environmental friendly</p>
                                        
                                         </div>
                                    </div>
                                    <div className="packaging_new_all_clickone one_6">
                                    <div className="Plus_imh_all_packing">
                                            <img src={PlusM} className="img-responsive"  alt="..."/>
                                        </div>
                                         <div className="packaging_new_all_clickone_u">
                                             <p> ALUMINIUM</p>
                                             <p className="all_clickone_u_midum">Light weight, matt finish, <br/> reusable and looks neat</p>
                                        
                                         </div>
                                    </div>
                                </div>
                          </div>
                          </div>
                    </div>
                </div>
                </div>

                <div className="Packaging-top-banner_video_middle">
                <div className="container">
                    <div className="row">
                       <div className="col-md-4">
                            <div className="video_Heading">
                                <h3>Aluminium</h3>
                            </div>
                       </div>
                       <div className="col-md-4">
                            <div className="video_Heading">
                                <h3>Wood</h3>
                            </div>
                       </div>
                       <div className="col-md-4">
                            <div className="video_Heading">
                                <h3>Glass</h3>
                            </div>
                       </div>
                       </div>
                    </div>
                    <video autoPlay loop muted>
                        <source src={Pack2} type="video/mp4"/>
                        <source src={Pack2} type="video/ogg"/>       
                    </video>
                </div>  
                <div className="container">
                   <div className="simply_text_add">
                       <p><span className="First">S</span>imply select and start designing with our designing team who ensure to deliver<br/> world class <span className="sect">name expertise with each eco-conscious curation. customize from shape<br/> of the bottle, colour, material that complements your brand personality. </span></p>
                   </div>

                </div> 
                <div className="Packaging-top-banner_video_borrom desktop">
                    <a href="/contact">
                    <video autoPlay loop muted>
                        <source src={Pack3} type="video/mp4"/>
                        <source src={Pack3} type="video/ogg"/>       
                    </video>
                    </a>
                </div>  
                <div className="Packaging-top-banner_video_borrom mobile">
                    <a href="/contact">
                   {/* <video autoPlay loop muted>
                        <source src={PackagingNew3} type="video/mp4"/>
                        <source src={PackagingNew3} type="video/ogg"/>       
                    </video>*/}
                    <img src={ManuB4} className="img-responsive"  alt="..."/>
                    </a>
                </div>  
                
            </div> 
        );
    }
}

export default Packaging_New;