import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default class SliderInsta extends Component {
    responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
          slidesToSlide: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6,
          slidesToSlide: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1
        },
      }; 
    render() {
        return (
            <div>
                <Carousel
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={2000}
   responsive={this.responsive}>

                     <a href="https://www.instagram.com/p/CVfP7eBhItS/"  target="_blank"> 
                        <img src={"https://ik.imagekit.io/vert/inst/1_MZ61wNrCxJr----.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659267722302"}  />   
                     </a> 
                     <a href="https://www.instagram.com/p/CUwnjZ8DHOa/"  target="_blank"> 
                        <img src={"https://ik.imagekit.io/vert/inst/2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659268240591"}  />   
                     </a> 
                     <a href="https://www.instagram.com/p/CUuHlT6MiSW/"  target="_blank">
                        <img src={"https://ik.imagekit.io/vert/inst/3_8seh9hUXTcv-----.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659267660894"}  />   
                     </a> 
                     <a href="https://www.instagram.com/p/CUZhps9j0lm/"  target="_blank">
                        <img src={"https://ik.imagekit.io/vert/inst/4_8ZMe4_DFB.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1637390209155"}  />   
                     </a> 

                     <a href="https://www.instagram.com/p/CUUNnBqDtNl/"  target="_blank">
                        <img src={"https://ik.imagekit.io/vert/inst/5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659268077128"}  />   
                     </a>  

                     <a href="https://www.instagram.com/p/CUMuZYiMJb8/"  target="_blank">
                        <img src={"https://ik.imagekit.io/vert/inst/6_qVw-3AGGn----------.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659267883170"}  />   
                     </a>  

                     
       
               </Carousel>
            </div>
        )
    }
}